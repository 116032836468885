import React from "react";
import { Card, Avatar, Tooltip, Row, Col, List, Button } from "antd";
import { PhoneFilled, GiftFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";

const { Meta } = Card;

const StudentCard = ({
  firstName,
  lastName,
  username,
  phoneNumber,
  packageName,
  onClick,
  userAvatar,
}) => {
  return (
    <List.Item onClick={onClick}>
      <List.Item.Meta
        avatar={userAvatar && userAvatar}
        title={
          <Button style={{ padding: 0 }} type="text">
            <b>{`${firstName} ${lastName}`}</b>
          </Button>
        }
        description={
          <Row gutter={[{ xs: 24, sm: 24, md: 16, lg: 8 }]}>
            <Col style={{ color: Colors.darkGrey }}>{username}</Col>
            <Col>
              <Tooltip placement="bottom" title={phoneNumber}>
                <a href={`tel:${phoneNumber}`}>
                  <PhoneFilled
                    style={{ fontSize: "20px" }}
                    color={Colors.lightGrey}
                    key="Phone"
                    title={phoneNumber}
                  />
                </a>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip placement="bottom" title={packageName}>
                <GiftFilled
                  style={{ fontSize: "20px", color: Colors.orange }}
                  key="Package"
                />
              </Tooltip>
            </Col>
          </Row>
        }
      />
    </List.Item>
  );
};

export default StudentCard;
