import React, { useEffect, useState } from "react";
import { Table, Card, Tag, Button, message } from "antd";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import Colors from "../../../constants/Colors";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function Instructors(props) {
  const [fetching, setFetching] = useState(true);
  const [instructorData, setInstructorData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const parseDaysOftheWeek = (index) => {
    const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return daysOfTheWeek[parseInt(index)];
  };

  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      .get(
        `/LemmajUsers?filter[where][userType]=Instructor&access_token=${props.authToken}`,
        axiosOption
      )
      .then((res) => {
        setFetching(false);
        const data = res.data.map((c) => {
          return {
            name: c.firstName + " " + c.lastName,
            status: c.isLocked ? "Locked" : c.isOnLeave ? "On Leave" : "Active",
            // dutyDays: c.dutyDays,
            phoneNumber: c.phoneNumber,
            email: c.email,
            id: c.id,
          };
        });
        setInstructorData(data.reverse());
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchData();
    // setFetching(false);
  }, []);

  const handleSearchTerm = (term) => {
    setSearchTerm(term);
  };

  let filteredInstructors =
    !searchTerm || searchTerm === ""
      ? instructorData
      : instructorData.filter((i) => {
          return (
            `${i.name.toLowerCase()}`
              .trim()
              .includes(searchTerm.toLowerCase().trim()) ||
            `${i.email.toLowerCase()}`
              .trim()
              .includes(searchTerm.toLowerCase().trim()) ||
            `${i.phoneNumber.toLowerCase()}`
              .trim()
              .includes(searchTerm.toLowerCase().trim())
          );
        });

  const history = useHistory();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    // {
    //   title: "Duty Days",
    //   dataIndex: "dutyDays",
    //   render: (value = []) => {
    //     return value.map((i) => {
    //       return <Tag style={{ margin: 5 }}>{parseDaysOftheWeek(i)}</Tag>;
    //     });
    //   },
    // },
    {
      title: "Details",
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              history.push(`${Routes.INSTRUCTOR_DETAIL}?id=${record.id}`);
            }}
            icon={<EyeOutlined />}
            type="ghost"
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 10, borderRadius: 16 }}>
        <AppInputSearch
          onSearch={handleSearchTerm}
          placeholder="Instructors name | email | phone number"
        />
      </Card>

      <Card style={{ borderRadius: 16 }}>
        <Button
          onClick={() => {
            history.push(Routes.CREAT_INSTRUCTOR);
          }}
          icon={
            <>
              <PlusOutlined />
            </>
          }
          type="primary"
          style={{
            marginBottom: 20,
            background: Colors.blue2,
            borderColor: Colors.blue2,
          }}
        >
          Add Instructor
        </Button>
        <Table
          loading={fetching}
          scroll={{ x: 50 }}
          columns={columns}
          dataSource={filteredInstructors}
        ></Table>
      </Card>
    </>
  );
}

export default Instructors;
