import React, { useState } from "react";
import { Form, Typography, Menu, Divider } from "antd";
import { a } from "@ant-design/icons";
import Basic from "./Basic";
import Location from "./Location";
import License from "./License";

function Profile({
  onLogout = () => null,
  userData = null,
  onBasicUpdate = () => null,
}) {
  const [selectedNavKey, setSelectedNavKey] = useState("1");
  const navs = (
    <Menu mode="horizontal" defaultSelectedKeys={"1"}>
      <Menu.Item
        key={"1"}
        onClick={(selected) => {
          setSelectedNavKey(selected.key);
        }}
      >
        Basic
      </Menu.Item>
      <Menu.Item
        key={"2"}
        onClick={(selected) => {
          setSelectedNavKey(selected.key);
        }}
      >
        License
      </Menu.Item>
      <Menu.Item
        key={"3"}
        onClick={(selected) => {
          setSelectedNavKey(selected.key);
        }}
      >
        Location
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {navs}
      {selectedNavKey === "1" ? (
        <Basic
          onLogout={onLogout}
          userData={userData}
          onUpdate={onBasicUpdate}
        />
      ) : selectedNavKey === "2" ? (
        <License onLogout={onLogout} userData={userData} />
      ) : (
        <Location onLogout={onLogout} userData={userData} />
      )}
    </>
  );
}

export default Profile;
