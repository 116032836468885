import React, { useEffect, useState } from "react";
import PackageHeader from "./PackageHeader";
import {
  Alert,
  Anchor,
  Button,
  Collapse,
  message,
  Spin,
  Typography,
} from "antd";
import Colors from "../../../constants/Colors";
import {
  CheckCircleFilled,
  ClockCircleFilled,
  ScheduleFilled,
  SyncOutlined,
  ScheduleTwoTone,
} from "@ant-design/icons";
import Period from "../Period/Period";
import PeriodDetails from "../PeriodDetails/PeriodDetails";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import moment from "moment";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function Package({ instId, student }) {
  const [loading, setLoading] = useState(false);
  const [periods, setPeriods] = useState([]);

  const getPeriods = () => {
    setLoading(true);
    lemmajAxios
      .get(`/PeriodContainers/getPeriodsWithCourses/`, {
        params: {
          studentId: student.id,
        },
        ...axiosOption,
      })
      .then((res) => {
        setLoading(false);

        if (res.data.error && res.data.error.statusCode === 400) {
          message.error(Messages.BAD_REQUEST);
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error(res.data.error.message);
          return;
        }
        setPeriods(res.data);
      })
      .catch((err) => {
        setLoading(false);

        message.error(Messages.OPREATION_FAILED);
      });
  };

  useEffect(() => {
    getPeriods();
  }, []);

  const getCurrentPeriodSeq = () => {
    let res = -1;
    periods.every((p) => {
      if (p.period.periodStatus !== "completed") {
        res = p.period.sequence;
        return false;
      }
      return true;
    });
    return res;
  };

  const calculateProgress = (periods = []) => {
    if (!periods || periods.length === 0) return 0;

    const completedPeriods = periods.filter(
      (p) => p.period.periodStatus === "completed"
    );

    return Number.parseInt((completedPeriods.length / periods.length) * 100);
  };

  const currentPeriodSeq = getCurrentPeriodSeq();
  const packageCompleted = getCurrentPeriodSeq() === -1;
  return (
    <Spin spinning={loading}>
      {packageCompleted && (
        <Alert
          type="success"
          banner
          description="Congratulation, you have successully completed this package"
        />
      )}

      <PackageHeader periods={periods} progress={calculateProgress(periods)} />

      {periods.map(({ period, courses, schedule }) => {
        const label =
          period.periodStatus === "not started"
            ? "Not Scheduled"
            : period.periodStatus === "scheduled"
            ? `Scheduled on ${moment(
                new Date(schedule.startDate),
                "MMM-DD-YYYY"
              ).format("MMM-DD-YYYY")}, ${moment(
                new Date(schedule.startDate),
                "hh:mm a"
              ).format("hh:mm a")} - ${moment(
                new Date(schedule.endDate),
                "hh:mm a"
              ).format("hh:mm a")}`
            : period.periodStatus === "completed"
            ? `Completed on ${moment(
                new Date(schedule.startDate),
                "MMM-DD-YYYY"
              ).format("MMM-DD-YYYY")}`
            : period.periodStatus;
        return (
          <Period
            schedule={schedule}
            key={period.id}
            title={`Period ${period.sequence + 1}`}
            subTitle={label}
            status={period.periodStatus}
            period={period}
            content={
              <PeriodDetails
                allowSchedule={currentPeriodSeq === period.sequence}
                courses={courses}
                period={period}
                periodStatus={period.periodStatus}
                schedule={schedule ? schedule : {}}
              />
            }
          />
        );
      })}
    </Spin>
  );
}

export default Package;
