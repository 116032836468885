import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Typography,
  Input,
  Select,
  Row,
  Col,
  Button,
  Spin,
  message,
} from "antd";
import Colors from "../../../constants/Colors";
import moment from "moment";
import Messages from "../../../others/Messages";
import lemmajAxios from "../../../others/apiConfig";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function Location({ onLogout = () => null, userData = null }) {
  const [fetching, setFetching] = useState(true);
  const [updating, setUpdateing] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (
      (!userData.user ||
        !userData.id ||
        !userData.userId ||
        userData.user.userType !== "Driver",
      userData.userType !== "Driver")
    ) {
      onLogout();
      return;
    }
    lemmajAxios
      .get(`/LemmajUsers/${userData.userId}`, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        const user = res.data;
        form.setFieldsValue({
          city: res.data.city,
          country: res.data.country,
          region: res.data.Region,
        });
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
      });
  }, []);
  const updateLocation = (values) => {
    const data = {
      city: values.city,
      country: values.country,
      region: values.region,
    };
    setUpdateing(true);
    lemmajAxios
      .patch(`/LemmajUsers/${userData.userId}`, data, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          message.error(res.data.error.message);
          onLogout();
          return;
        }
        message.success(Messages.INFO_UPDATED);
        setUpdateing(false);
      })
      .catch((err) => {
        message.success(Messages.OPREATION_FAILED);
        setUpdateing(false);
      });
  };
  return (
    <Spin spinning={updating}>
      <Card loading={fetching}>
        <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
          Location Information
        </Typography.Title>
        <Form form={form} onFinish={updateLocation} layout="vertical">
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: "Country is required" }]}
          >
            <Input placeholder="Country" />
          </Form.Item>
          <Form.Item
            label="Region"
            name="region"
            rules={[{ required: true, message: "Select a region" }]}
          >
            <Select placeholder="Region">
              <Select.Option key="Addis Ababa City State">
                Addis Ababa City State
              </Select.Option>
              <Select.Option key="Afar">Afar</Select.Option>
              <Select.Option key="Amhara">Amhara</Select.Option>
              <Select.Option key="Benishangul Gumuz">
                Benishangul Gumuz
              </Select.Option>
              <Select.Option key=" Dire Dawa City State">
                Dire Dawa City State
              </Select.Option>
              <Select.Option key="Gambela">Gambela</Select.Option>
              <Select.Option key="Harari">Harari</Select.Option>
              <Select.Option key="Oromia">Oromia</Select.Option>
              <Select.Option key="SNNP">SNNP</Select.Option>
              <Select.Option key="Sidama">Sidama</Select.Option>
              <Select.Option key="Tigray">Tigray</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "City is required" }]}
          >
            <Input placeholder="City" />
          </Form.Item>
          {/* <Form.Item
            label="Subcity"
            name="subcity"
            rules={[{ required: true, message: "Subcity is required" }]}
          >
            <Input placeholder="Subcity" />
          </Form.Item>
         <Form.Item
            label="Woreda"
            name="woreda"
            rules={[{ required: true, message: "Woreda is required" }]}
          >
            <Input placeholder="Woreda" />
          </Form.Item> */}
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  style={{ background: Colors.blue2, border: Colors.blue2 }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default Location;
