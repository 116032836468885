import React from "react";

import lemmajAxios from "../../../../others/apiConfig";
import moment from "moment"

export default function getGraduated(
    userId,
    axiosOption,
    setIsLoading,
    setGraduatedUsers,
    onLogout 
) {
    lemmajAxios
      .get("/CertifiedUsers/getCertifiedUsersWithUserInfo", {
        params: {
          access_token: userId,
        },
        ...axiosOption,
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
            return onLogout();
          }
          if (res.data.error && res.data.error.statusCode === 404) {
            return res.json({ message: "data is not found" });
          }
        const data = res.data.map((CertifiedStudent) => {
            if(CertifiedStudent.User !== undefined) {
                return {
                    name: `${CertifiedStudent.User.firstName} ${CertifiedStudent.User.lastName}`,
                    certificateCode: CertifiedStudent.certifiedUser.certificateCode,
                    certfiedDate: moment(CertifiedStudent.certifiedUser.certfiedDate).format("MMM DD, YYYY")
                };
        }
        });
        setGraduatedUsers(data);
      })
      .catch((res) => {
        setIsLoading(false);
      });
}
