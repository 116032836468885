import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import getInactive from "./QUERIES/getInactive";
import { Card } from "@material-ui/core";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";

export default function InActive({userId, onLogout, axiosOption}) {
  const [isLoading, setIsLoading] = useState(true);
  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  
    useEffect(() => {
      getInactive(userId, axiosOption, setIsLoading, setInactiveUsers, onLogout)
    }, [])

    const handleSearchTerm = term => {
      setSearchTerm(term)
    }

    let filteredStudents =
    !searchTerm || searchTerm === ""
      ? inactiveUsers
      : inactiveUsers.filter((i) => {
          return `${i.studentName.toLowerCase()}`
          .trim()
          .includes(searchTerm.toLocaleLowerCase().trim())
        });

      const columns = [
        {
          title: 'Name',
          dataIndex: 'studentName',
          key: 'studentName',
        },
        {
          title: 'Last Schedule',
          dataIndex: 'lastSchedule',
          key: 'lastSchedule',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
      ];
      return (
        <>
        <Card style={{ marginBottom: 10, borderRadius: 16 }}>
              <AppInputSearch
              onSearch={handleSearchTerm}
              placeholder="Inactive Student" />
            </Card>
        <Table loading={isLoading} dataSource={filteredStudents} scroll={{x: 50}} columns={columns} />
        </>
      )
}