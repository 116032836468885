import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import getGraduated from "./QUERIES/getGraduated";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import { Card } from "@material-ui/core";

export default function GraduatedStudents({userId, onLogout, axiosOption}) {
  
  const [isLoading, setIsLoading] = useState(true);
  const [graduatedUsers, setGraduatedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  
    useEffect(() => {
      getGraduated(userId, axiosOption, setIsLoading, setGraduatedUsers, onLogout)
    }, [])

    const handleSearchTerm = term => {
      setSearchTerm(term)
    }

    let filteredStudents =
    !searchTerm || searchTerm === ""
      ? graduatedUsers
      : graduatedUsers.filter((i) => {
          return i != null?`${i.name.toLowerCase()}`
          .trim()
          .includes(searchTerm.toLocaleLowerCase().trim()): null
        });

      
      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Certificate Code',
          dataIndex: 'certificateCode',
          key: 'certificateCode',
        },
        {
          title: "Certified Date",
          dataIndex: "certfiedDate",
          key: 'certifiedteDate',
        },
      ];
      return (
        <>
        <Card style={{ marginBottom: 10, borderRadius: 16 }}>
              <AppInputSearch
              onSearch={handleSearchTerm}
              placeholder="Graduated Student" />
            </Card>
            <Table loading={isLoading} dataSource={filteredStudents} scroll={{x: 50}} columns={columns} />
            </>
      )
}