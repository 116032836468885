import React, { useEffect, useState } from "react";

import StudentStatusPanel from "../StudentStatusPanel/StudentStatusPanel";
import StudentStatusHeader from "../StudentStatusHeader/StudentStatusHeader";
import StudentEnrollment from "./StudentEnrollment";
import StudentCourseDetails from "./StudentCourseDetails";
import { message, Spin } from "antd";
import Messages from "../../../others/Messages";
import lemmajAxios from "../../../others/apiConfig";
import { useHistory } from "react-router";
import Routes from "../../Routes";
import moment from "moment";
import { Modal } from "antd";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function StudentStatus({ onLogout, authToken }) {
  const [user, setUser] = useState({
    name: "loading loading",
    userName: "loading",
    imageSrc: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [_package, setPackage] = useState(null);
  const history = useHistory();
  const studentId = new URLSearchParams(history.location.search).get("id");

  const getPeriods = () => {
    setLoading(true);
    if (!studentId) return;
    lemmajAxios
      .get(`/PeriodContainers/getPeriodsWithCourses`, {
        params: {
          access_token: authToken,
          studentId: studentId,
        },
        ...axiosOption,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          history.replace(Routes.BASE);
          return;
        }
        if (res.data.error) {
          message.error(res.statusText);
          return;
        }
        setPeriods(res.data);
        const packageId =
          res.data[0] && res.data[0].period
            ? res.data[0].period.packageId
            : null;
        if (packageId) {
          lemmajAxios
            .get(`/LemmajPackages/${packageId}`, {
              params: { access_token: authToken },
              ...axiosOption,
            })
            .then((res) => {
              if (!res.data.error) {
                setPackage(res.data);
              }
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setLoading(false);
      });
  };
  const getStudent = () => {
    setLoading(true);
    if (!studentId) return;
    lemmajAxios
      .get(`/LemmajUsers/${studentId}`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          history.replace(Routes.BASE);
          return;
        }
        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }
        const stud = res.data;
        setUser({
          name: `${stud.firstName} ${stud.lastName}`,
          userName: stud.email,
          imageSrc: "",
          phoneNumber: stud.phoneNumber,
        });
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setLoading(false);
      });
  };

  const calculateProgress = (periods = []) => {
    if (!periods || periods.length === 0) return 0;

    const completedPeriods = periods.filter(
      (p) => p.period.periodStatus === "completed"
    );

    return Number.parseInt((completedPeriods.length / periods.length) * 100);
  };
  const tryToCompletePackage = () => {
    if (!periods || periods.length === 0) return 0;
    const completedPeriods = periods.filter(
      (p) => p.period.periodStatus === "completed"
    );

    if (completedPeriods.length === periods.length - 1) {
      const data = {
        packageId: completedPeriods[0].period.packageId,
        studentId: studentId,
      };

      lemmajAxios
        .post(`LemmajPackages/completePackage`, data, {
          params: {
            access_token: authToken,
          },
          ...axiosOption,
        })
        .then((res) => {
          if (res.data.error && res.data.error.statusCode === 401) {
            onLogout();
            return;
          }
          if (res.data.error && res.data.error.statusCode === 404) {
            message.error(Messages.ITEM_NOT_FOUND);
            return;
          }
          if (res.data.error) {
            message.error(res.statusText);
            return;
          }
          message.success("Student has completed this package successfully");
          Modal.info({
            title: "Student has completed this package successfully",
          });
        })
        .catch((err) => {
          message.error(Messages.OPREATION_FAILED);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getStudent();
    getPeriods();
  }, []);
  return studentId ? (
    <Spin spinning={loading}>
      <StudentStatusHeader
        _package={_package}
        user={user}
        packageProgress={calculateProgress(periods)}
      />
      {periods.map(({ period, courses, schedule }) => {
        let headerLabel = "";
        if (period.periodStatus === "not started") {
          headerLabel = "Not Started";
        } else if (
          period.periodStatus === "scheduled" ||
          period.periodStatus === "completed"
        ) {
          const startDate = moment(new Date(schedule.startDate)).format(
            "DD-MMM-YYYY"
          );
          const startTime = moment(
            new Date(schedule.startDate),
            "hh:mm a"
          ).format("hh:mm a");
          const endTime = moment(new Date(schedule.endDate), "hh:mm a").format(
            "hh:mm a"
          );

          const createdDate = new Date(schedule.createdDate);

          headerLabel =
            period.periodStatus === "scheduled"
              ? `Scheduled on ${startDate}, ${startTime} - ${endTime}`
              : `Completed on ${startDate}, ${startTime} - ${endTime}`;
        }
        return (
          <StudentEnrollment
            key={period.id}
            progress={period.periodStatus === "completed" ? "100" : "0"}
            title={`Period ${period.sequence + 1}`}
            status={period.periodStatus}
            description={headerLabel}
            content={
              <StudentCourseDetails
                onPackageCompleted={tryToCompletePackage}
                onCompleteFinished={getPeriods}
                authToken={authToken}
                onLogout={onLogout}
                period={period}
                schedule={schedule}
                courses={courses}
                instId={studentId}
                periodStatus={period.periodStatus}
              />
            }
          />
        );
      })}
    </Spin>
  ) : null;
}

export default StudentStatus;
