import React, { useState } from "react";
import { Avatar, Modal } from "antd";
import Colors from "../../constants/Colors";
import { UserOutlined } from "@ant-design/icons";
function UserAvatar({
  size,
  imagSrc = null,
  name,
  previewModal = null,
  preview = true,
}) {
  const [isPreview, setPreview] = useState(false);
  const [firstName, lastName] = name.split(" ");
  const previewHandler = () => {
    if (preview && imagSrc) {
      setPreview(true);
    }
  };
  const handleCancel = () => {
    setPreview(false);
  };
  return (
    <>
      <div onClick={previewHandler}>
        {imagSrc ? (
          <Avatar
            style={{ backgroundColor: Colors.orange }}
            icon={<UserOutlined />}
            src={imagSrc}
            size={size}
          />
        ) : (
          <Avatar
            size={size}
            style={{ verticalAlign: "middle", background: Colors.orange }}
          >
            <span style={{ fontSize: size * 0.5 }}>
              {firstName && String(firstName).toUpperCase()[0]}
            </span>
            <span style={{ fontSize: size * 0.5 }}>
              {lastName && String(lastName).toUpperCase()[0]}
            </span>
          </Avatar>
        )}
      </div>
      {previewModal ? (
        previewModal
      ) : (
        <Modal
          centered={true}
          style={{ margin: 0, padding: 0 }}
          zIndex={0.5}
          visible={isPreview}
          title={null}
          closable={false}
          footer={null}
          onCancel={handleCancel}
        >
          {imagSrc ? <img style={{ width: "100%" }} src={imagSrc} /> : null}
        </Modal>
      )}
    </>
  );
}

export default UserAvatar;
