import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Select, Card } from "antd";
import lemmajAxios from "../../../others/apiConfig";
import moment from "moment";
import getScheduledStudents from "./QUERIES/getScheduledStudents";
import Messages from "../../../others/Messages";
import PatchScheduleData from "./QUERIES/PatchScheduleData";
import getVehicles from "./QUERIES/getVehicles";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";

export default function ScheduledStudents({ userId, axiosOption, onLogout }) {
  const { Option } = Select;
  const [scheduledData, setScheduledData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [scheduleRecord, setScheduleRecord] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [vehiclePlateNumber, setVehiclePlateNumber] = useState(
    "NA plate number"
  );
  const [scheduleData, setScheduleData] = useState([]);
  let scheduleRe = {};
  useEffect(() => {
    getScheduledStudents(
      userId,
      axiosOption,
      setIsLoading,
      setScheduledData,
      onLogout
    );
  }, []);

  const handleSearchTerm = term => {
    setSearchTerm(term)
  }

  let filteredInstructors =
    !searchTerm || searchTerm === ""
      ? scheduledData
      : scheduledData.filter((i) => {
          return `${i.studentName.toLowerCase()}`
          .trim()
          .includes(searchTerm.toLocaleLowerCase().trim())
        });

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "studentId",
    },
    {
      title: "Instructor Name",
      dataIndex: "instructorName",
      key: "instructorId",
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Status",
      dataIndex: "scheduleStatus",
      key: "scheduleStatus",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "address",
      render(text, record) {
        return (
          <>
            <Button
              onClick={() => {
                // scheduleRe = record
                setScheduleRecord(record);
                onActionClicked(record);
              }}
            >
              Action
            </Button>
          </>
        );
      },
    },
  ];
  const onActionClicked = (record) => {
    getVehicles(
      userId,
      axiosOption,
      setVehicleData,
      // setIsLoading,
      // setScheduledData,
      onLogout,
      record.id
    );
    setShowModal(true);
  };
  const handleApproveReject = (record, status) => {
    if (status === "reject") {
      setShowModal(false);
      PatchScheduleData(
        record.id,
        record.studentName,
        userId,
        axiosOption,
        { isRejected: true, studentId: null },
        setIsLoading,
        setScheduledData,
        onLogout
      );
    }
    if (status === "approve") {
      setShowModal(false);
      PatchScheduleData(
        record.id,
        record.studentName,
        userId,
        axiosOption,
        { isApproved: true, vehiclePlateNumber: vehiclePlateNumber },
        setIsLoading,
        setScheduledData,
        onLogout
      );
    }
  };
  const vehicleSelected = (record) => {
    setVehiclePlateNumber(record);
  };
  return (
    <>
      <Card style={{ marginBottom: 10, borderRadius: 16 }}>
          <AppInputSearch 
          onSearch={handleSearchTerm}
          placeholder="Scheduled Student" />
      </Card>
      <Table loading={isLoading} scroll={{x: 50}} dataSource={filteredInstructors} columns={columns} />
      <Modal
        visible={showModal}
        title="Manage the Schedule"
        key={scheduleRecord.id}
        onCancel={() => setShowModal(false)}
        footer={[
          <>
            <Button
              key="reject"
              onClick={() => handleApproveReject(scheduleRecord, "reject")}
            >
              Reject
            </Button>
            <Button
              key="approve"
              onClick={() => handleApproveReject(scheduleRecord, "approve")}
            >
              Approve
            </Button>
          </>,
        ]}
      >
        <p>Vehicle Plate Numbers</p>
        <Select
          style={{ width: 440 }}
          placeholder="Select Vehicle"
          onSelect={vehicleSelected}
        >
          {vehicleData.map((vehicle) => (
            <Option value={vehicle.plate} key={vehicle.id}>
              {vehicle.plate} - {vehicle.model}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}
