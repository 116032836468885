import {
  CheckCircleFilled,
  ClockCircleFilled,
  ScheduleTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { Typography, Collapse, Progress } from "antd";
import React from "react";
import Colors from "../../../constants/Colors";

const StudentEnrollment = ({
  progress = "0",
  description = "",
  title = "",
  status = "",
  content,
}) => {
  const truncateDescription = (values) => {
    if (values.length >= 50) {
      values = values.slice(0, 50) + "....";
    }
    return values;
  };

  const themeColor =
    status === "completed"
      ? Colors.green1
      : status === "scheduled"
      ? Colors.blue2
      : status === "not started"
      ? Colors.red
      : Colors.darkGrey;
  const icon =
    status === "completed" ? (
      <CheckCircleFilled />
    ) : status === "scheduled" ? (
      <ScheduleTwoTone size={10} />
    ) : status === "not started" ? (
      <ClockCircleFilled />
    ) : null;
  const statusLabelMap = {
    completed: "Completed",
    scheduled: "Scheduled",
    "not started": "Not Started",
  };
  return (
    <>
      <Progress percent={progress} />
      <Collapse
        style={{
          marginBottom: 16,
          borderLeftWidth: 10,
          borderLeftColor: themeColor,
        }}
        expandIconPosition="right"
      >
        <Collapse.Panel
          header={
            <>
              <Typography.Text style={{ color: themeColor, marginRight: 8 }}>
                {icon}
              </Typography.Text>
              <Typography.Text style={{ marginRight: 8, fontWeight: "bold" }}>
                {title}
              </Typography.Text>
              <Typography.Text
                style={{ fontWeight: "bold", color: Colors.darkGrey }}
              >
                {statusLabelMap[status]}
              </Typography.Text>
              <Typography.Paragraph type="secondary">
                {truncateDescription(description)}
              </Typography.Paragraph>
            </>
          }
        >
          {content}
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default StudentEnrollment;
