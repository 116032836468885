import React from "react";
import lemmajAxios from "../../../../others/apiConfig";
import moment from "moment";

export default function getAbsents(
  userId,
  axiosOption,
  setIsLoading,
  setAbsents,
  onLogout
) {
  lemmajAxios
    .get("/Schedules", {
        params: {
            access_token: userId,
            "filter[where][scheduleStatus]": "scheduled",
          },
      ...axiosOption,
    })
    .then((res) => {
      if (res.data.error && res.data.error.statusCode === 401) {
        return onLogout();
      }
      if (res.data.error && res.data.error.statusCode === 404) {
        return res.json({ message: "data is not found" });
      }
      getUsers(res.data)
    })
    .catch((err) => {
      setIsLoading(false);
    });

    const getUsers = (scheduledData) => {
        lemmajAxios
        .get("/LemmajUsers", {
            params: {
                access_token: userId,
            },
        ...axiosOption,
        })
        .then((res) => {
        setIsLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
            return onLogout();
        }
        if (res.data.error && res.data.error.statusCode === 404) {
            return res.json({ message: "data is not found" });
        }
        const scheduleList = [];
        const data = scheduledData.map((schedule) => {
            if(new Date(schedule.scheduledDate) < new Date()){
                const user = [];
                const userTemp = res.data.map((u) => {
                    if(u.id == schedule.studentId){
                        user.push(u)
                        return u;
                    }
                    return null;
                });
                scheduleList.push({
                    studentName: `${user[0].firstName} ${user[0].lastName}`,
                    scheduledDate: moment(schedule.scheduledDate).format(
                      "MMM DD, YY"
                    ),
                  });
                  
                return {
                    studentName: `${user[0].firstName} ${user[0].lastName}`,
                    scheduledDate: moment(schedule.scheduledDate).format(
                      "MMM DD, YY"
                    ),
                  };
                }
        });
        setAbsents(scheduleList);
        })
        .catch((err) => {
        setIsLoading(false);
        });

    }
}
