import React from "react";
import { Row, Col, Avatar } from "antd";
import Colors from "../../constants/Colors";
const UserProfileSiderHeader = ({ userProfileAvatar, user }) => {
  return (
    <Row align="middle" gutter={(16, 16)}>
      <Col>{userProfileAvatar}</Col>
      <Col>
        <Row justify="start">
          <Col style={{ color: Colors.white }}>{`${user.name}`}</Col>
        </Row>
        <Row justify="start">
          <Col style={{ color: Colors.white }}> {user.phone} </Col>
        </Row>
        <Row justify="start" style={{ color: Colors.white }}>
          <Col>{`${user.username}`}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserProfileSiderHeader;
