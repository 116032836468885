import React, { useEffect, useState } from "react";
import { Badge, Menu, message } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  GiftOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  InfoCircleOutlined,
  FileDoneOutlined,
  CarOutlined,
} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import Routes from "../../Routes";
import { NavLink, useHistory } from "react-router-dom";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import getScheduledStudents from "../StudentStatus/QUERIES/getScheduledStudents";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function SiderMenu({
  authToken = null,
  onLogout = () => {},
  pathState = "",
  studentPendingCount = 0,
  leavePendingCount = 0,
}) {
  const [fetching, setFetching] = useState(true);
  const [pendingStudentCount, setPendingStudentCount] = useState(0);
  const [scheduledStudentCount, setScheduledStudentCount] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingLeaveRequestCount, setPendingLeaveRequestCount] = useState(0);
  const [scheduledData, setScheduledData] = useState([]);
  const history = useHistory();
  const [pathname, setCurrentLocation] = useState(history.location.pathname);

  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      .get("/LemmajUsers", {
        params: {
          access_token: authToken,
          "filter[where][userType]": "student",
          "filter[where][isApproved]": false,
          "filter[where][isRejected]": false,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const data = res.data.map((c) => {
          return {
            name: c.firstName + " " + c.lastName,
            pending_time: c.createdDate,
            id: c.id,
          };
        });
        setPendingStudentCount(res.data.length);

        lemmajAxios
          .get("/Leaves/count", {
            params: {
              access_token: authToken,
              "[where][isApproved]": false,
              "[where][isRejected]": false,
            },
            ...axiosOption,
          })
          .then((res) => {
            setFetching(false);
            if (res.data.error && res.data.error.statusCode === 401) {
              onLogout();
              return;
            }
            if (res.data.error && res.data.error.statusCode === 404) {
              message.error(Messages.ITEM_NOT_FOUND);
              return;
            }
            setPendingLeaveRequestCount(res.data.count);
          })
          .catch((err) => {
            setFetching(false);
            message.error(Messages.OPREATION_FAILED);
          });
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  useEffect(() => {
    getScheduledStudents(
      authToken,
      axiosOption,
      setIsLoading,
      setScheduledData,
      onLogout
    );
    fetchData();
    // setFetching(false);
  }, []);

  return (
    <Menu
      onSelect={() => {
        setCurrentLocation(history.location.pathname);
      }}
      theme="dark"
      mode="inline"
      style={{ background: Colors.blue2 }}
      defaultSelectedKeys={["/"]}
      selectedKeys={[history.location.pathname]}
    >
      <Menu.ItemGroup title="Students">
        <Menu.Item
          key="1"
          icon={<DashboardOutlined />}
          style={{
            color: Colors.white,
            background:
              history.location.pathname.includes(Routes.STUDENTS_PENDING) ||
              history.location.pathname === Routes.BASE ||
              history.location.pathname === `${Routes.BASE}/`
                ? Colors.blue3
                : Colors.blue2,
          }}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.STUDENTS_PENDING}>
            Pending Students
            <Badge
              style={{ margin: 5, borderWidth: 0 }}
              count={pendingStudentCount}
            ></Badge>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="2"
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(Routes.STUDENTS)
              ? Colors.blue3
              : Colors.blue2,
          }}
          icon={<UsergroupAddOutlined />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.STUDENTS}>
            Students
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="9"
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(
              Routes.CERTIFIED_STUDENTS
            )
              ? Colors.blue3
              : Colors.blue2,
          }}
          icon={<UsergroupAddOutlined />}
        >
          <NavLink
            style={{ color: Colors.white }}
            to={Routes.CERTIFIED_STUDENTS}
          >
            Certified Students
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="10"
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(
              Routes.STUDENT_STATUS
            )
              ? Colors.blue3
              : Colors.blue2,
          }}
          icon={<InfoCircleOutlined />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.STUDENT_STATUS}>
            Status
          </NavLink>
          <Badge
            style={{ margin: 5, borderWidth: 0 }}
            count={scheduledData.length}
          ></Badge>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Instructors">
        <Menu.Item
          key="3"
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(Routes.INSTRUCTORS)
              ? Colors.blue3
              : Colors.blue2,
          }}
          icon={<UsergroupAddOutlined />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.INSTRUCTORS}>
            Instructors
          </NavLink>
        </Menu.Item>
        <Menu.Item
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(Routes.VECHILES)
              ? Colors.blue3
              : Colors.blue2,
          }}
          key="4"
          icon={<CarOutlined />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.VECHILES}>
            Vehicles
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="8"
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(Routes.LEAVE_PENDING)
              ? Colors.blue3
              : Colors.blue2,
          }}
          icon={<FileDoneOutlined />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.LEAVE_PENDING}>
            Leave Requests
            <Badge
              style={{ margin: 5, borderWidth: 0 }}
              count={leavePendingCount}
            ></Badge>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Packages and Courses">
        <Menu.Item
          key="4"
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(Routes.PACKAGES)
              ? Colors.blue3
              : Colors.blue2,
          }}
          key={1}
          icon={<GiftOutlined />}
        >
          <NavLink key={1} style={{ color: Colors.white }} to={Routes.PACKAGES}>
            Packages
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="5"
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(Routes.COURSE)
              ? Colors.blue3
              : Colors.blue2,
          }}
          icon={<UnorderedListOutlined />}
        >
          <NavLink key={2} style={{ color: Colors.white }} to={Routes.COURSE}>
            Courses
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Account">
        <Menu.Item
          key="6"
          icon={<UserOutlined />}
          style={{
            color: Colors.white,
            background: history.location.pathname.includes(Routes.ACCOUNT)
              ? Colors.blue3
              : Colors.blue2,
          }}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.ACCOUNT}>
            Account
          </NavLink>
        </Menu.Item>
        <Menu.Item
          onClick={onLogout}
          key="7"
          icon={<LogoutOutlined />}
          style={{ color: Colors.white }}
        >
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
}

export default SiderMenu;
