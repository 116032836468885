import React from "react";
import { Comment, List, Form, Button, Input } from "antd";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import moment from "moment";
import Colors from "../../../constants/Colors";

const Editor = ({ onSubmit, submitting }) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      style={{ width: "100%" }}
      layout="vertical"
      onFinish={(value) => {
        onSubmit(value["content"]);
        form.resetFields();
      }}
    >
      <Form.Item
        label="Comment"
        name="content"
        rules={[{ required: true, message: "" }]}
      >
        <Input.TextArea
          color={Colors.veryLightGrey}
          placeholder="Add comment"
          width="100%"
          style={{
            paddingRight: 20,
            borderRadius: 3,
          }}
          allowClear
          rows={2}
          showCount
          maxLength={200}
        />
      </Form.Item>
      <Form.Item>
        <Button
          style={{
            background: Colors.blue2,
            borderColor: Colors.blue2,
            borderRadius: 16,
          }}
          htmlType="submit"
          loading={submitting}
          type="primary"
        >
          Comment
        </Button>
      </Form.Item>
    </Form>
  );
};

function Comments({
  comments = [],
  onCommentSubmit = () => null,
  addCommentForm = true,
  visible = true,
  author = null,
}) {
  return (
    visible && (
      <List
        pagination={{ pageSize: 6 }}
        itemLayout="horizontal"
        dataSource={[1]}
        renderItem={(items) => (
          <List.Item>
            {addCommentForm ? <Editor onSubmit={onCommentSubmit} /> : null}
            {/* <Comment
            style={{ width: "100%" }}
            datetime={moment().format("MM-DD-YYYY, HH:mm")}
            author={author.firstName + " " + author.lastName}
            content={<Editor onSubmit={onCommentSubmit} />}
            avatar={
              author && (
                <UserAvatar
                  name={author.firstName + " " + author.lastName}
                  imagSrc={author.imgSrc}
                />
              )
            }
          /> */}
          </List.Item>
        )}
      >
        <List
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={(item) => {
            return (
              <List.Item>
                <Comment
                  className="comment-list"
                  datetime={item.dateTime}
                  author={item.author}
                  content={item.content}
                  avatar={
                    <UserAvatar name={item.author} imagSrc={item.imgSrc} />
                  }
                />
              </List.Item>
            );
          }}
        ></List>
      </List>
    )
  );
}

export default Comments;
