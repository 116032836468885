import { Card, message, Spin } from 'antd';
import React, { useState } from 'react';
import PasswordChangeForm from '../../../component/PasswordChangeForm/PasswordChangeForm';
import RestorePassword from './RestorePassword';
import lemmajAxios from "../../../others/apiConfig";
import Messages from '../../../others/Messages';
import { useHistory } from 'react-router';
import Routes from '../../Routes';

const axiosOption = {
    validateStatus: function (status) {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
  };

const RestorePasswordInstructor = ({
    authToken = null,
    onLogout = () => {},
  }) => {
    const [submmiting, setSubmmittng] = useState(false);
    const history = useHistory();

    const onChangePassword = (values, submmittng) => {
        const instructor_id = history.location.search.slice("id?=".length);
        const data = {
            password: values.newPassword,
        };
        setSubmmittng(true);
        lemmajAxios
        .patch(`/LemmajUsers/${instructor_id}`, data, {
            params: {
            access_token: authToken,
            },
            ...axiosOption,
        })
        .then((res) => {
            if (res.data.error && res.data.error.statusCode === 401) {
            onLogout();
            return;
            }
            if (res.data.error && res.data.error.statusCode === 404) {
            message.error(Messages.ITEM_NOT_FOUND);
            return;
            }
            message.success(Messages.INFO_UPDATED);
            history.replace(`${Routes.INSTRUCTOR_DETAIL}?id=${instructor_id}`);
            setSubmmittng(false);
        })
        .catch((err) => {
            setSubmmittng(false);
            message.error(Messages.OPREATION_FAILED);
        });
        return;
    };

    return (
        <Spin spinning={submmiting}>
            <RestorePassword isNewPassword onSubmit={onChangePassword} />
        </Spin>
    );
}

export default RestorePasswordInstructor;
