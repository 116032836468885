import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Card,
  DatePicker,
  Select,
  InputNumber,
  Row,
  Col,
  Button,
  Upload,
  Divider,
  Typography,
  Switch,
  message,
  Spin,
} from "antd";
import ImageUpload from "../../../component/ImageUpload/ImageUpload";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import { useHistory } from "react-router";
import Routes from "../../Routes";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function VechileProfile({
  gearType,
  carModel,
  modelYear,
  drivenYear,
  authToken,
  user = { id: 0 },
  onLogout,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [instructors, setInstructors] = useState([]);

  const getInstructors = () => {
    setLoading(true);
    lemmajAxios
      .get(
        `/LemmajUsers?filter[where][userType]=Instructor&access_token=${authToken}`,
        axiosOption
      )
      .then((res) => {
        setLoading(false);
        if (res.data.error) {
          message.error(res.statusText);
          return;
        }
        setInstructors(res.data);
      })
      .catch((err) => { });
  };
  const onAddVehicle = (values) => {
    const data = {
      gearType: values.gearType,
      owner: values.owner,
      plate: values.plateNumber,
      chasisNumber: "NA",
      ensuranceExpiryDate: Date.now(),
      bolo: Date.now(),
      model: values.carModel,
      year: values.modelYear,
      image: "NA",
      lemmajUserId: values.owner,
      createdDate: Date.now(),
    };
    setLoading(true);
    lemmajAxios
      .post("/Vehicles", data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }
        history.replace(Routes.VECHILES);
        message.success("Vehicle added successfully");
      })
      .catch((err) => {
        setLoading(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  const history = useHistory();
  useEffect(() => {
    getInstructors();
  }, []);
  return (
    <Spin spinning={loading}>
      <Card>
        <Typography.Title style={{ color: Colors.darkGrey }} level={4}>
          Vehicle Information
        </Typography.Title>
        {/* <ImageUpload
        title={"Vehicle"}
        multiple={false}
        fileList={fileList}
        maximumUploads={2}
        onChange={(value) => setFileList(value.fileList)}
      />
      <Divider style={{ margin: 0, padding: 0 }} /> */}
        <Form
          form={form}
          style={{ marginTop: 0 }}
          layout="vertical"
          onFinish={onAddVehicle}
        >
          {/* <Form.Item name="cardPicture" label="">
          <Upload listType="picture-card" />
        </Form.Item> */}
          <Form.Item
            label="Gear Type"
            name="gearType"
            rules={[{ required: true, message: "Select Gear Type" }]}
          >
            <Select>
              <Select.Option key="automatic">Automatic</Select.Option>
              <Select.Option key="manual">Manual</Select.Option>
              <Select.Option key="hybrid">Hybrid</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Vehicle Category"
            name="category"
            rules={[{ required: true, message: "Select Vehichle Category" }]}
          >
            <Select>
              <Select.Option key="automobile">Automobile</Select.Option>
              <Select.Option key="truck1">Truck - 1</Select.Option>
              <Select.Option key="truck2">Truck - 2</Select.Option>
              <Select.Option key="truck3">Truck - 3</Select.Option>
              <Select.Option key="truck4">Truck - 5</Select.Option>
              <Select.Option key="truck5">Truck - 4</Select.Option>
              <Select.Option key="truck6">Truck - 6</Select.Option>
              <Select.Option key="bus1">Bus - 1</Select.Option>
              <Select.Option key="bus2">Bus - 2</Select.Option>
              <Select.Option key="bus3">Bus - 3</Select.Option>
              <Select.Option key="bus4">Bus - 4</Select.Option>
              <Select.Option key="bus5">Bus - 5</Select.Option>
              <Select.Option key="bus6">Bus - 6</Select.Option>
              <Select.Option key="special1">Machinary - 1</Select.Option>
              <Select.Option key="special2">Machinary - 2</Select.Option>
              <Select.Option key="special3">Machinary - 3</Select.Option>
              <Select.Option key="special4">Machinary - 4</Select.Option>
              <Select.Option key="special5">Machinary - 5</Select.Option>
              <Select.Option key="special6">Machinary - 6</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="carModel"
            label="Car Model"
            rules={[{ required: true, message: "Car model is required" }]}
          >
            <Input maxLength={15} />
          </Form.Item>
          <Form.Item
            name="plateNumber"
            label="Plate Number"
            rules={[{ required: true, message: "Plate Number is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="owner"
            label="Owner"
            rules={[
              { required: true, message: "Owner of the vehicle is requied" },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option key="none">none</Select.Option>
              {instructors.map((i) => (
                <Select.Option value={i.id} key={i.id}>
                  {`${i.firstName} ${i.lastName} - ${i.email}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="modelYear"
            label="Model Year"
            rules={[{ required: true, message: "Model Year is required" }]}
          >
            <DatePicker picker="year" />
          </Form.Item>
          {/* <Form.Item
          name="drivenYear"
          label="Driven Year"
          rules={[
            {
              required: true,
              message: "Number of Driven years is required",
            },
            () => ({
              validator(_, value) {
                if (
                  Number.isNaN(!value) ||
                  !Number.isInteger(value) ||
                  value < 0
                ) {
                  return Promise.reject("Value must be valid");
                } else if (value > 99) {
                  return Promise.reject("Maxium driven years is 99");
                }
                return Promise.resolve();
              },
            }),
          ]}
        > 
          <InputNumber maxLength={2} min={0} max={50} />
        </Form.Item> */}
          {/* <Form.Item name="carActivated" label="Use Vechile">
            <Switch defaultChecked />{" "}
            <Typography.Text style={{ margin: 5, color: Colors.green1 }}>
              {" "}
              Vehicle has been activated{" "}
            </Typography.Text>
          </Form.Item> */}
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  loading={loading}
                  style={{
                    background: Colors.blue2,
                    borderColor: Colors.blue2,
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Add Vehilce
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default VechileProfile;
