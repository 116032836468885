import React, { useState } from "react";
import AppHeader from "../AppHeader/AppHeader";
import AppSider from "../AppSider/AppSider";
import AppTitleBoard from "../AppTitleBoard/AppTitleBoard";
import { Layout, BackTop, Affix, Button } from "antd";
import Backdrop from "../BackDrop/BackDrop";
import Colors from "../../constants/Colors";
import { UpOutlined } from "@ant-design/icons";
const { Content } = Layout;
function AppLayout({
  title = "",
  siderMenu,
  userProfileSiderHeader = null,
  headerNavItems,
  content,
  siderMenuShow = true,
}) {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [isMobileView, setMobileView] = useState(false);
  const handleBreakPointChanged = (collapsed) => {
    setSiderCollapsed(collapsed);
    setMobileView(collapsed);
  };
  const closeSiderHandler = () => {
    setSiderCollapsed(true);
  };
  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };
  const style = {
    // height: 40,
    // width: 40,
    lineHeight: "40px",
    // borderRadius: 4,
    backgroundColor: "#1088e9",
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
    right: 20,
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Backdrop
        onClickHandler={closeSiderHandler}
        show={isMobileView && !siderCollapsed}
      />
      <AppSider
        show={siderMenuShow}
        siderHeader={userProfileSiderHeader}
        siderMenu={siderMenu}
        onBreakpointChanged={(br) => {
          handleBreakPointChanged(br);
        }}
        siderCollapsed={siderCollapsed}
        isMobileView={isMobileView}
      />

      <Layout style={{ background: Colors.veryLightGrey }}>
        <AppHeader
          headerNavItems={headerNavItems}
          toggleSider={toggleSider}
          siderCollapsed={siderCollapsed}
        />
        <AppTitleBoard title={title} />
        <Content style={{ margin: "15px" }}>{content}</Content>
      </Layout>
      <BackTop visibilityHeight={10}>
        <Button
          size="large"
          shape="circle"
          icon={<UpOutlined />}
          style={style}
        ></Button>
      </BackTop>
    </Layout>
  );
}

export default AppLayout;
