import React from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Progress,
  Divider,
  Typography,
  Alert,
  Form,
  DatePicker,
  Dropdown,
  Select,
} from "antd";
import {} from "@ant-design/icons";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import Colors from "../../../constants/Colors";
import Instructors from "../Instructors/Instructors";
import Instructor from "../Instructor/Instructor";

function InstructorSchedule({ userData, periods, selectedPeriod = true }) {
  return (
    <>
      <Card>
        <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
          Instructor
        </Typography.Title>
        <Instructor accessToken={userData.id} />
      </Card>
      {/* {selectedPeriod ? (
        <Card>
          <Typography.Text strong style={{ color: Colors.darkGrey }}>
            Schedule Period
          </Typography.Text>
          <Row justify="space-between" align="middle">
            <Col>
              <Alert
                style={{ width: "100%" }}
                banner
                description="Next session is on jan-2-2012, 4:00 morning"
              ></Alert>
              <Button
                size="large"
                style={{
                  background: Colors.blue2,
                  borderColor: Colors.blue2,
                  color: Colors.white,
                  width: "100%",
                }}
              >
                <Typography.Text strong style={{ color: Colors.white }}>
                  Schedule For The Next Session{" "}
                </Typography.Text>
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Card>
      ) : null} */}
      {/* <Card>
        <Form layout="vertical">
          <Form.Item name="period" label="Period">
            <Select>
              <Select.Option key={"Period 1"}> Period 1</Select.Option>
              <Select.Option key={"Period 2"}> Period 2</Select.Option>
              <Select.Option key={"Period 3"}> Period 3</Select.Option>
              <Select.Option key={"Period 4"}> Period 4</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="date" label="Choose date to schedule">
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Row>
              <Col xs={24} sm={8}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: Colors.blue2,
                    borderColor: Colors.blue2,
                    color: Colors.white,
                  }}
                >
                  <b> Schedule Period</b>
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card> */}
    </>
  );
}

export default InstructorSchedule;
