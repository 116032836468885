export default {
  PACKAGE: "/driver/package",
  PROFILE: "/driver/profile",
  CERTIFICATE: "/driver/certificate",
  ACCOUNT: "/driver/account",
  INSTRCUTOR_SEARCH: "/driver/instructors_search",
  INSTRUCTOR_SCHEDULE: "/driver/instructor_schedule",
  SET_INSTRUCTOR: "/driver/set_instructor",
  SCHEDULE: "/driver/schedule",
  SCEHEDULE_CALENDER: "/driver/instructor_calendar",
  BASE: "/driver",
};
