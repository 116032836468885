import React from "react";
import { Button, Col, List, Row, Select, Space, Tag } from "antd";
import {
  CalendarOutlined,
  CheckOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import { useHistory, NavLink } from "react-router-dom";
import Routes from "../../Routes";
import moment from "moment";
import Form from "antd/lib/form/Form";
import FeedbackForm from "./FeedbackForm";

function PeriodDetails({
  period = {},
  courses = [],
  schedule = {},
  periodStatus,
  allowSchedule = false,
}) {
  const history = useHistory();
  const truncateDescription = (values) => {
    if (values.length >= 50) {
      values = values.slice(0, 50) + "....";
    }
    return values;
  };

  const onScheduleClick = () => {
    history.push({
      pathname: Routes.INSTRCUTOR_SEARCH,
      state: { period: period },
    });
  };

  const icon =
    periodStatus === "completed" ? (
      <CheckOutlined style={{ color: Colors.green2 }} />
    ) : periodStatus === "scheduled" ? (
      schedule.isRejected ? (
        <>
          <WarningOutlined style={{ color: Colors.blue2 }} />
          <CalendarOutlined style={{ color: Colors.blue2 }} />
        </>
      ) : (
        <CalendarOutlined style={{ color: Colors.blue2 }} />
      )
    ) : periodStatus === "not started" ? (
      <ClockCircleOutlined style={{ color: Colors.red }} />
    ) : periodStatus === "waiting" ? (
      <SyncOutlined spin style={{ color: Colors.orange }} />
    ) : null;
  const footer =
    periodStatus === "completed" ? (
      <FeedbackForm period={period} pastFeedback={period.feedback} />
    ) : periodStatus === "scheduled" ? (
      schedule.isRejected ? (
        <>
          Scheduled on
          {` ${moment(new Date(schedule.startDate)).format("MMM-DD-YYYY")}`}
          <b> is REJECTED</b>
          <br />
          <br />
          {allowSchedule && (
            <Button
              disabled={!allowSchedule}
              type="primary"
              style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
              onClick={onScheduleClick}
            >
              {" "}
              Schedule Period
            </Button>
          )}
        </>
      ) : (
        `Scheduled on ${moment(new Date(schedule.startDate)).format(
          "MMM-DD-YYYY"
        )}`
      )
    ) : periodStatus === "not started" ? (
      allowSchedule ? (
        <Button
          disabled={!allowSchedule}
          type="primary"
          style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
          onClick={onScheduleClick}
        >
          {" "}
          Schedule Period
        </Button>
      ) : (
        <b>Complete the above periods before</b>
      )
    ) : periodStatus === "waiting" ? (
      "Scheduled date has passed, waiting for status"
    ) : null;

  return (
    <List
      footer={footer}
      dataSource={courses}
      renderItem={(i) => (
        <List.Item key={i.id}>
          <List.Item.Meta
            title={
              <Space direction="horizontal">
                {icon}
                {i.name}
              </Space>
            }
            description={
              i.description
                ? truncateDescription(i.description)
                : truncateDescription(i.name)
            }
          />
          <div>
            <Tag color={Colors.darkGrey}>
              <Space>
                <ClockCircleOutlined />
                {`${i.length} miutes`}
              </Space>
            </Tag>
          </div>
        </List.Item>
      )}
    />
  );
}

export default PeriodDetails;
