import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Divider,
  Typography,
  Card,
  InputNumber,
  Spin,
  message,
} from "antd";
import { UserOutlined, MailFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import { useHistory } from "react-router";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import Routes from "../../Routes";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function Vehicle({ authToken = null, onLogout = () => {} }) {
  const [submmiting, setSubmmittng] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const history = useHistory();
  const [form] = Form.useForm();

  const fillForm = (plate, model, gearType, year, id, gender) => {
    form.setFieldsValue({
      plateNumber: plate,
      carModel: model,
      gearType: gearType,
      modelYear: year
    });
  };

  const fetchDataAndFillData = () => {
    const instructor_id = history.location.search.slice("id?=".length);
    setFetching(true);
    lemmajAxios
      .get(`/LemmajUsers/${instructor_id}/vehicle`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const vehicle = res.data[0];
        setVehicle(vehicle);
        fillForm(vehicle.plate, vehicle.model, vehicle.gearType, vehicle.year);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchDataAndFillData();
  }, []);

  const onUpdateBasicInstructor = (values) => {
    const instructor_id = history.location.search.slice("id?=".length);
    const data = {
      id: vehicle.id,
      owner: vehicle.owner,
      ensuranceExpiryDate: vehicle.ensuranceExpiryDate,
      bolo: vehicle.bolo,
      plate: values.plateNumber,
      model: values.carModel,
      gearType: values.gearType,
      year: values.modelYear,
    };
    setSubmmittng(true);
    lemmajAxios
      .patch(`/Vehicles`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        message.success(Messages.INFO_UPDATED);
        history.replace(`${Routes.INSTRUCTOR_DETAIL}?id=${instructor_id}`);
        setSubmmittng(false);
      })
      .catch((err) => {
        setSubmmittng(false);
        message.error(Messages.OPREATION_FAILED);
      });
    return;
  };

  return (
    <Spin spinning={submmiting}>
      <Card loading={fetching}>
        <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
          Vehicle Information
        </Typography.Title>
        <Divider />
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            onUpdateBasicInstructor(values);
          }}
        >
          <Form.Item
            label="Plate Number"
            name="plateNumber"
            rules={[{ required: true, message: "Plate number is required" }]}
          >
            <Input placeholder="Plate Number" />
          </Form.Item>
          <Form.Item
            label="Model Name"
            name="carModel"
            rules={[{ required: true, message: "Model name is required" }]}
          >
            <Input placeholder="Car model" />
          </Form.Item>
          <Form.Item
            label="Gear Type"
            name="gearType"
            rules={[{ required: true, message: "Select gear type" }]}
          >
            <Select>
              <Select.Option key={"auto"}>Auto</Select.Option>
              <Select.Option key={"hybrid"}>Hybrid</Select.Option>
              <Select.Option key={"manual"}>Manual</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Model year"
            name="modelYear"
            rules={[{ required: true, message: "Model year is required" }]}
          >
            <Input placeholder="Model | Year" />
          </Form.Item>
          {/* <Form.Item
            label="Year Driven"
            name="yearDriven"
            rules={[
              {
                type: "number",
                required: true,
                message: "Driven year is required",
              },
            ]}
          >
            <InputNumber step={1} width={"100%"} placeholder="" />
          </Form.Item> */}
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  style={{
                    background: Colors.blue2,
                    borderRadius: Colors.blue2,
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default Vehicle;
