import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu } from "antd";
import { NavLink } from "react-router-dom";
import Routes from "../../Routes";

function HeaderNavs({ onLogout = () => null }) {
  const menu = (
    <Menu>
      <Menu.Item key="0" icon={<UserOutlined />}>
        <NavLink to={Routes.ACCOUNT}>Account</NavLink>
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined />} key="1">
        <span onClick={onLogout}>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Avatar
        icon={<UserOutlined />}
        style={{ marginRight: "30px" }}
        size={35}
      />
    </Dropdown>
  );
}

export default HeaderNavs;
