import lemmajAxios from "../../../../others/apiConfig";
import moment from "moment";
export default function getScheduledStudents(
  userId,
  axiosOption,
  setIsLoading,
  setScheduledData,
  onLogout
) {
  lemmajAxios
    .get("/Schedules/getAllSchedulesForAdmin", {
      params: {
        access_token: userId,
        "filter[where][isApproved]": false,
        "filter[where][isRejected]": false,
      },
      ...axiosOption,
    })
    .then((res) => {
      setIsLoading(false);
      if (res.data.error && res.data.error.statusCode === 401) {
        onLogout();
      }
      if (res.data.error && res.data.error.statusCode === 404) {
        return res.json({ message: "data is not found" });
      }
      const dataForScheduledStudent = res.data.filter((schedule) => {
        return (schedule =
          schedule.schedule.hasOwnProperty("studentId") === true);
      });
      const data = dataForScheduledStudent.map((schedule) => {
        return {
          id: schedule.schedule.id,
          instructorName: `${schedule.instructor.firstName} ${schedule.instructor.lastName}`,
          studentName: `${schedule.student.firstName} ${schedule.student.lastName}`,
          endDate: moment(schedule.schedule.endDate).format("MMM DD, YY"),
          startDate: moment(schedule.schedule.startDate).format("MMM DD, YY"),
          scheduledDate: moment(schedule.schedule.scheduledDate).format(
            "MMM DD, YY"
          ),
          scheduleStatus: schedule.schedule.scheduleStatus,
        };
      });
      setScheduledData(data.reverse());
    })
    .catch((err) => {
      setIsLoading(false);
    });
}
