import React from "react";
import { Typography, Form, Input, Button, Row, Col } from "antd";
import { LockFilled } from "@ant-design/icons";
import Colors from "../../constants/Colors";
function PasswordChangeForm({
  isNewPassword = false,
  submitBtnText = "Update Information",
  onSubmit,
  onCancel = null,
  cancelText = "CANCEL",
}) {
  const passwordFormRef = React.createRef();
  return (
    <>
      <Typography.Title
        level={4}
        style={{
          color: Colors.darkGrey,
        }}
      >
        {" "}
        Password
      </Typography.Title>
      <Form
        layout="vertical"
        ref={passwordFormRef}
        onFinish={(v) => {
          onSubmit(v);
        }}
      >
        {!isNewPassword && (
          <Form.Item
            name="oldPasssword"
            label="Old Passowrd"
            rules={[{ required: true, message: "Old password is required" }]}
          >
            <Input.Password
              addonBefore={<LockFilled />}
              placeholder="Old Password"
            />
          </Form.Item>
        )}
        <Form.Item
          name="newPassword"
          label="New Passowrd"
          rules={[
            { required: true, message: "New password is required" },
            () => ({
              validator(rule, value) {
                if (value && value.length < 6) {
                  return Promise.reject("Minimum password length allowed is 6");
                }
                if (value && value.length > 35) {
                  return Promise.reject(
                    "Maximum password length allowed is 35"
                  );
                }
                //   const passdReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[ \~\`\!\@\#\$\%\^\&\*\(\)\_\+\=\-\{\}\[\]\|\\\"\'\:\;\?\>\<\.\,\/])/;
                //   if (!value.match(passdReg)) {
                //     return Promise.reject(
                //       "Password must contian an uppercase a lowercase a digit and a special character"
                //     );
                //   }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            addonBefore={<LockFilled />}
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Passowrd"
          rules={[
            { required: true, message: "Confirm password field is required" },
            () => ({
              validator(rule, value) {
                if (
                  passwordFormRef.current.getFieldValue("newPassword") != value
                ) {
                  return Promise.reject("Password does not match");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            addonBefore={<LockFilled />}
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between">
            <Col xs={24} sm={8}>
              <Button
                style={{ background: Colors.blue2, borderRadius: Colors.blue2 }}
                block
                type="primary"
                htmlType="submit"
              >
                {submitBtnText}
              </Button>
            </Col>
            {onCancel ? (
              <Col xs={24} sm={8}>
                <Button
                  onClick={() => {
                    onCancel();
                  }}
                  style={{
                    background: Colors.orange,
                    borderColor: Colors.orange,
                  }}
                  block
                  type="primary"
                >
                  {cancelText}
                </Button>
              </Col>
            ) : null}
          </Row>
        </Form.Item>
      </Form>
    </>
  );
}

export default PasswordChangeForm;
