import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./AppInputSearch.css";
import Colors from "../../constants/Colors";
const { Search } = Input;
function AppInputSearch({ placeholder, onSearch }) {
  return (
    <Input
      onChange={onSearch}
      size="large"
      style={{
        borderRadius: 20,
        background: Colors.veryLightGrey,
        padding: 10,
        borderBottomWidth: 300,
      }}
      bordered={false}
      className={"seachInput"}
      prefix={
        <SearchOutlined
          style={{
            color: Colors.veryDarkGrey,
            marginRight: 10,
            marginLeft: 10,
          }}
        />
      }
      onChange={(value) => onSearch(value.target.value)}
      placeholder={placeholder}
    />
  );
}

export default AppInputSearch;
