import React from "react";
import { Card, Avatar, Row, Col, Typography, Timeline, Empty } from "antd";
import { ClockCircleFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import TodayScheduleTimeline from "./TodayScheduleTimeline";
import TodayScheduleCard from "./TodayScheduleCard";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";
import moment from "moment";

function TodayScheduledStudent({ students }) {
  const history = useHistory();
  const schedules = students.map((i) => {
    const startH = Number(
      moment(new Date(i.schedule.startDate), "hh:mm a").format("hh")
    );
    const startM = moment(new Date(i.schedule.startDate), "hh:mm a").format(
      "mm"
    );

    const startAM_PM = moment(new Date(i.schedule.startDate), "hh:mm a").format(
      "a"
    );

    return [startH, startM, startAM_PM];
  });

  return (
    <>
      <Card style={{ marginBottom: 8, borderRadius: 16 }}>
        <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
          Today's Schedule
        </Typography.Title>
        <TodayScheduleTimeline schedules={schedules} />
      </Card>

      {students.length <= 0 ? (
        <Empty description="You don't have any schedule today" />
      ) : (
        students.map((i) => {
          const startH = moment(
            new Date(i.schedule.startDate),
            "hh:mm a"
          ).format("hh");
          const startM = moment(
            new Date(i.schedule.startDate),
            "hh:mm a"
          ).format("mm");
          const startAM_PM = moment(
            new Date(i.schedule.startDate),
            "hh:mm a"
          ).format("a");
          return (
            <TodayScheduleCard
              key={i.schedule.id}
              onClick={() =>
                history.push(`${Routes.STUDENT}?id=${i.student.id}`)
              }
              time={[startH, startM, startAM_PM]}
              student={{
                firstName: i.student.firstName,
                lastName: i.student.lastName,
                imgSrc: "",
                phone: i.student.phoneNumber,
              }}
            />
          );
        })
      )}
    </>
  );
}

export default TodayScheduledStudent;
