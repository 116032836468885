import React, {useState, useEffect} from "react";
import { Menu } from "antd";
import Colors from "../../../constants/Colors";
import {
  GiftFilled,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  CheckOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { NavLink, useHistory } from "react-router-dom";
import Routes from "../../Routes";
import lemmajAxios from "../../../others/apiConfig";
import "./SiderMenus.css";

function SiderMenu({ onLogout }) {

  const [isCertified, setIsCertified] = useState(false);
  const history = useHistory();
  const userId = JSON.parse(localStorage.getItem('userAuth')).userId;

  const getCertifiedUsers = () => {
    lemmajAxios
      .get("/CertifiedUsers", {
        params: {
          access_token: userId,
          "filter[where][lemmajUserId][like]":userId
        },
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          return;
        }
        if(res.data.length > 0) {
          setIsCertified(true)
        } else {
          setIsCertified(false)
        }
      })
      .catch((res) => {
        setIsCertified(false)
      });
  };

  useEffect(() => {
    getCertifiedUsers();
  }, [])
  return (
    <Menu
      theme="dark"
      mode="inline"
      style={{ background: Colors.blue2 }}
      defaultSelectedKeys={["2"]}
    >
      <Menu.ItemGroup title="Package">
        <Menu.Item
          style={{
            color: Colors.white,
            background:
              history.location.pathname === Routes.PACKAGE
                ? Colors.blue3
                : Colors.blue2,
          }}
          key="2"
          icon={<GiftFilled />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.PACKAGE}>
            My Packege
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Account And Profile">
        <Menu.Item
          style={{
            color: Colors.white,
            background:
              history.location.pathname === Routes.PROFILE
                ? Colors.blue3
                : Colors.blue2,
          }}
          key="3"
          icon={<UserOutlined />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.PROFILE}>
            Profile
          </NavLink>
        </Menu.Item>
        {isCertified== true ? 
        <Menu.Item
          style={{
            color: Colors.white,
            background:
              history.location.pathname === Routes.CERTIFICATE
                ? Colors.blue3
                : Colors.blue2,
          }}
          key="7"
          icon={<SafetyCertificateOutlined />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.CERTIFICATE}>
            Certificate
          </NavLink>
        </Menu.Item>
        : ''}
        <Menu.Item
          style={{
            color: Colors.white,
            background:
              history.location.pathname === Routes.ACCOUNT
                ? Colors.blue3
                : Colors.blue2,
          }}
          key="5"
          icon={<SettingOutlined />}
        >
          <NavLink style={{ color: Colors.white }} to={Routes.ACCOUNT}>
            Account Settings
          </NavLink>
        </Menu.Item>
        <Menu.Item
          onClick={onLogout}
          style={{
            color: Colors.white,
          }}
          key="4"
          icon={<LogoutOutlined />}
        >
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
}

export default SiderMenu;
