export default {
  BASE: "/admin",

  STUDENTS_PENDING: "/admin/students_pending",
  STUDENT_PENDING_DETAIL: "/admin/students_pending/student_pending_detail",

  STUDENTS: "/admin/students_list",
  CERTIFIED_STUDENTS: "/admin/certified_students",
  STUDENT_STATUS: "/admin/students_status",
  STUDENT_DETAIL: "/admin/students_list/student_detail",
  RESTORE_PASSWORD_STUDENT:
    "/admin/students_list/student_detail/restore_password_student",
  CHANGE_PACKAGE: "/admin/students_list/student_detail/changePackage",

  LEAVE_PENDING: "/admin/leave_pending",

  INSTRUCTORS: "/admin/instructors",
  CREAT_INSTRUCTOR: "/admin/instructors/instructor_create",
  INSTRUCTOR_DETAIL: "/admin/instructors/instructor",
  UPDATE_INSTRUCTOR: "/admin/instructors/instructor/instructor_update",
  RESTORE_PASSWORD_INSTRUCTOR:
    "/admin/instructors/instructor/restore_password_instructor",

  COURSE: "/admin/course",
  ADD_COURSE: "/admin/course/add_course",
  UPDATE_COURSE: "/admin/course/update_course",

  PACKAGES: "/admin/packages",
  ADD_PACKAGES: "/admin/packages/add_packages",
  UPDATE_PACKAGES: "/admin/packages/update_packages",
  VECHILES: "/admin/vechiles",
  VECHILE: "/admin/vechile",

  ACCOUNT: "/admin/account",
};
