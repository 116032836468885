import React, { useEffect, useState } from "react";
import { Button, Card, message, Space, Table, Modal, Spin } from "antd";
import {
  PlusOutlined,
  GiftOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import { useHistory } from "react-router-dom";
import lemmajAxios from "../../../others/apiConfig";
import Routes from "../../Routes";
import moment from "moment";
import Messages from "../../../others/Messages";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function Packages({ authToken, onLogout = () => {} }) {
  const history = useHistory();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: "Price",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      sorter: (a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space size="small">
          <Button
            onClick={() =>
              history.push(`${Routes.UPDATE_PACKAGES}/?id=${record.id}`)
            }
            size="small"
            type="primary"
            style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
            icon={<EditOutlined />}
          >
            Update
          </Button>
          {/* <Button
            loading={deleting}
            onClick={() => {
              onDeleteBtn(record.id);
            }}
            size="small"
            type="primary"
            style={{ background: Colors.orange, borderColor: Colors.orange }}
            icon={<DeleteOutlined />}
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      .get("/LemmajPackages", axiosOption)
      .then((res) => {
        setFetching(false);
        const data = res.data.map((p) => {
          return {
            name: p.name,
            duration: p.duration.length,
            price: p.price,
            id: p.id,
            createdDate: moment(p.createdDate).format("MMM-DD-YYYY"),
          };
        });
        setData(data);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  const onDeleteBtn = (id) => {
    Modal.confirm({
      title: Messages.DELETE_PACKAGE_CONFIRM,
      onOk: () => {
        handleDelete(id);
      },
    });
  };
  const handleDelete = (id) => {
    setDeleting(true);
    lemmajAxios
      .delete(`/LemmajPackages/${id}`, {
        params: { access_token: authToken },
        ...axiosOption,
      })
      .then((res) => {
        setDeleting(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.count <= 0) {
          message.error(Messages.ITEM_NOT_FOUND);
        } else {
          message.success(Messages.PACKAGE_DELETED);
          fetchData();
        }
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setDeleting(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Spin spinning={deleting}>
      <Card style={{ borderRadius: 16 }}>
        <Button
          onClick={() => history.push(Routes.ADD_PACKAGES)}
          icon={
            <>
              <PlusOutlined />
            </>
          }
          type="primary"
          style={{
            marginBottom: 20,
            background: Colors.blue2,
            borderColor: Colors.blue2,
          }}
        >
          Add Package
        </Button>
        <Table
          loading={fetching}
          size="large"
          scroll={{ x: 20 }}
          columns={columns}
          dataSource={data}
        />
      </Card>
    </Spin>
  );
}

export default Packages;
