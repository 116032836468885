import React from "react";
import { Layout, Row, Col, Affix, Image } from "antd";
import Trigger from "../Trigger/Trigger";
import Colors from "../../constants/Colors";
const { Header } = Layout;
function AppHeader({ siderCollapsed, toggleSider, headerNavItems }) {
  return (
    <Affix offsetTop={0}>
      <Header
        className="site-layout-sub-header-background"
        style={{
          padding: 0,
          background: Colors.white,
          boxShadow: "0px 0px 20px #eee",
        }}
      >
        <Row style={{ alignItems: "center" }} justify="space-between">
          <Col span={2} offset={1}>
            {siderCollapsed ? (
              <Trigger toggle={toggleSider} siderCollapsed />
            ) : null}
          </Col>

          <Image width={150} src="../../logo.png" />
          <Row justify="end">
            <Col>{headerNavItems}</Col>
          </Row>
        </Row>
      </Header>
    </Affix>
  );
}

export default AppHeader;
