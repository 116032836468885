import firebase from "firebase";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyB-YS58tbxqRd6w4WDpDCTa6t1_t2oTiR4",
  // authDomain: "lemmaj-bbb24.firebaseapp.com",
  // projectId: "lemmaj-bbb24",
  // storageBucket: "lemmaj-bbb24.appspot.com",
  // messagingSenderId: "933615282765",
  // appId: "1:933615282765:web:55ea1e938db93185b38a04",
  // measurementId: "G-7WLBZWQY7X",
  apiKey: "AIzaSyCLLERQEwpyPBqxOrXcRjPBbF1AqL_BBLg",
  authDomain: "client.lemmaj.com.et",
  projectId: "lemmaj-client",
  storageBucket: "lemmaj-bbb24.appspot.com",
  messagingSenderId: "231551235733",
  appId: "1:231551235733:web:717105670f5bcca1540d9c"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
