import React, { useState, useEffect } from "react";
import { Button, Space, Card, Table, Modal, List, message } from "antd";
import lemmajAxios from "../../../others/apiConfig";
import moment from "moment";
import Messages from "../../../others/Messages";
import Enrollment from "../../../Admin/component/Enrollment/Enrollment";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function MyPerformance({ user, authToken, onLogout }) {
  return (
    <Enrollment
      portal_view={1}
      filterStatus="completed"
      inst_id={user.id}
      onLogOut={onLogout}
      authToken={authToken}
    />
  );
}

export default MyPerformance;
