import React from "react";
import { Form, Typography, Input, Select, Row, Col, Button } from "antd";
import Colors from "../../constants/Colors";
function Location({
  onFormSubmit,
  onCancel,
  country = "",
  region = "",
  city = "",
}) {
  const [form] = Form.useForm();
  form.setFieldsValue({
    country,
    region,
    city,
  });
  return (
    <>
      <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
        Location Information
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => onFormSubmit(values)}
      >
        <Form.Item
          name="country"
          label="Country"
          rules={[{ required: true, message: "Country is required" }]}
        >
          <Input placeholder="Country" />
        </Form.Item>
        <Form.Item
          label="Region"
          name="region"
          rules={[{ required: true, message: "Select a region" }]}
        >
          <Select placeholder="Region">
            <Select.Option key="Addis Ababa City State">
              Addis Ababa City State
            </Select.Option>
            <Select.Option key="Afar">Afar</Select.Option>
            <Select.Option key="Amhara">Amhara</Select.Option>
            <Select.Option key="Benishangul Gumuz">
              Benishangul Gumuz
            </Select.Option>
            <Select.Option key=" Dire Dawa City State">
              Dire Dawa City State
            </Select.Option>
            <Select.Option key="Gambela">Gambela</Select.Option>
            <Select.Option key="Harari">Harari</Select.Option>
            <Select.Option key="Oromia">Oromia</Select.Option>
            <Select.Option key="SNNP">SNNP</Select.Option>
            <Select.Option key="Sidama">Sidama</Select.Option>
            <Select.Option key="Tigray">Tigray</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "City is required" }]}
        >
          <Input placeholder="City" />
        </Form.Item>
        {/* <Form.Item
          label="Subcity"
          name="subcity"
          rules={[{ required: true, message: "Subcity is required" }]}
        >
          <Input placeholder="Subcity" />
        </Form.Item>
        <Form.Item
          label="Woreda"
          name="woreda"
          rules={[{ required: true, message: "Woreda is required" }]}
        >
          <Input placeholder="Woreda" />
        </Form.Item> */}
        <Form.Item>
          <Row justify="space-between">
            <Col span={10}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{
                  borderRadius: 16,
                  background: Colors.blue2,
                  borderColor: Colors.blue2,
                }}
              >
                Next
              </Button>
            </Col>
            <Col span={10}>
              <Button
                onClick={onCancel}
                block
                type="primary"
                style={{
                  background: Colors.orange,
                  borderColor: Colors.orange,
                  borderRadius: 16,
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
}

export default Location;
