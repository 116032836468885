import { Card, Tabs } from "antd";
import React from "react";
import Basic from "./Basic";
import Licence from "./Licence";
import Vehicle from "./Vehicle";
import Location from "./Location";
const { TabPane } = Tabs;

function index({onLogOut, authToken}) {
  return (
    <Card>
      <Tabs>
        <TabPane tab="Basic" key="1">
          <Basic onLogOut={onLogOut} authToken={authToken} />
        </TabPane>
        <TabPane tab="Location" key="2">
          <Location onLogOut={onLogOut} authToken={authToken} />
        </TabPane>
        <TabPane tab="Licence" key="3">
          <Licence onLogOut={onLogOut} authToken={authToken} />
        </TabPane>
        <TabPane tab="Vehicle" key="4">
          <Vehicle onLogOut={onLogOut} authToken={authToken} />
        </TabPane>
      </Tabs>
    </Card>
  );
}

export default index;
