import React from "react";
import { Timeline } from "antd";
import Colors from "../../../constants/Colors";
function TodayScheduleTimeline({ schedules }) {
  return (
    <Timeline>
      {schedules.map((schedule) => {
        const [hr, min, amPm] = schedule;
        if (hr <= 3) {
          return (
            <Timeline.Item
              key={Math.random()}
              color={Colors.yellow}
            >{`${hr}:${min} ${amPm}`}</Timeline.Item>
          );
        } else if (hr <= 5) {
          return (
            <Timeline.Item
              key={Math.random()}
              color={Colors.blue3}
            >{`${hr}:${min} ${amPm}`}</Timeline.Item>
          );
        } else if (hr <= 9) {
          return (
            <Timeline.Item key={Math.random()} color={Colors.green3}>
              {`${hr}:${min} ${amPm}`}
            </Timeline.Item>
          );
        } else if (hr > 9 && hr < 12) {
          return (
            <Timeline.Item color={Colors.purple2} key={Math.random()}>
              {`${hr}:${min} ${amPm}`}
            </Timeline.Item>
          );
        }
      })}
    </Timeline>
  );
}

export default TodayScheduleTimeline;
