import React, { useState } from "react";
import { Card, Tabs, Steps, message, Spin } from "antd";
import FirstStepForm from "./FirstStepForm";
import NextForm from "./NextForm";
import PasswordChangeForm from "../../../component/PasswordChangeForm/PasswordChangeForm";
import Messages from "../../../others/Messages";
import lemmajAxios from "../../../others/apiConfig";
import { useHistory } from "react-router";
import Routes from "../../Routes";
import firebase from "../../../others/firebaseConfig";

const { TabPane } = Tabs;
const { Step } = Steps;

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function CreateInstructor({ authToken = null, onLogout = () => {} }) {
  const [submmiting, setSubmmittng] = useState(false);
  const [current, setCurrent] = useState(0);
  const [instrForm, setInstrForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dob: "",
    gender: "",
    country: "",
    Region: "",
    city: "",
  });
  const [vehicleForm, setVehicleForm] = useState({});
  const history = useHistory();

  const [formData, setFormData] = useState({});

  const parsePhoneNo = (phoneNumber) => {
    return `09${phoneNumber}`;
  };

  const naviagte = (to) => {
    if (current != to && to >= 0 && to <= 2) {
      setCurrent(to);
    }
  };
  const handleFirstFormSubmit = (values) => {
    const newInstrForm = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: parsePhoneNo(values.phoneNumber),
      dob: values.dob,
      gender: values.gender,
      country: values.country,
      Region: values.region,
      city: values.city,
    };
    setInstrForm(newInstrForm);
    naviagte(1);
  };
  const handlePasswordSubmit = (values) => {
    const newInstrForm = {
      ...instrForm,
      dob: instrForm.dob,
      password: values.newPassword,
    };
    setInstrForm(newInstrForm);
    naviagte(2);
  };

  const onDeleteImage = (image) => {
    let storage = firebase.storage();
    let storageRef = storage.ref();
    let deleteRef = storageRef.child("license/" + image);
    deleteRef
      .delete()
      .then(() => {})
      .catch((err) => {});
  };

  const handlefinalStepForm = (values) => {
    if (values.license_image) {
      const file = values.license_image.originFileObj;
      setSubmmittng(true);
      let storage = firebase.storage();
      let storageRef = storage.ref();
      const imageFileName =
        file.name + `${String(file.lastModified)}-${String(Math.random())}`;
      let uploadTask = storageRef.child("license/" + imageFileName).put(file);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (error) => {
          setSubmmittng(false);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            if (url) {
              const data = {
                ...instrForm,
                licenseType: values.licenseType,
                licenseNumber: values.regNum,
                licenseIssuedDate: values.issuedDate.toString(),
                licenseExpiryDate: values.expireDate.toString(),
                licenseImageUrl: url,
                vehicleUsed: "None",
                userType: "Instructor",
              };
              setSubmmittng(true);
              lemmajAxios
                .post("/LemmajUsers", data, {
                  params: {
                    access_token: authToken,
                  },
                  ...axiosOption,
                })
                .then((res) => {
                  setSubmmittng(false);
                  if (res.data.error) {
                    // delete pic
                    onDeleteImage(imageFileName);
                    message.error(res.data.error.message);
                    return;
                  }
                  message.success("Instructor account created", 6);
                  history.replace(Routes.INSTRUCTORS);
                  return;
                })
                .catch((err) => {
                  onDeleteImage(imageFileName);
                  setSubmmittng(false);
                });
            }
          });
        }
      );
    }
  };

  const steps = [
    {
      title: "Basic",
      content: (
        <FirstStepForm
          firstName={instrForm.firstName}
          lastName={instrForm.lastName}
          dob={instrForm.dob}
          phoneNumber={instrForm.phoneNumber}
          email={instrForm.email}
          gender={instrForm.gender}
          country={instrForm.country}
          city={instrForm.city}
          region={instrForm.Region}
          onSubmit={handleFirstFormSubmit}
        />
      ),
    },
    {
      title: "Password",
      content: (
        <PasswordChangeForm
          submitBtnText={"Next"}
          onCancel={() => naviagte(0)}
          isNewPassword={true}
          cancelText="Back"
          onSubmit={handlePasswordSubmit}
        />
      ),
    },
    {
      title: "Licence",
      content: (
        <NextForm cancelText="Back" onSubmit={handlefinalStepForm} onCancel={() => naviagte(1)} />
      ),
    },
  ];

  return (
    <Spin spinning={submmiting} tip="Registering">
      <Card>
        <Steps
          style={{
            padding: 10,
            marginBottom: 20,
            marginTop: 10,
          }}
          responsive
          current={current}
          direction="horizontal"
          size="default"
        >
          {steps.map((item) => (
            <Step title={item.title} />
          ))}
        </Steps>
        {/* {steps[2].content} */}
        {steps[current].content}
        {/* <Tabs>
          <TabPane tab="First Step" key="1">
            <CreateInstructor />
          </TabPane>
          <TabPane tab="Second Step" key="2">
            <NextForm />
          </TabPane>
        </Tabs> */}
      </Card>
    </Spin>
  );
}

export default CreateInstructor;
