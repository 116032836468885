import React from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import "./Trigger.css";

function Trigger({ toggle, siderCollapsed }) {
  return (
    <>
      {React.createElement(
        siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "trigger",
          onClick: toggle,
          // onClick: this.toggle,
        }
      )}
    </>
  );
}

export default Trigger;
