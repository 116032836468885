import { Card } from 'antd';
import React from 'react';
import PasswordChangeForm from '../../../component/PasswordChangeForm/PasswordChangeForm';

const RestorePassword = ({onSubmit}) => {
    return (
        <Card>
            <PasswordChangeForm isNewPassword onSubmit={onSubmit} />
      </Card>
    );
}

export default RestorePassword;
