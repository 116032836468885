import React, { useState } from "react";
import { Typography, Card, Menu, Spin } from "antd";
import Colors from "../../../constants/Colors";
import BasciForm from "./BasciForm";
import AddressForm from "./AddressForm";
import ProfilePicForm from "./ProfilePicForm";
function AccountForm({
  basic,
  onBasicSubmit,
  basicFormSubmitting,
  address,
  onAddressSubmit,
  addressFormSubmitting,
}) {
  const [selectedNavKey, setSelectedNavKey] = useState("1");
  const navs = (
    <Menu mode="horizontal" defaultSelectedKeys={"1"}>
      <Menu.Item
        key={"1"}
        onClick={(selected) => {
          setSelectedNavKey(selected.key);
        }}
      >
        Basic
      </Menu.Item>
      <Menu.Item
        key={"2"}
        onClick={(selected) => {
          setSelectedNavKey(selected.key);
        }}
      >
        Address
      </Menu.Item>
      {/* <Menu.Item
        key={"3"}
        onClick={(selected) => {
          setSelectedNavKey(selected.key);
        }}
      >
        Profile Picture
      </Menu.Item> */}
    </Menu>
  );
  return (
    <>
      {navs}
      <Card>
        <Spin
          spinning={
            (selectedNavKey === "1" && basicFormSubmitting) ||
            (selectedNavKey === "2" && addressFormSubmitting)
          }
        >
          <Typography.Title style={{ color: Colors.darkGrey }} level={4}>
            {selectedNavKey === "1"
              ? "Basic"
              : selectedNavKey === "2"
              ? "Address"
              : "Profile Picture"}
          </Typography.Title>
          {selectedNavKey === "1" ? (
            <BasciForm
              disabled={true}
              submitting={basicFormSubmitting}
              firstName={basic.firstName}
              lastName={basic.lastName}
              email={basic.email}
              dob={basic.dob}
              gender={basic.gender}
              phone={basic.phone.slice(2)}
              onSubmit={onBasicSubmit}
            />
          ) : selectedNavKey === "2" ? (
            <AddressForm
              disabled={true}
              city={address.city}
              region={address.region}
              country={address.country}
              onSubmit={onAddressSubmit}
              submitting={addressFormSubmitting}
            />
          ) : // <ProfilePicForm />
          null}
        </Spin>
      </Card>
    </>
  );
}

export default AccountForm;
