export default {
  BASE: "/instructor",
  STUDENT: "/instructor/student",
  STUDENTS: "/instructor/students",
  ACCOUNT: "/instructor/account",
  SCHEDULE: "/instructor/schedule",
  DUTY_DAYS: "/instructor/duty_days",
  VECHILE: "/instructor/vechile",
  // VECHILES: "/instructor/vechiles",
  PERFORMANCE: "/instructor/performance",
  TODAY_SCHEDULE: "/instructor/today_schedule",
  CHANGE_PASSWORD: "/instructor/password",
};
