import React, { useState } from "react";
import { Card, message, Spin } from "antd";
import PasswordChangeForm from "../../../component/PasswordChangeForm/PasswordChangeForm";
import Messages from "../../../others/Messages";
import lemmajAxios from "../../../others/apiConfig";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function InstructorPassword({ accessToken, onLogout }) {
  const [submitting, isSubmitting] = useState(false);
  const onChangePassword = (values) => {
    const data = {
      oldPassword: values.oldPasssword,
      newPassword: values.newPassword,
    };
    if (!accessToken) {
      onLogout();
    }
    isSubmitting(true);
    lemmajAxios
      .post("/LemmajUsers/change-password", data, {
        params: {
          access_token: accessToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 400) {
          message.error(Messages.INVALID_PASSWORD);
          isSubmitting(false);
          return;
        }
        message.success(Messages.PASSWORD_CHANGE_SUCCESS);
        isSubmitting(false);
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        isSubmitting(false);
      });
  };
  return (
    <Spin spinning={submitting}>
      <Card>
        <PasswordChangeForm onSubmit={onChangePassword} />
      </Card>
    </Spin>
  );
}

export default InstructorPassword;
