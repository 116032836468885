import React from "react";
import { Layout, Divider, Affix, Card } from "antd";
import Colors from "../../constants/Colors";

function AppSider({
  onBreakpointChanged,
  isMobileView,
  siderCollapsed,
  siderMenu,
  siderHeader,
  show = true,
}) {
  const siderPos = isMobileView ? "fixed" : null;

  return show ? (
    <Affix offsetTop={0} style={{ zIndex: 1000 }}>
      <Layout.Sider
        onBreakpoint={(broken) => {
          onBreakpointChanged(broken);
        }}
        breakpoint="lg"
        collapsible
        collapsed={siderCollapsed}
        collapsedWidth="0"
        width="250px"
        theme="dark"
        trigger={null}
        style={{
          boxShadow: "0px 0px 20px #eee",
          position: siderPos,
          zIndex: 100,
          minHeight: "100vh",
          background: Colors.blue2,
        }}
      >
        {siderHeader && (
          <Card
            hoverable={true}
            bordered={false}
            className="logo"
            style={{
              minHeight: "20vh",
              maxHeight: "20vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: Colors.blue2,
              boxShadow: "10px 0px 10px #eee",
            }}
          >
            {siderHeader}
          </Card>
        )}
        {/* <Divider dashed /> */}
        <div style={{ marginTop: 20 }}>{siderMenu ? siderMenu : null}</div>
      </Layout.Sider>
    </Affix>
  ) : null;
}

export default AppSider;
