import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import {
  Card,
  Typography,
  Divider,
  Select,
  Row,
  Col,
  Form,
  Checkbox,
  Alert,
  message,
  Spin,
} from "antd";
import Colors from "../../../constants/Colors";
import Instructors from "../Instructors/Instructors";
import { GiftFilled } from "@ant-design/icons";
import Routes from "../../Routes";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function InstructorSearch({ userData, userType, onLogout }) {
  const history = useHistory();
  const [instructors, setInstructorsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fetching, setFetching] = useState(true);

  const getInstructors = () => {
    lemmajAxios
      .get(`/LemmajUsers`, {
        params: {
          access_token: userData.id,
          "filter[where][userType]": "Instructor",
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          onLogout();
          return;
        }
        const result = res.data;
        const instructors = result.map((i) => {
          return {
            firstName: i.firstName,
            lastName: i.lastName,
            phone: i.phoneNumber,
            comments: [],
            imgSrc: null,
            star: 0,
            car: i.vehicleUsed,
            commentShow: false,
            id: i.id,
          };
        });
        setInstructorsData(instructors);
        setFetching(false);
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setFetching(false);
      });
  };

  useEffect(() => {
    if (userType !== "Driver") {
      onLogout();
      return;
    }
    getInstructors();
  }, []);

  const handleCommentVisibleToggle = (i) => {
    const newInstructors = instructors.slice();
    const instructor = { ...newInstructors[i] };

    instructor.commentShow = !instructor.commentShow;

    newInstructors[i] = instructor;

    setInstructorsData(newInstructors);
  };

  const handleRateIncrease = (i) => {
    const newInstructors = instructors.slice();
    const instructor = { ...newInstructors[i] };

    instructor.star = instructor.star + 1;

    newInstructors[i] = instructor;
    setInstructorsData(newInstructors);
  };

  const handleSearchTerm = (term) => {
    setSearchTerm(term);
  };

  let filteredInstructors =
    !searchTerm || searchTerm === ""
      ? instructors
      : instructors.filter((i) => {
          return `${i.firstName.toLowerCase()} ${i.lastName.toLowerCase()}`
            .trim()
            .includes(searchTerm.toLowerCase().trim());
        });
  return (
    <Spin spinning={fetching}>
      <Card
        hoverable
        style={{
          borderTopWidth: 10,
          borderTopColor: Colors.blue2,
          borderRadius: 8,
          marginBottom: 10,
        }}
      >
        <Typography.Title level={4} style={{ color: Colors.orange }}>
          <GiftFilled style={{ color: Colors.orange, marginRight: 10 }} />
          Choose Package Instructor
        </Typography.Title>
        <AppInputSearch
          onSearch={handleSearchTerm}
          placeholder="Instructor Name"
        />{" "}
        <br />
        <Divider />
        {/* <Form.Item
          label={
            <span style={{ color: Colors.darkGrey, marginRight: 3 }}>Day</span>
          }
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Select day"
          >
            <Select.Option key={0}>Sunday</Select.Option>
            <Select.Option key={1}>Monday</Select.Option>
            <Select.Option key={2}>Tuesday</Select.Option>
            <Select.Option key={3}>Wednesday</Select.Option>
            <Select.Option key={4}>Thursday</Select.Option>
            <Select.Option key={5}>Friday</Select.Option>
            <Select.Option key={6}>Saturday</Select.Option>
          </Select>
        </Form.Item> */}
      </Card>

      <Card hoverable style={{ borderRadius: 8 }}>
        {/* <Alert
          banner
          description="You can not change an instructor once selected for this period"
        /> */}
        <Divider style={{ marginBottom: 20, marginTop: 20 }} />
        <Instructors
          withStars={false}
          withComments={false}
          onClick={(id) => {
            history.push({
              pathname: Routes.SET_INSTRUCTOR,
              search: `id=${id}`,
              state: { ...history.location.state },
            });
          }}
          onIncreaseRate={handleRateIncrease}
          onCommentIconToggle={handleCommentVisibleToggle}
          instructors={filteredInstructors}
        />
      </Card>
    </Spin>
  );
}

export default InstructorSearch;
