import React from "react";
import { Form, Input } from "antd";
import { PhoneFilled } from "@ant-design/icons";
function PhoneNumberFormItem({
  name = "phoneNumber",
  label = "Phone",
  placeholder = "Phone",
}) {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        { required: true, message: "Phone is required" },
        () => ({
          validator(_, value) {
            const digit = Number(value);
            if (
              Number.isNaN(digit) ||
              value.includes("+") ||
              value.includes(".") ||
              !Number.isInteger(digit) ||
              value < 0 ||
              value.length < 8
            ) {
              return Promise.reject("Phone must be valid");
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Input
        type="tel"
        maxLength={8}
        prefix={"09"}
        placeholder={placeholder}
        addonBefore={<PhoneFilled />}
      />
    </Form.Item>
  );
}

export default PhoneNumberFormItem;
