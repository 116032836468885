import React from "react";
import { Row, Col, Avatar, Menu, Typography } from "antd";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";

function UserBarDropDown({
  name,
  imageSrc,
  username,
  onUserNameClicked,
  extraData,
}) {
  return (
    <>
      <Row style={{ padding: "10px" }} justify="center">
        <Col>
          <Menu>
            <Menu.Item active={false} onClick={onUserNameClicked}>
              <Row gutter={(12, 12)}>
                <Col>
                  <UserAvatar name={name} preview={false} imagSrc={imageSrc} />
                </Col>
                <Col>
                  <div>{name}</div>
                  <Typography.Text type="secondary">{username}</Typography.Text>
                </Col>
              </Row>
            </Menu.Item>
            {extraData.map((itemObj) => {
              return (
                <Menu.Item key={itemObj.title}>
                  <Row gutter={(16, 16)}>
                    <Col>
                      <itemObj.iconComponent style={{ fontSize: 20 }} />
                    </Col>
                    <Col onClick={itemObj.clickHandler}>{itemObj.title}</Col>
                  </Row>
                </Menu.Item>
              );
            })}
          </Menu>
        </Col>
      </Row>
    </>
  );
}

export default UserBarDropDown;
