import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Typography,
  Input,
  DatePicker,
  Row,
  Col,
  Button,
  Spin,
  message,
} from "antd";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import moment from "moment";
import Messages from "../../../others/Messages";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function License({ onLogout = () => null, userData = null }) {
  const [fetching, setFetching] = useState(true);
  const [updating, setUpdateing] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (
      (!userData.user ||
        !userData.id ||
        !userData.userId ||
        userData.user.userType !== "Driver",
      userData.userType !== "Driver")
    ) {
      onLogout();
      return;
    }
    lemmajAxios
      .get(`/LemmajUsers/${userData.userId}`, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        const user = res.data;
        form.setFieldsValue({
          licenseNumber: user.licenseNumber,
          licenseType: user.licenseType,
          licenseIssuedDate: moment(user.licenseIssuedDate),
          licenseExpiryDate: moment(user.licenseExpiryDate),
        });
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
      });
  }, []);
  const updateLicense = (values) => {
    const data = {
      licenseNumber: values.licenseNumber,
      licenseType: values.licenseType,
      licenseIssuedDate: values.licenseIssuedDate.toString(),
      licenseExpiryDate: values.licenseExpiryDate.toString(),
    };
    setUpdateing(true);
    lemmajAxios
      .patch(`/LemmajUsers/${userData.userId}`, data, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          message.error(res.data.error.message);
          onLogout();
          return;
        }
        message.success(Messages.INFO_UPDATED);
        setUpdateing(false);
      })
      .catch((err) => {
        message.success(Messages.OPREATION_FAILED);
        setUpdateing(false);
      });
  };
  return (
    <Spin spinning={updating}>
      <Card loading={fetching}>
        <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
          License Information
        </Typography.Title>
        <Form form={form} onFinish={updateLicense} layout="vertical">
          <Form.Item
            name="licenseType"
            label="License Type"
            rules={[{ required: true, message: "License type is required" }]}
          >
            <Input placeholder="License Type" />
          </Form.Item>
          <Form.Item
            name="licenseNumber"
            label="license Number"
            rules={[{ required: true, message: "license Number is required" }]}
          >
            <Input placeholder="license Number" />
          </Form.Item>
          <Form.Item
            label="Issued Date"
            name="licenseIssuedDate"
            rules={[{ required: true, message: "Issued Date is required" }]}
          >
            <DatePicker placeholder="Issued Date" />
          </Form.Item>
          <Form.Item
            label="Expire Date"
            name="licenseExpiryDate"
            rules={[{ required: true, message: "Expire Date is required" }]}
          >
            <DatePicker placeholder="Expire Date" />
          </Form.Item>

          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  style={{ background: Colors.blue2, border: Colors.blue2 }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default License;
