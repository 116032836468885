import React from "react";
import { Card, Collapse, Typography } from "antd";
import { GiftFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import EnrollmentContent from "./EnrollmentContent";
import moment from "moment";

function EnrollmentCollapse({
  _package,
  period,
  date,
  instructor,
  student,
  portal_view = 0,
}) {
  return (
    <Collapse accordion expandIconPosition="right">
      <Collapse.Panel
        header={
          <>
            <Typography.Text style={{ color: Colors.orange, marginRight: 8 }}>
              {<GiftFilled />}
            </Typography.Text>
            <Typography.Text style={{ marginRight: 8, fontWeight: "bold" }}>
              {_package.name && _package.name}
            </Typography.Text>
            <Typography.Text style={{ marginRight: 8 }}>|</Typography.Text>
            <Typography.Text style={{ marginRight: 8 }}>
              {date && moment(date).format("DD-MMM-YYYY")}
            </Typography.Text>
            <Typography.Text style={{ marginRight: 8 }}>|</Typography.Text>
            <Typography.Text style={{ marginRight: 8 }}>
              <b>{period && `period ${period.sequence + 1}`}</b>
            </Typography.Text>
            <Typography.Text style={{ marginRight: 8 }}>|</Typography.Text>
            <Typography.Text
              style={{ fontWeight: "bold", color: Colors.darkGrey }}
            >
              <a>{student && `${student.firstName} ${student.lastName}`} </a>
            </Typography.Text>
          </>
        }
      >
        <EnrollmentContent
          portal_view={portal_view}
          _package={_package}
          student={student}
          period={period}
          startDate={date}
        />
      </Collapse.Panel>
    </Collapse>
  );
}

export default EnrollmentCollapse;
