import React, { useEffect, useState } from "react";
import EnrollmentCollapse from "./EnrollmentCollapse";
import { DatePicker, Card, Typography, Divider, Spin } from "antd";
import moment from "moment";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import { useHistory } from "react-router";
const monthFormat = "YYYY / MM";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function Enrollment({
  onLogOut = () => null,
  authToken = "",
  filterStatus = "",
  inst_id = null,
  portal_view = 0,
}) {
  const history = useHistory();
  const instructor_id = inst_id
    ? inst_id
    : history.location.search.slice("id?=".length);
  const [periods, setPeriods] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDateSelect = (val) => {
    const formattedDate = moment(val).format("YYYY-MM") + "-01";
    getPeriods(formattedDate);
  };
  const getPeriods = (dateString) => {
    const data = {
      dateFilter: dateString,
      instructorId: instructor_id,
    };
    setLoading(true);
    lemmajAxios
      .post("Periods/getPeriodsOfInstructorByDate", data, axiosOption)
      .then((res) => {
        setLoading(false);

        if (res.status === 200) {
          setPeriods(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const val = moment(Date.now()).format("YYYY-MM") + "-01";
    getPeriods(val);
  }, []);
  const filteredPeriods = periods.filter(
    (p) => p.period.periodStatus === filterStatus
  );
  const sortedPeriods = filteredPeriods.sort((p1, p2) => {
    if (moment(p1.startDate).isAfter(moment(p2.startDate))) {
      return 1;
    } else {
      return -1;
    }
  });
  return (
    <Spin spinning={loading} tip={"fetching"}>
      <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
        {portal_view === 0 ? "Instructor Enrollment" : "Performance"}
        <Divider />
      </Typography.Title>
      <Card bordered={false} style={{ marginBottom: 8 }}>
        <DatePicker
          onSelect={onDateSelect}
          allowClear={false}
          size="large"
          style={{ width: "100%" }}
          defaultValue={moment(new Date())}
          format={monthFormat}
          picker="month"
        />
      </Card>
      <b>Total Periods: {filteredPeriods.length}</b>
      <br />
      <br />
      {sortedPeriods.map((p) => {
        return (
          <EnrollmentCollapse
            _package={p.package}
            date={p.startDate}
            period={p.period}
            student={p.student}
            portal_view={portal_view}
          />
        );
      })}
    </Spin>
  );
}

export default Enrollment;
