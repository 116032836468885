import React, { useState } from "react";
import {
  Card,
  Button,
  Input,
  Form,
  Row,
  Col,
  Checkbox,
  Alert,
  Spin,
} from "antd";
import { UserOutlined, LockFilled } from "@ant-design/icons";
import Colors from "../../constants/Colors";
import { useHistory } from "react-router-dom";
import axios from "../../others/apiConfig";
import "./Login.css";
import Routes from "../../Routes";
import Messages from "../../others/Messages";
import Logo from "../Logo/Logo";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function Login(props) {
  const history = useHistory();
  const [errorMessage, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    setLoading(true);
    axios
      .post("/LemmajUsers/login", data, {
        params: {
          include: "User",
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          setError(res.data.error.message);
          setLoading(false);
          return;
        }
        //user
        const user = res.data.user;
        //userID
        const userId = res.data.userId;
        //apikey
        const id = res.data.id;
        //time to expire
        const ttl = res.data.ttl;
        if (user && user.userType === "Instructor") {
          props.onLogin(user, user.userType, userId, id, ttl);
          history.replace(Routes.INSTRUCTOR);
          // window.location.reload();
        } else if (user && user.userType === "student") {
          if (!user.isApproved) {
            setError("Your account is not approved yet");
          } else if (user.isLocked) {
            setError(
              "Your account has been locked, please contact Lemmaj technical team"
            );
          } else {
            props.onLogin(user, "Driver", userId, id, ttl);
            history.replace(Routes.DRIVER);
          }
          // window.location.reload();
        } else if (user && user.userType === "admin") {
          props.onLogin(user, "Admin", userId, id, ttl);
          history.replace(Routes.ADMIN);
          // window.location.reload();
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(Messages.CONNECTION_ERR);
      });
  };
  return (
    <div style={{ minHeight: "100vh", background: Colors.veryLightGrey }}>
      <Row justify="center" style={{ paddingTop: 80 }}>
        <Logo />
      </Row>
      <Row style={{ width: "100%" }}>
        {/* <Col></Col> */}
        <Col
          xl={{ offset: 7, span: 10 }}
          lg={{ offset: 7, span: 10 }}
          md={{ offset: 7, span: 13 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <div style={{ marginTop: 80, marginRight: 10, marginLeft: 10 }}>
            {errorMessage && (
              <Alert
                closable
                onClose={() => {
                  setError(null);
                }}
                style={{ marginBottom: 5, borderRadius: 16 }}
                banner
                description={errorMessage}
              />
            )}

            <Card
              hoverable
              style={{
                borderRadius: 16,
              }}
            >
              <Spin spinning={loading}>
                <Form
                  initialValues={{ remember: true }}
                  layout="vertical"
                  style={{ width: "100%" }}
                  onFinish={handleLogin}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "User email is required" },
                      { type: "email", message: "Please enter a valid email" },
                    ]}
                  >
                    <Input
                      addonBefore={
                        <UserOutlined className="site-form-item-icon" />
                      }
                      placeholder="email"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Password is required" },
                    ]}
                  >
                    <Input.Password
                      visibilityToggle={true}
                      addonBefore={
                        <LockFilled className="site-form-item-icon" />
                      }
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    {/* <a href="/" className="login-form-forgot">
                  Forgot password
                </a> */}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={loading}
                      style={{
                        width: "100%",
                        borderRadius: 24,
                        borderColor: Colors.blue2,
                        background: Colors.blue2,
                        marginBottom: 10,
                      }}
                      block
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      <b>LOGIN</b>
                    </Button>
                    <Button
                      onClick={() => {
                        history.push(Routes.REGISTER);
                      }}
                      style={{
                        width: "100%",
                        borderRadius: 24,
                        borderColor: Colors.orange,
                        background: Colors.orange,
                      }}
                      block
                      type="primary"
                      className="login-form-button"
                    >
                      <b>REGISTER</b>
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
