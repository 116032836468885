import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Divider,
  Typography,
  Card,
  message,
  Spin,
} from "antd";
import { UserOutlined, MailFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import { useHistory } from "react-router";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import Routes from "../../Routes";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function Location({ authToken = null, onLogout = () => {} }) {
  const [submmiting, setSubmmittng] = useState(false);
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const fillForm = (country, Region, city) => {
    form.setFieldsValue({
      country: country,
      region: Region,
      city: city,
    });
  };

  const fetchDataAndFillData = () => {
    const instructor_id = history.location.search.slice("id?=".length);
    setFetching(true);
    lemmajAxios
      .get(`/LemmajUsers/${instructor_id}`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const instructor = res.data;
        fillForm(instructor.country, instructor.Region, instructor.city);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchDataAndFillData();
  }, []);

  const onUpdateBasicInstructor = (values) => {
    const instructor_id = history.location.search.slice("id?=".length);
    const data = {
      country: values.country,
      Region: values.region,
      city: values.city,
    };
    setSubmmittng(true);
    lemmajAxios
      .patch(`/LemmajUsers/${instructor_id}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        message.success(Messages.INFO_UPDATED);
        history.replace(`${Routes.INSTRUCTOR_DETAIL}?id=${instructor_id}`);
        setSubmmittng(false);
      })
      .catch((err) => {
        setSubmmittng(false);
        message.error(Messages.OPREATION_FAILED);
      });
    return;
  };

  return (
    <Spin spinning={submmiting}>
      <Card loading={fetching}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            onUpdateBasicInstructor(values);
          }}
        >
          <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
            Location Information
          </Typography.Title>
          <Divider />
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: "Country is required" }]}
          >
            <Input placeholder="Country" />
          </Form.Item>
          <Form.Item
            label="Region"
            name="region"
            rules={[{ required: true, message: "Select a region" }]}
          >
            <Select placeholder="Region">
              <Select.Option key="Addis Ababa City State">
                Addis Ababa City State
              </Select.Option>
              <Select.Option key="Afar">Afar</Select.Option>
              <Select.Option key="Amhara">Amhara</Select.Option>
              <Select.Option key="Benishangul Gumuz">
                Benishangul Gumuz
              </Select.Option>
              <Select.Option key=" Dire Dawa City State">
                Dire Dawa City State
              </Select.Option>
              <Select.Option key="Gambela">Gambela</Select.Option>
              <Select.Option key="Harari">Harari</Select.Option>
              <Select.Option key="Oromia">Oromia</Select.Option>
              <Select.Option key="SNNP">SNNP</Select.Option>
              <Select.Option key="Sidama">Sidama</Select.Option>
              <Select.Option key="Tigray">Tigray</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "City is required" }]}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  style={{
                    background: Colors.blue2,
                    borderRadius: Colors.blue2,
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default Location;
