import { Button, Col, message, Row, Select, Space, Spin } from "antd";
import Form from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

const FeedbackForm = ({ period, pastFeedback }) => {
  const [feedback, setFeedback] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const onSubmitFeedBack = () => {
    if (feedback != "") {
      setSubmitting(true);
      let data = {
        feedback: feedback,
      };
      lemmajAxios
        .patch(`/Periods/${period.id}`, data, {
          params: {
            access_token: JSON.parse(localStorage.getItem("userAuth")).id,
          },
          ...axiosOption,
        })
        .then((res) => {
          onPushNotifiaction();
        })
        .catch((err) => {
          message.error(Messages.OPREATION_FAILED);
          setSubmitting(false);
        });
    }
  };

  const onPushNotifiaction = () => {
    const data = {
      message: `Your student ${
        JSON.parse(localStorage.getItem("userAuth")).user.firstName
      } ${
        JSON.parse(localStorage.getItem("userAuth")).user.lastName
      } is ${feedback} with period ${period.sequence + 1}`,
      instructorId: period.instructorId,
    };

    setSubmitting(true);
    lemmajAxios
      .post("/Notifications", data, {
        params: {
          access_token: JSON.parse(localStorage.getItem("userAuth")).id,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          // onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 400) {
          message.error(Messages.INVALID_PASSWORD);
          setSubmitting(false);
          return;
        }
        message.success(Messages.FEEDBACK_SUBMITTED);
        setSubmitting(false);
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setSubmitting(false);
      });
  };

  const setFeedbackValue = (value) => {
    setFeedback(value);
  };

  useEffect(() => {
  }, []);

  return (
    <Spin spinning={submitting}>
      <Space>
        <Row gutter={[16, 16]}>
          <Col>
            <Select
              onChange={setFeedbackValue}
              defaultValue={pastFeedback ? pastFeedback : "Select Feedback"}
            >
              <Select.Option value="Very Dissatisfied">
                Very Dissatsfied
              </Select.Option>
              <Select.Option value="dissatisfied">Dissatisfied</Select.Option>
              <Select.Option value="satisfied">satisfied</Select.Option>
              <Select.Option value="Very Satisfied">
                Very Satisfied
              </Select.Option>
            </Select>
          </Col>

          <Col>
            <Button
              onClick={onSubmitFeedBack}
              style={{
                color: Colors.white,
                background: Colors.blue2,
                borderColor: Colors.blue2,
              }}
            >
              <b>Submit</b>
            </Button>
          </Col>

          <Col>Completed on Jan-12-2012</Col>
        </Row>
      </Space>
    </Spin>
  );
};

export default FeedbackForm;
