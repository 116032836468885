import React, { useState, useEffect } from "react";
import AppLayout from "../../../component/AppLayout/AppLayout";
import SiderMenu from "../SiderMenu/SiderMenu";
import UserNotificationBar from "../UserNotificationBar/UserNotificationBar";
import Students from "../Students/Students";
import StudentStatus from "../StudentStatus/StudentStatus";
import VechileProfile from "../Vechile/VechileProfile";
import PerformanceList from "../Peformance/PerformanceList.js";
import AccountForm from "../AccountForm/AccountForm";
import { Switch, Route, useHistory } from "react-router-dom";
import Routes from "../../Routes";
import Schedule from "../Schedule/Schedule";
import UserProfileSiderHeader from "../../../component/UserPorfilePic/UserProfilePic";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import TodayScheduledStudent from "../TodayScheduledStudent/TodayScheduledStudent";
import InstructorPassword from "../InstructorPassword/InstructorPassword";
import getInstructorNotification from "../InstructorNotification/InstructorQueries/GetInstructorNotification";
import lemmajAxios from "../../../others/apiConfig";
import moment from "moment";
import { message, Spin } from "antd";
import Messages from "../../../others/Messages";
// import Vehicles from "../Vechile/Vehicles";
import DutyDays from "../DutyDays/DutyDays";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function LemajInstructorApp(props) {
  const [user, setUser] = useState({
    name: "",
    username: "",
    phone: "",
    imageSrc: "",
  });

  const [loading, setLoading] = useState(true);
  const [basicFormSubmitting, setBasicFormSubmitting] = useState(false);
  const [dutyDays, setDutyDays] = useState([]);

  const [addressFormSubmitting, setAddressFormSubmitting] = useState(false);
  const [countNotification, setCountNotification] = useState(0);
  const [instructorNotification, setInstructorNotification] = useState([]);

  const fetchData = () => {
    const userData = props.userAuthData;
    lemmajAxios
      .get(`/LemmajUsers/${userData.userId}`, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          props.onLogout();
          return;
        }
        setUser({
          name: res.data.firstName + " " + res.data.lastName,
          username: res.data.email,
          phone: res.data.phoneNumber,
          carModel: res.data.vehicleUsed,
          drivenYear: res.data.yearDriven,
          gearType: "hybrid",
          modelYear: moment(Date.now),
          imageSrc: null,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          dob: moment(res.data.dob),
          gender: res.data.gender.toLowerCase() === "male" ? "male" : "female",
          email: res.data.email,
          city: res.data.city,
          country: res.data.country,
          region: res.data.Region,
        });
      })
      .catch((err) => {});
  };

  const getDutyDays = (id, authToken) => {
    setLoading(true);
    lemmajAxios
      .get("/Schedules/getSchedulesForInstructor/", {
        params: {
          access_token: authToken,
          instructorId: id,
        },
        ...axiosOption,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          props.onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }
        setDutyDays(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const userData = props.userAuthData;
    if (
      (!userData.user ||
        !userData.id ||
        !userData.userId ||
        userData.user.userType !== "Instructor",
      userData.userType !== "Instructor")
    ) {
      props.onLogout();
      return;
    }
    setLoading(true);
    fetchData();
    getInstructorNotification(setCountNotification, setInstructorNotification);
    getDutyDays(userData.userId, userData.id);
    setLoading(false);
  }, []);

  const getUpdatedDutyDays = () => {
    const userData = props.userAuthData;

    getDutyDays(userData.userId, userData.id);
  };

  const updateUserAddress = (values) => {
    const data = {
      city: values.city,
      Region: values.region,
      country: values.country,
    };
    const userData = props.userAuthData;
    setAddressFormSubmitting(true);
    lemmajAxios
      .patch(`/LemmajUsers/${userData.userId}`, data, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          props.onLogout();
          setAddressFormSubmitting(false);
          return;
        }
        fetchData();
        setAddressFormSubmitting(false);
        message.success(Messages.INFO_UPDATED);
      })
      .catch((err) => {
        setAddressFormSubmitting(false);
        message.success(Messages.OPREATION_FAILED);
      });
  };
  const updateUserBasic = (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      phoneNumber: values.phoneNumber,
      dob: values.dob,
    };
    const userData = props.userAuthData;
    setBasicFormSubmitting(true);
    lemmajAxios
      .patch(`/LemmajUsers/${userData.userId}`, data, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          props.onLogout();
          setBasicFormSubmitting(false);
          return;
        }
        fetchData();
        setBasicFormSubmitting(false);
        message.success(Messages.INFO_UPDATED);
      })
      .catch((err) => {
        setBasicFormSubmitting(false);
        message.success(Messages.OPREATION_FAILED);
      });
  };

  const history = useHistory();
  const siderMenu = <SiderMenu onLogout={props.onLogout} />;
  const userProfileAvatar = (
    <UserAvatar imagSrc={user.imageSrc} name={user.name} size={65} />
  );
  const headerNavItems = (
    <UserNotificationBar
      onScheduleClicked={() => {
        history.push(Routes.SCHEDULE);
      }}
      onPasswrodClicked={() => {
        history.push(Routes.CHANGE_PASSWORD);
      }}
      onLogout={props.onLogout}
      notificatinCount={countNotification}
      setCountNotification={setCountNotification}
      name={user.name}
      username={user.username}
      imageSrc={user.imageSrc}
      userId={props.userAuthData.userId}
      accessToken={props.userAuthData.id}
      getInstructorNotification={instructorNotification}
      onUpdate={() =>
        getInstructorNotification(
          setCountNotification,
          setInstructorNotification
        )
      }
    />
  );

  const getStudentsForInstructor = () => {
    if (dutyDays && dutyDays.length > 0) {
      const scheduled = dutyDays.filter(
        (d) =>
          d.student &&
          d.schedule.scheduleStatus === "scheduled" &&
          d.schedule.isApproved &&
          moment(d.schedule.endDate).isAfter(moment(Date.now()))
      );

      const studentsSchedule = scheduled.map((d) => d.student);
      let res = [];
      studentsSchedule.forEach((s) => {
        let temp = res.findIndex((i) => i.id === s.id);
        if (temp <= -1) {
          res.push(s);
        }
      });

      return res;
    }

    return [];
  };

  const getTodaySchedules = () => {
    if (dutyDays && dutyDays.length > 0) {
      const todayD = Number(moment(Date.now()).format("DD"));
      const todayM = Number(moment(Date.now()).format("MM"));
      const todayY = Number(moment(Date.now()).format("YYYY"));

      const scheduled = dutyDays.filter((d) => {
        const day = Number(moment(d.schedule.startDate).format("DD"));
        const month = Number(moment(d.schedule.startDate).format("MM"));
        const year = Number(moment(d.schedule.startDate).format("YYYY"));

        return (
          d.schedule.scheduleStatus === "scheduled" &&
          d.schedule.isApproved &&
          day === todayD &&
          month === todayM &&
          year === todayY
        );
      });
      return scheduled;
    }

    return [];
  };
  const content = (
    <Switch>
      <Route
        path={Routes.STUDENTS}
        render={(props) => <Students students={getStudentsForInstructor()} />}
      />
      <Route
        path={Routes.ACCOUNT}
        render={(props) => (
          <AccountForm
            basicFormSubmitting={basicFormSubmitting}
            onBasicSubmit={updateUserBasic}
            basic={{
              firstName: user.firstName,
              lastName: user.lastName,
              phone: user.phone,
              email: user.email,
              gender: user.gender,
              dob: user.dob,
            }}
            address={{
              city: user.city,
              region: user.region,
              country: user.country,
            }}
            onAddressSubmit={updateUserAddress}
            addressFormSubmitting={addressFormSubmitting}
            onProfilePicChange
          />
        )}
      />
      <Route
        path={Routes.SCHEDULE}
        render={() => <Schedule dutyDays={dutyDays} />}
      />
      <Route
        path={Routes.DUTY_DAYS}
        render={() => (
          <DutyDays
            onSaveDutyDate={getUpdatedDutyDays}
            dutyDays={dutyDays}
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />
      <Route
        path={Routes.VECHILE}
        render={() => (
          <VechileProfile
            user={props.userAuthData.user}
            authToken={props.userAuthData.id}
            onLogout={props.onLogout}
            gearType={user.gearType}
            modelYear={moment(Date.now())}
            drivenYear={user.drivenYear}
            carModel={user.carModel}
          />
        )}
      />
      {/* <Route
        path={Routes.VECHILES}
        render={() => (
          <Vehicles
            user={props.userAuthData.user}
            authToken={props.userAuthData.id}
            onLogout={props.onLogout}
          />
        )}
      /> */}
      <Route
        path={Routes.PERFORMANCE}
        render={() => (
          <PerformanceList
            user={props.userAuthData.user}
            authToken={props.userAuthData.id}
            onLogout={props.onLogout}
          />
        )}
      />
      <Route
        path={Routes.STUDENT}
        render={() => (
          <StudentStatus
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />
      <Route
        path={Routes.CHANGE_PASSWORD}
        render={() => (
          <InstructorPassword
            userId={props.userAuthData.userId}
            accessToken={props.userAuthData.id}
            onLogout={props.onLogout}
          />
        )}
      />
      <Route
        path={Routes.TODAY_SCHEDULE}
        render={(props) => (
          <TodayScheduledStudent students={getTodaySchedules()} />
        )}
      />

      <Route
        path={Routes.BASE}
        render={() => <TodayScheduledStudent students={getTodaySchedules()} />}
      />
    </Switch>
  );
  return (
    <Spin spinning={loading} tip="loading">
      <AppLayout
        title={"Welcome " + user.name}
        userProfileSiderHeader={
          <UserProfileSiderHeader
            user={user}
            userProfileAvatar={userProfileAvatar}
          />
        }
        siderMenu={siderMenu}
        headerNavItems={headerNavItems}
        content={content}
      />
    </Spin>
  );
}

export default LemajInstructorApp;
