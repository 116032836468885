import React, { useEffect, useState } from "react";
import { Layout, Typography, Row, Col, Button } from "antd";
import Logo from "../../../component/Logo/Logo";
import icon from "../../../Assets/certificate_template.jpg";
import logo from "../../../Assets/logo.png";
import signature from "../../../Assets/Signature_lemmaj_owner.png";
import getCurrentDate from "./../../../component/GetCurrentDate/getCurrentDate";
// import signature from "./SignatureImg";
import moment from "moment";
import SignatureImage from "./SignatureImg";
import lemmajAxios from "../../../others/apiConfig";
import { useHistory } from "react-router";
import Colors from "../../../constants/Colors";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DownloadOutlined } from "@ant-design/icons";
function CourseCompletionCertificate({ onLogout }) {
  const authData = JSON.parse(localStorage.getItem("userAuth"));
  const { Header, Content, Footer } = Layout;
  const { Title, Text } = Typography;
  var currentDate = new Date();
  const [firstname1, setFirstname1] = useState("");
  const [lastname1, setLastname1] = useState("");
  const [packageName, setPackageName] = useState("");
  const [certificateCode, setCertificateCode] = useState(null);
  const [certifiedDate, setCertifiedDate] = useState(getCurrentDate());
  const [isCertified, setIsCertified] = useState(false);

  const getCertifiedUser = () => {
    lemmajAxios
      .get(
        "/CertifiedUsers/getCertifiedUserByStudentId?studentId=" +
          authData.userId,
        {
          params: {
            access_token: authData.userId,
          },
        }
      )
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          return;
        }
        if (res.data.length == 0) {
          onLogout();
        } else {
          setFirstname1(res.data[0].lemmajUser.firstName);
          setLastname1(res.data[0].lemmajUser.lastName);
          setCertificateCode(res.data[0].certificate.certificateCode);
          setPackageName(res.data[0].package.name);
          setCertifiedDate(
            moment(res.data[0].certificate.certfiedDate).format("MMM DD, YYYY")
          );
          setIsCertified(true);
        }

        // to do here
      })
      .catch((err) => {});
  };

  const downloadCertificate = () => {
    // console.log("downloadeddd")
    // // return;
    // window.html2canvas = html2canvas

    // let doc = new jsPDF("p", "pt", "a3");
    // doc.html(document.querySelector("#main"), {
    //   callback: function (pdf) {
    //     console.log("start downloading", pdf);
    //     pdf.save("certeficate.pdf");
    //   },
    // });
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.addImage(icon, "JPG", 0, 0, 300, 210, undefined, "FAST");
    doc.addImage(logo, "PNG", 130, 30, 40, 10);
    doc.setFont("ConsolasHex", "bold");
    doc.setFontSize(17);
    doc.text(100, 50, "Safety and Driving Skills | lemmaj.com");
    doc.text(120, 57, "Addis Ababa, Ethiopia");
    doc.setFontSize(30);
    doc.text(70, 80, "CERTIFICATE OF COMPLETION");
    doc.setFontSize(15);
    doc.text(110, 88, "This certificate is presented to");
    doc.setFontSize(20);
    doc.text(128, 98, `${firstname1} ${lastname1}`);
    doc.setFontSize(15);
    doc.text(
      95,
      120,
      `This is to certify that ${firstname1} ${lastname1} has completed`
    );
    doc.text(95, 128, `${packageName} Package with good attendance on`);
    doc.text(128, 138, `${certifiedDate}`);
    doc.text(115, 157, "Mr. Mihretu Tadesse, CEO");
    doc.addImage(signature, "PNG", 125, 165, 40, 10);
    doc.setFontSize(12);
    doc.text(222, 180, `${certificateCode}`);
    window.open(doc.output("bloburl"));
  };

  // let firstname1 = "one";
  // let lastname1 = "two";

  useEffect(() => {
    // generateCertificateCode();
    getCertifiedUser();
  }, []);
  return isCertified ? (
    <>
      <Button
        icon={<DownloadOutlined />}
        type="primary"
        style={{
          background: Colors.orange,
          borderRadius: 2,
          color: "white",
          borderColor: Colors.orange,
        }}
        onClick={downloadCertificate}
      >
        Download Certificate
      </Button>
      <br />
      <Layout id="main">
        <Content
          className="site-layout"
          style={{
            padding: "0 0px",
            marginTop: 0,
            border: "20px solid #787878",
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 380,
              borderWidth: 20,
              backgroundColor: "white",
              borderColor: "black",
            }}
          >
            <Row justify="center">
              <Col>
                <Logo />
              </Col>
            </Row>
            <Row justify="center" style={{ marginBottom: -14 }}>
              <Col>
                <Title type="sucess" level={4}>
                  Safety and Driving Skills | lemmaj.com
                </Title>
              </Col>
            </Row>
            <Row justify="center" style={{ marginBottom: 20 }}>
              <Col>
                <Title level={4}>Addis Ababa, Ethiopia</Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Title level={1}>CERTIFICATION OF COMPLETION</Title>
              </Col>
            </Row>
            <Row justify="center" style={{ marginTop: -10, marginBottom: -10 }}>
              <Col>
                <Title level={5}>This Certificate is Presented to</Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Title level={4}>
                  {firstname1} {lastname1}
                </Title>
              </Col>
            </Row>
            <Row justify="center" style={{ marginBottom: -5 }}>
              <Col>
                <Title level={5}>
                  This is to certify that {firstname1} {lastname1} has completed{" "}
                </Title>
              </Col>
            </Row>
            <Row justify="center" style={{ marginBottom: -5 }}>
              <Col>
                <Title level={5}>
                  {packageName} Package with good attendance on{" "}
                </Title>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Title level={5}> {certifiedDate} </Title>
              </Col>
            </Row>
            <Row justify="center" style={{ marginTop: 20 }}>
              <Col>
                <Title level={5}>Mr. Mihretu Tadesse, CEO </Title>
              </Col>
            </Row>
            <Row justify="center" style={{ marginTop: 20 }}>
              <Col>
                <SignatureImage />
              </Col>
            </Row>
            <Row justify="end" style={{}}>
              <Col>{certificateCode}</Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  ) : null;
}

export default CourseCompletionCertificate;
