import React from 'react';
import { Tabs, Card } from 'antd';

import EnrolledStudents from "./EnrolledStudents";
import GraduatedStudents from "./GraduatedStudents";
import ScheduledStudents from "./ScheduledStudents";
import InActive from "./InActive";
import Absent from "./Absent";

const { TabPane } = Tabs;

export default function StudentStatus({userId, onLogout, axiosOption}) {
    return (
        <Card>
            <Tabs defaultActiveKey="1" >
                <TabPane tab="Enrolled" key="1">
                    <EnrolledStudents userId={userId} onLogout={onLogout} axiosOption={axiosOption}/>
                </TabPane>
                <TabPane tab="Scheduled" key="2">
                    <ScheduledStudents userId={userId} onLogout={onLogout}/>
                </TabPane>
                <TabPane tab="Graduated" key="3">
                    <GraduatedStudents userId={userId} onLogout={onLogout} axiosOption={axiosOption}/>
                </TabPane>
                <TabPane tab="Inactive" key="4">
                    <InActive userId={userId} onLogout={onLogout}  axiosOption={axiosOption}/>
                </TabPane>
                <TabPane tab="Absent" key="5">
                    <Absent userId={userId} onLogout={onLogout} axiosOption={axiosOption}/>
                </TabPane>
            </Tabs>
        </Card>
    )
}