import React, {useEffect, useState} from "react";
import { Table, Card } from 'antd';
import lemmajAxios from "../../../others/apiConfig";
import getEnrollments from "./QUERIES/getEnrollements";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";

export default function EnrolledStudents({userId, onLogout, axiosOption}) {
  const [isLoading, setIsLoading] = useState(true);
  const [enrollments, setEnrollments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
      getEnrollments(userId, axiosOption, setIsLoading, setEnrollments, onLogout)
      // getEnrollments();
    }, [])

    const handleSearchTerm = term => {
      setSearchTerm(term)
    }

    let filteredInstructors =
    !searchTerm || searchTerm === ""
      ? enrollments
      : enrollments.filter((i) => {
          return `${i.studentName.toLowerCase()}`
          .trim()
          .includes(searchTerm.toLocaleLowerCase().trim())
        });
      
      const columns = [
        {
          title: 'Student Name',
          dataIndex: 'studentName',
          key: 'student_name',
        },
        {
          title: 'Instructor Name',
          dataIndex: 'instructorName',
          key: 'instructor_name',
        },
        {
          title: 'Enrollment Date',
          dataIndex: 'enrollmentDate',
          key: 'enrollment_date',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Completed',
          dataIndex: 'completed',
          key: 'completed',
        },
        {
          title: 'Is Done',
          dataIndex: 'isDone',
          key: 'isDone',
        },
      ];
      return (
          <>
            <Card style={{ marginBottom: 10, borderRadius: 16 }}>
              <AppInputSearch 
              onSearch={handleSearchTerm}
              placeholder="Enrolled Student" />
            </Card>
            <Table 
            loading={isLoading}
            dataSource={filteredInstructors} 
            scroll={{x: 50}}
            columns={columns} />
          </>
      )
}