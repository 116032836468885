import React from "react";
import { Form, Input, Button, Row, Col, DatePicker, Select } from "antd";
import { PhoneFilled, UserOutlined, MailFilled } from "@ant-design/icons";
import moment from "moment";
import Colors from "../../../constants/Colors";
function AddressForm({
  disabled = false,
  submitting = false,
  onSubmit = () => null,
  region = "",
  city = "",
  country = "",
}) {
  const [form] = Form.useForm();
  form.setFieldsValue({
    city: city,
    region: region,
    country: country,
  });
  return (
    <Form
      form={form}
      style={{ marginTop: 0 }}
      layout="vertical"
      onFinish={(values) => {
        const data = {
          city: values.city,
          region: values.region,
          country: values.country,
        };
        onSubmit(data);
      }}
    >
      <Form.Item
        label="Country"
        name="country"
        rules={[{ required: true, message: "Country is required" }]}
      >
        <Input disabled={disabled} placeholder="Country" />
      </Form.Item>
      <Form.Item
        label="Region"
        name="region"
        rules={[{ required: true, message: "Select a region" }]}
      >
        <Select disabled={disabled} placeholder="Region">
          <Select.Option key="Addis Ababa City State">
            Addis Ababa City State
          </Select.Option>
          <Select.Option key="Afar">Afar</Select.Option>
          <Select.Option key="Amhara">Amhara</Select.Option>
          <Select.Option key="Benishangul Gumuz">
            Benishangul Gumuz
          </Select.Option>
          <Select.Option key=" Dire Dawa City State">
            Dire Dawa City State
          </Select.Option>
          <Select.Option key="Gambela">Gambela</Select.Option>
          <Select.Option key="Harari">Harari</Select.Option>
          <Select.Option key="Oromia">Oromia</Select.Option>
          <Select.Option key="SNNP">SNNP</Select.Option>
          <Select.Option key="Sidama">Sidama</Select.Option>
          <Select.Option key="Tigray">Tigray</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="City"
        name="city"
        rules={[{ required: true, message: "City is required" }]}
      >
        <Input disabled={disabled} placeholder="City" />
      </Form.Item>
      {/* <Form.Item
        label="Subcity"
        name="subcity"
        rules={[{ required: true, message: "Subcity is required" }]}
      >
        <Input placeholder="Subcity" />
      </Form.Item> */}
      {/* <Form.Item
        label="Woreda"
        name="woreda"
        rules={[{ required: true, message: "Woreda is required" }]}
      >
        <Input placeholder="Woreda" />
      </Form.Item> */}
      {!disabled ? (
        <Form.Item>
          <Row justify="start">
            <Col xs={24} sm={8}>
              <Button
                style={{ background: Colors.blue2, borderRadius: Colors.blue2 }}
                block
                type="primary"
                htmlType="submit"
                loading={submitting}
              >
                Update Information
              </Button>
            </Col>
          </Row>
        </Form.Item>
      ) : null}
    </Form>
  );
}

export default AddressForm;
