import React, { useEffect, useState } from "react";
import {
  Calendar,
  Alert,
  Row,
  Col,
  Modal,
  List,
  message,
  Button,
  Descriptions,
  Space,
  Spin,
} from "antd";
import moment from "moment";
import Colors from "../../../constants/Colors";
import { ClockCircleFilled } from "@ant-design/icons";
import { useHistory } from "react-router";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import Routes from "../../Routes";

const CalendarStyle = {
  height: "70%",
  width: "70%",
  lineHeight: "40px",
  borderRadius: 2,
  backgroundColor: Colors.lightGrey,
  textAlign: "center",
  fontSize: 14,
  margin: 10,
};

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function ScdeuleCalender({
  userId = "",
  authToken = "",
  onLogout = () => null,
}) {
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const history = useHistory();
  const instructorID = new URLSearchParams(history.location.search).get("id");

  const isDateOnSchedule = (momentDay) => {
    const allScedules = schedule.map((d, i) => {
      return {
        ...d,
        startDate: moment(d.startDate).format("DD/MM/YYYY"),
        endDate: moment(d.endDate).format("DD/MM/YYYY"),
      };
    });
    const formattedDate = momentDay.format("DD/MM/YYYY");
    const scheduleDates = [];
    allScedules.forEach((d, i) => {
      // check if slot is occupied

      if (!d.studentId && d.startDate === formattedDate) {
        scheduleDates.push(schedule[i]);
      }
    });
    if (!scheduleDates || scheduleDates.length <= 0) {
      return [];
    }
    return scheduleDates;
  };

  const fetchSchedules = () => {
    if (!instructorID) {
      return;
    }
    setLoading(true);
    lemmajAxios
      .get("/Schedules/", {
        params: {
          access_token: authToken,
          "filter[where][instructorId][like]": instructorID,
        },
        ...axiosOption,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }
        setSchedule(res.data);
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setLoading(false);
      });
  };

  const onPeriodSelect = (period) => {
    const data = {
      ...period,
      vehiclePlateNumber: "NA plate number",
      studentId: userId,
      scheduleStatus: "scheduled",
      isApproved: false,
      isRejected: false,
      trainingLocation: "NA training location",
      specificLocation: "NA specific location",
    };

    Modal.confirm({
      onOk: () => CompleteSchedule(data),
      content: (
        <>
          <p>
            <b>Date: </b>
            {moment(new Date(data.startDate), "hh:mm a").format("DD/MMM/YYYY")}
          </p>
          <p>
            <b>Start Time: </b>
            {moment(new Date(data.startDate), "hh:mm a").format("hh:mm a")}
          </p>
          <p>
            <b>End Time: </b>
            {moment(new Date(data.endDate), "hh:mm a").format("hh:mm a")}
          </p>

          <p>
            <b>Vechile Plate Number: </b>
            {data.vehiclePlateNumber}
          </p>

          <p>
            <b>Location: </b>
            {data.trainingLocation}
          </p>
        </>
      ),
    });
  };

  const CompleteSchedule = (argData) => {
    const data = { ...argData, scheduledDate: moment(Date.now()).toString() };
    setLoading(true);
    const period = history.location.state.period;
    lemmajAxios
      .patch(`/Schedules/${data.id}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }

        if (period && period.id) {
          const periodData = {
            scheduleId: argData.id,
            instructorId: argData.instructorId,
            periodStatus: "scheduled",
          };
          setLoading(true);
          lemmajAxios
            .patch(`/Periods/${period.id}`, periodData)
            .then((res) => {
              if (res.data.error) {
                message.error(res.statusText);
                return;
              }
              message.success("Period scheduled successfully");
              Modal.destroyAll();
              history.replace(Routes.PACKAGE);
            })
            .catch((err) => {
              message.error(Messages.OPREATION_FAILED);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const onCellSelect = (date, periods) => {
    Modal.info({
      title: `Scheduled on ${date}`,
      okText: "CLOSE",
      closable: false,
      icon: false,
      content: (
        <List
          dataSource={periods}
          renderItem={(period) => {
            return (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Space>
                      <ClockCircleFilled style={{ color: Colors.orange }} />
                      {moment(new Date(period.startDate), "hh:mm a").format(
                        "hh:mm a"
                      )}{" "}
                      -{" "}
                      {moment(new Date(period.endDate), "hh:mm a").format(
                        "hh:mm a"
                      )}{" "}
                    </Space>
                  }
                />
                <Button
                  onClick={() => onPeriodSelect(period)}
                  type="primary"
                  style={{
                    background: Colors.blue2,
                    borderColor: Colors.blue2,
                  }}
                >
                  Schedule
                </Button>
              </List.Item>
            );
          }}
        />
      ),
    });
  };

  return (
    <Spin spinning={loading}>
      <Calendar
        mode="month"
        fullscreen={false}
        validRange={[moment(Date.now()), moment(Date.now()).add(1, "year")]}
        onSelect={(value) => {
          const momentDay = moment(value);
          const scheduleOnDay = isDateOnSchedule(momentDay);
          if (scheduleOnDay.length > 0) {
            const fullDateString = moment(value).format("MMM-DD-YYYY");
            onCellSelect(fullDateString, scheduleOnDay);
          }
        }}
        dateFullCellRender={(value) => {
          const day = Number(moment(value).toDate().getDate());
          const momentDay = moment(value);

          let scheduleOnDay = [];
          if (momentDay.isAfter(moment(Date.now()))) {
            scheduleOnDay = isDateOnSchedule(momentDay);
          }

          const cell =
            scheduleOnDay.length > 0 ? (
              <div
                onClick={() => {}}
                style={{
                  height: "70%",
                  width: "70%",
                  lineHeight: "40px",
                  borderRadius: 2,
                  backgroundColor: Colors.yellow,
                  textAlign: "center",
                  fontSize: 14,
                  margin: 10,
                  color: Colors.white,
                }}
              >
                {day}
              </div>
            ) : (
              <div style={CalendarStyle}>{day}</div>
            );
          return cell;
        }}
      />
    </Spin>
  );
}

export default ScdeuleCalender;
