import React, { useState } from "react";
import {
  Row,
  Col,
  Avatar,
  Button,
  Menu,
  Typography,
  Drawer,
  Input,
  Form,
  DatePicker,
  message,
  Spin,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";
import Colors from "../../../constants/Colors";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import Messages from "../../../others/Messages";
import lemmajAxios from "../../../others/apiConfig";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function UserBarDropDown({
  name,
  imageSrc,
  username,
  extraData,
  dropDownCloseHandler,
  userId,
  authToken,
  onLogout,
}) {
  const onFinish = (values) => {};

  const onStartDateSelected = () => {
    setStartDateSelected(true);
  };

  const onStartDateChanged = (value) => {
    setStartDateValue(value);
  };

  const onEndDateChanged = (value) => {
    setEndDateValue(value);
  };

  const [drawerVisible, setDrawer] = useState(false);
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [submmiting, setSubmmittng] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const openDrawer = () => {
    setDrawer(true);
  };
  const closeDrawer = () => {
    setDrawer(false);
  };

  function disabledEndDate(current) {
    // Can not select days before today and today
    return current && current <= startDateValue;
  }

  function disabledStartDate(current) {
    // Can not select days before today and today
    return current && endDateValue && current >= endDateValue;
  }

  const submitLeaveRequest = (values) => {


    const currentTime = Date.now().toString();

    const data ={
      instructorId: userId,
      reason: values.reason,
      isApproved: false,
      status: "default",
      createdDate: currentTime,
      startDate: values.leaveStartDate,
      endDate: values.leaveEndDate,
    };

    //post the leave request form data
    lemmajAxios
      .post("/Leaves", data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        closeDrawer();
        message.success(Messages.LEAVE_REQUESTED);
        setSubmmittng(false);
        form.resetFields();
      })
      .catch((err) => {
        setSubmmittng(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  const [firstName, lastName] = name.split(" ");
  return (
    <>
      <Row style={{ padding: "10px" }} justify="center">
        <Col>
          <Menu>
            <Menu.Item
              onClick={() => {
                history.push(Routes.ACCOUNT);
              }}
            >
              <Row gutter={(12, 12)}>
                <Col>
                  {imageSrc ? (
                    <Avatar src={imageSrc} />
                  ) : (
                    <Avatar
                      style={{
                        verticalAlign: "middle",
                        background: Colors.orange,
                      }}
                    >
                      <span>
                        {firstName && String(firstName).toUpperCase()[0]}
                      </span>
                      <span>
                        {lastName && String(lastName).toUpperCase()[0]}
                      </span>
                    </Avatar>
                  )}
                </Col>
                <Col>
                  <div>{name}</div>
                  <Typography.Text type="secondary">{username}</Typography.Text>
                </Col>
              </Row>
            </Menu.Item>
            {extraData.map((itemObj) => {
              return (
                <Menu.Item>
                  <Row gutter={(16, 16)}>
                    <Col>
                      <itemObj.iconComponent style={{ fontSize: 20 }} />
                    </Col>
                    <Col
                      onClick={() => {
                        itemObj.clickHandler();
                      }}
                    >
                      {itemObj.title}
                    </Col>
                  </Row>
                </Menu.Item>
              );
            })}
          </Menu>
          {/* <Button
            onClick={() => {
              openDrawer();
              dropDownCloseHandler();
            }}
            icon={<FormOutlined />}
            type="primary"
            block
          >
            Leave Form
          </Button> */}
        </Col>
      </Row>
      <Drawer
        title="Leave Form"
        onClose={closeDrawer}
        closable={false}
        placement="right"
        visible={drawerVisible}
      >
        <Spin spinning={submmiting}>
          <Form form={form} onFinish={submitLeaveRequest} layout="vertical">
            <Form.Item
              label="Reason"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please input your reason",
                },
              ]}
            >
              <Input.TextArea
                maxLength={100}
                placeholder="Please provide your reason"
              />
            </Form.Item>

            <Form.Item
              label="Leave Start Date"
              name="leaveStartDate"
              rules={[
                {
                  required: true,
                  message: "Please input start date",
                },
              ]}
            >
              <DatePicker
                showTime={{
                  defaultValue: moment("00", "HH"),
                  format: "HH",
                }}
                onSelect={onStartDateSelected}
                onChange={onStartDateChanged}
                disabledDate={disabledStartDate}
              />
            </Form.Item>
            <Form.Item
              label="Leave End Date"
              name="leaveEndDate"
              rules={[
                {
                  required: true,
                  message: "Please input end date",
                },
              ]}
            >
              <DatePicker
                showTime={{
                  defaultValue: moment("00", "HH"),
                  format: "HH",
                }}
                disabled={!startDateSelected}
                disabledDate={disabledEndDate}
                onChange={onEndDateChanged}
              />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
}

export default UserBarDropDown;
