import React from "react";
import { Card, Typography, Row, Col, Avatar, Progress } from "antd";
import { GiftFilled, PhoneFilled } from "@ant-design/icons";

import Colors from "../../../constants/Colors";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
// attach packageName, phone, image name, username using prop
function StudentStatusHeader({
  _package = { name: "" },
  packageProgress,
  user,
}) {
  return (
    <>
      <Card>
        <Typography.Title style={{ color: Colors.veryDarkGrey }} level={4}>
          {user && user.name}
        </Typography.Title>
        <Row gutter={(8, 8)} justify="space-between" align="middle">
          <Col>
            <Row gutter={(8, 8)}>
              <Col>
                {user && (
                  <UserAvatar
                    size={50}
                    name={user.name}
                    imagSrc={user.imageSrc}
                  />
                )}
              </Col>
              <Col>
                <Row gutter={(8, 8)}>
                  <Col>
                    <a href={`tel:${+user.phoneNumber}`}>
                      <PhoneFilled
                        style={{ fontSize: "20px" }}
                        color={Colors.lightGrey}
                        key="Phone"
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href={`tel:${user.phoneNumber}`}>
                      <Typography.Text
                        style={{ color: Colors.blue2, fontWeight: "bold" }}
                      >
                        {user.phoneNumber}
                      </Typography.Text>
                    </a>
                  </Col>
                </Row>
                <Row gutter={(8, 8)}>
                  <Col>
                    <GiftFilled
                      style={{ fontSize: "20px", color: Colors.orange }}
                    />
                  </Col>
                  <Col>
                    <Typography.Text
                      style={{ color: Colors.orange, fontWeight: "bold" }}
                    >
                      {_package && _package.name}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Progress width={50} type="circle" percent={packageProgress} />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default StudentStatusHeader;
