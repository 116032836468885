import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import getAbsents from "./QUERIES/getAbsent";
import { Card } from "@material-ui/core";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";

export default function Absent({userId, onLogout, axiosOption}) {
  const [isLoading, setIsLoading] = useState(true);
  const [absents, setAbsents] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); 

    useEffect(() => {
      getAbsents(userId, axiosOption, setIsLoading, setAbsents, onLogout)
    }, [])

    const handleSearchTerm = term => {
      setSearchTerm(term)
    }

    let filteredStudents =
    !searchTerm || searchTerm === ""
      ? absents
      : absents.filter((i) => {
          return `${i.studentName.toLowerCase()}`
          .trim()
          .includes(searchTerm.toLocaleLowerCase().trim())
        });
      
    const columns = [
      {
        title: 'Student Name',
        dataIndex: 'studentName',
        key: 'student_name',
      },
      {
        title: 'Scheduled Date',
        dataIndex: 'scheduledDate',
        key: 'scheduled_date',
      },
    ];
      return (
        <>
        <Card style={{ marginBottom: 10, borderRadius: 16 }}>
              <AppInputSearch
              onSearch={handleSearchTerm}
              placeholder="Absent Student" />
            </Card>
            <Table loading={isLoading} dataSource={filteredStudents} scroll={{x: 50}} columns={columns} />
            </>
      )
}