import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Divider,
  Typography,
  Card,
  message,
  Spin,
} from "antd";
import { UserOutlined, MailFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import { useHistory } from "react-router";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import moment from "moment";
import Routes from "../../Routes";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function Licence({ authToken = null, onLogout = () => {} }) {
  const [submmiting, setSubmmittng] = useState(false);
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const fillForm = (
    licenseType,
    licenseNumber,
    licenseIssuedDate,
    licenseExpiryDate
  ) => {
    form.setFieldsValue({
      licenseType: licenseType,
      regNum: licenseNumber,
      issuedDate: moment(licenseIssuedDate),
      dateFormat,
      expireDate: moment(licenseExpiryDate),
      dateFormat,
    });
  };

  const dateFormat = "YYYY-MM-DD";

  const fetchDataAndFillData = () => {
    const instructor_id = history.location.search.slice("id?=".length);
    setFetching(true);
    lemmajAxios
      .get(`/LemmajUsers/${instructor_id}`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const instructor = res.data;
        fillForm(
          instructor.licenseType,
          instructor.licenseNumber,
          instructor.licenseIssuedDate,
          instructor.licenseExpiryDate
        );
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchDataAndFillData();
  }, []);

  const onUpdateBasicInstructor = (values) => {
    const instructor_id = history.location.search.slice("id?=".length);
    const data = {
      licenseType: values.licenseType,
      licenseNumber: values.regNum,
      licenseIssuedDate: values.issuedDate,
      licenseExpiryDate: values.expireDate,
    };
    setSubmmittng(true);
    lemmajAxios
      .patch(`/LemmajUsers/${instructor_id}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        message.success(Messages.INFO_UPDATED);
        history.replace(`${Routes.INSTRUCTOR_DETAIL}?id=${instructor_id}`);
        setSubmmittng(false);
      })
      .catch((err) => {
        setSubmmittng(false);
        message.error(Messages.OPREATION_FAILED);
      });
    return;
  };

  return (
    <Spin spinning={submmiting}>
      <Card loading={fetching}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            onUpdateBasicInstructor(values);
          }}
        >
          <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
            License Information
          </Typography.Title>
          <Divider />
          <Form.Item
            name="licenseType"
            label="License Type"
            rules={[{ required: true, message: "License type is required" }]}
          >
            <Select placeholder="License Type">
              <Select.Option key="auto">Auto</Select.Option>
              <Select.Option key="bus1">Hizb 1</Select.Option>
              <Select.Option key="bus2">Hizb 2</Select.Option>
              <Select.Option key="heavy">Heavy</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="regNum"
            label="Registeration Number"
            rules={[
              { required: true, message: "Registeration Number is required" },
            ]}
          >
            <Input placeholder="Registeration Number" />
          </Form.Item>
          <Form.Item
            label="Issued Date"
            name="issuedDate"
            rules={[{ required: true, message: "Issued Date is required" }]}
          >
            <DatePicker placeholder="Issued Date" />
          </Form.Item>
          <Form.Item
            label="Expire Date"
            name="expireDate"
            rules={[{ required: true, message: "Expire Date is required" }]}
          >
            <DatePicker placeholder="Expire Date" />
          </Form.Item>{" "}
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  style={{
                    background: Colors.blue2,
                    borderRadius: Colors.blue2,
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default Licence;
