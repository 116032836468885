import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Card, Tag, Button, Space, message, Spin, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import Colors from "../../../constants/Colors";
import Routes from "../../Routes";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function Courses({ authToken, onLogout = () => {} }) {
  const [fetching, setFetching] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [courseData, setCourseData] = useState([]);

  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      .get("/Courses", axiosOption)
      .then((res) => {
        setFetching(false);
        const data = res.data.map((c) => {
          return { name: c.name, duration: c.length, id: c.id };
        });
        setCourseData(data);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchData();
    setFetching(false);
  }, []);

  const history = useHistory();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <Space>
            <Button
              onClick={() => {
                onUpdateButtonClicked(record.id);
              }}
              size="small"
              type="primary"
              style={{
                marginTop: 15,
                background: Colors.blue2,
                borderRadius: Colors.blue2,
              }}
            >
              <EditOutlined />
              Update
            </Button>
            <Button
              loading={deleting}
              onClick={() => {
                onDeleteBtnClicked(record.id);
              }}
              size="small"
              type="primary"
              style={{
                marginTop: 15,
                background: Colors.orange,
                borderColor: Colors.orange,
              }}
            >
              <DeleteOutlined />
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onUpdateButtonClicked = (id) => {
    history.push(`${Routes.UPDATE_COURSE}?id=${id}`);
  };

  const onDeleteBtnClicked = (id) => {
    Modal.confirm({
      title: Messages.DELETE_COURSE_CONFIRM,
      onOk: () => {
        handleDelete(id);
      },
    });
  };
  const handleDelete = (id) => {
    setDeleting(true);
    lemmajAxios
      .delete(`/Courses/${id}`, {
        params: { access_token: authToken },
        ...axiosOption,
      })
      .then((res) => {
        setDeleting(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.count <= 0) {
          message.error(Messages.ITEM_NOT_FOUND);
        } else {
          message.success(Messages.COURSE_DELETED);
          fetchData();
        }
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setDeleting(false);
      });
  };
  return (
    <Card style={{ borderRadius: 16 }}>
      <Button
        onClick={() => {
          history.push(Routes.ADD_COURSE);
        }}
        size="medium"
        type="primary"
        style={{
          marginBottom: 20,
          background: Colors.blue2,
          borderRadius: Colors.blue2,
        }}
      >
        <PlusOutlined />
        Add course
      </Button>
      <Table
        loading={fetching}
        pagination={{ position: "topRight" }}
        scroll={{ x: 20 }}
        columns={columns}
        dataSource={courseData}
        size="small"
      ></Table>
    </Card>
  );
}

export default Courses;
