import React from "react";
import lemmajAxios from "../../../../others/apiConfig";
import moment from "moment";

export default function getInactive(
  userId,
  axiosOption,
  setIsLoading,
  setInactiveUsers,
  onLogout
) {
  lemmajAxios
    .get("Schedules/getInactiveStudents", {
      params: {
        access_token: userId,
      },
      ...axiosOption,
    })
    .then((res) => {
      setIsLoading(false);
      if (res.data.error && res.data.error.statusCode === 401) {
        return onLogout();
      }
      if (res.data.error && res.data.error.statusCode === 404) {
        return res.json({ message: "data is not found" });
      }
      const data = res.data.map((inactive) => {
        return {
          studentName: `${inactive.firstName} ${inactive.lastName}`,
          lastSchedule: moment(inactive.lastScheduledDate).format(
            "MMM DD, YY"
          ),
          status: "inactive",
        };
      });

      setInactiveUsers(data);
    })
    .catch((err) => {
      setIsLoading(false);
    });
}
