import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  Tag,
  Row,
  Col,
  Button,
  Divider,
  Modal,
  List,
  Space,
  message,
  Typography,
} from "antd";
import Colors from "../../../constants/Colors";
import { CheckCircleFilled, ClockCircleFilled } from "@ant-design/icons";

import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import { useHistory } from "react-router";

const axiosOption = {
  validateStatus: function (status) {
    return status <= 500; // Reject only if the status code is greater than or equal to 500
  },
};
function AddComponentToPackage({
  authToken = null,
  packageId = "",
  onLogout = () => {},
  packageCourseIDs = [],
}) {
  //   const akkcourses = ["Course 1", "Course 2", "Course Music", "Sports Course"];
  const [selectedCoures, setselectedCoures] = useState([]);
  const [courses, setCourses] = useState([]);

  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const fetchCourses = () => {
    setFetching(true);
    lemmajAxios
      .get("/Courses", axiosOption)
      .then((res) => {
        setFetching(false);
        setCourses(res.data);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  const getPackageId = () => {
    return history.location.search.slice("?id=".length);
  };
  const fetchPackage = () => {
    lemmajAxios
      .get(`/LemmajPackages/${getPackageId()}`, {
        params: { access_token: authToken },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const _package = res.data;

        setselectedCoures(_package.courses);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchCourses();
    fetchPackage();
    setFetching(false);
  }, []);
  const handleChange = (courseId, checked) => {
    const newselectedCoures = selectedCoures.slice();
    const nextselectedCoures = checked
      ? [...newselectedCoures, courseId]
      : newselectedCoures.filter((c) => c !== courseId);
    setselectedCoures(nextselectedCoures);
  };
  const calculatedTotalMins = (selectedCourses) => {
    const _courses = courses.filter((c) => selectedCourses.includes(c.id));
    let totalMin = 0;
    _courses.forEach((c) => {
      const min = Number.parseInt(c.length);
      totalMin = min !== NaN ? totalMin + min : totalMin;
    });
    return totalMin;
  };

  const queryString = (data = {}) => {
    return Object.entries(data)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
  };
  const updateCoursesOfPackage = (data, id = "") => {
    setLoading(true);
    const courses = ["605607ff3c906300150f440b", "test"];
    data = {
      packageId: "606afdc1c930b80015264132",
      courses: ["605607ff3c906300150f440b", "test"],
    };

    const qeuryData = queryString(data);
    lemmajAxios
      .post("/LemmajPackages/replaceCourses", qeuryData, {
        params: {
          access_token: authToken,
        },

        headers: { "Content-Type": "application/x-www-form-urlencoded" },

        ...axiosOption,
      })
      .then((res) => {})
      .catch((err) => {
        setLoading(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  const handleAddCourse = () => {
    const data = selectedCoures ? selectedCoures : [];
    updateCoursesOfPackage({ courses: data }, "");
  };

  const courseCheckout = () => {
    const totalMins = calculatedTotalMins(selectedCoures);
    Modal.confirm({
      onOk: () => {
        handleAddCourse(selectedCoures);
      },
      title: "Update package courses ?",
      content: (
        <>
          <List
            header="Selected package"
            footer={`Total package minutes ${totalMins} mins`}
            dataSource={selectedCoures}
            renderItem={(courseId) => {
              const course = courses.filter((c) => c.id === courseId)[0];
              return (
                <List.Item key={courseId}>
                  <List.Item.Meta title={course.name} />
                  <Space>
                    <Tag>
                      <Space>
                        <span>{course.length}</span>
                        <span>mins</span>
                        <ClockCircleFilled />
                      </Space>
                    </Tag>
                    <CheckCircleFilled style={{ color: Colors.green2 }} />
                  </Space>
                </List.Item>
              );
            }}
          />
        </>
      ),
    });
  };
  return (
    <>
      <Card>
        <Row gutter={(16, 16)}>
          {courses.map((course) => {
            return (
              <Col style={{ marginBottom: 8 }}>
                <Tag.CheckableTag
                  key={course.id}
                  checked={selectedCoures.indexOf(course.id) > -1}
                  onChange={(checked) => handleChange(course.id, checked)}
                >
                  {course.name}
                </Tag.CheckableTag>
              </Col>
            );
          })}
        </Row>
      </Card>
      <Divider />
      {/* <Button
        onClick={courseCheckout}
        style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
        type="primary"
      >
        Update Courses
      </Button> */}
    </>
  );
}

export default AddComponentToPackage;
