import React from 'react';
import { Tabs, Card } from 'antd';
import Instructors from './Instructors';
import Periods from './Periods';

const { TabPane } = Tabs;

export default function InstructorWrapper({userId, onLogout, axiosOption}) {
    return (
        <Card>
            <Tabs defaultActiveKey="1" >
                <TabPane tab="Instructors" key="1">
                    <Instructors authToken={userId} />
                </TabPane>
                <TabPane tab="Periods" key="2">
                    <Periods authToken={userId} />
                </TabPane>
            </Tabs>
        </Card>
    )
}