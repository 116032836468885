import React, { useState } from "react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function ImageUpload({
  name,
  headers,
  title = "",
  action = null,
  multiple = false,
  onChange = () => {},
  fileList = [],
  maximumUploads = 1,
  manualUpload = false,
}) {
  const [imgCount, setImgCount] = useState(0);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleCancel = () => {
    setPreviewVisible(false);
  };
  return (
    <>
      <Upload
        beforeUpload={(file) => {
          if (manualUpload) {
            return false;
          }
          return true;
        }}
        multiple={multiple}
        onPreview={handlePreview}
        fileList={fileList}
        accept="image/png, image/jpeg"
        name={name}
        action={action}
        headers={headers}
        onChange={(value) => {
          setImgCount(value.fileList.length);
          onChange(value);
        }}
        listType="picture-card"
      >
        {imgCount < maximumUploads ? uploadButton : null}
      </Upload>
      <Modal
        visible={previewVisible}
        title={title}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt={title} style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
}

export default ImageUpload;
