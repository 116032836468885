import React, { formR, useState } from "react";
import {
  Form,
  Card,
  Typography,
  Input,
  Divider,
  Button,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import { UserOutlined, LockFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import Messages from "../../../others/Messages";
import lemmajAxios from "../../../others/apiConfig";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function AccountForm({ userData, onLogout }) {
  const passwordFormRef = React.createRef();
  const [submitting, isSubmitting] = useState(false);
  const onChangePassword = (values) => {
    const data = {
      oldPassword: values.oldPasssword,
      newPassword: values.newPassword,
    };
    if (!userData || !userData.id) {
      onLogout();
    }
    isSubmitting(true);
    lemmajAxios
      .post("/LemmajUsers/change-password", data, {
        params: {
          access_token: userData.id,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.tatusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 400) {
          message.error(Messages.INVALID_PASSWORD);
          isSubmitting(false);
          return;
        }
        message.success(Messages.PASSWORD_CHANGE_SUCCESS);
        isSubmitting(false);
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        isSubmitting(false);
      });
  };
  return (
    <Spin spinning={submitting}>
      {/* <Card style={{ marginBottom: 20 }}>
        <Typography.Title
          level={4}
          style={{
            color: Colors.mediumGrey,
          }}
        >
          {" "}
          Username
        </Typography.Title>
        <Form layout="vertical" onFinish={() => {}}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Username is required" }]}
          >
            <Input addonBefore={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button block type="primary" htmlType="submit">
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card> */}
      {/* <Divider /> */}
      <Card>
        <Typography.Title
          level={4}
          style={{
            color: Colors.mediumGrey,
          }}
        >
          {" "}
          Password
        </Typography.Title>
        <Form
          onFinish={onChangePassword}
          layout="vertical"
          ref={passwordFormRef}
        >
          <Form.Item
            name="oldPasssword"
            label="Old Passowrd"
            rules={[{ required: true, message: "Old password is required" }]}
          >
            <Input addonBefore={<LockFilled />} placeholder="Old Password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Passowrd"
            rules={[
              { required: true, message: "New password is required" },
              () => ({
                validator(rule, value) {
                  if (value && value.length < 6) {
                    return Promise.reject(
                      "Minimum password length allowed is 6"
                    );
                  }
                  if (value && value.length > 35) {
                    return Promise.reject(
                      "Maximum password length allowed is 35"
                    );
                  }
                  //   const passdReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[ \~\`\!\@\#\$\%\^\&\*\(\)\_\+\=\-\{\}\[\]\|\\\"\'\:\;\?\>\<\.\,\/])/;
                  //   if (!value.match(passdReg)) {
                  //     return Promise.reject(
                  //       "Password must contian an uppercase a lowercase a digit and a special character"
                  //     );
                  //   }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input addonBefore={<LockFilled />} placeholder="New Password" />
          </Form.Item>
          <Form.Item
            name="confirmPasswor"
            label="Confirm Passowrd"
            rules={[
              { required: true, message: "Confirm password field is required" },
              () => ({
                validator(rule, value) {
                  if (
                    passwordFormRef.current.getFieldValue("newPassword") !=
                    value
                  ) {
                    return Promise.reject("Password does not match");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              addonBefore={<LockFilled />}
              placeholder="Confirm Password"
            />
          </Form.Item>
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  style={{ background: Colors.blue2 }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default AccountForm;
