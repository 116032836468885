import React from "react";
import { Row, Col, Avatar, Badge, Dropdown } from "antd";
import {
  BellTwoTone,
  SettingFilled,
  LogoutOutlined,
  LockFilled,
  UserOutlined,
} from "@ant-design/icons";
import UserBarDropDown from "../UserBarDropDonw/UserBarDropDown";

function UserNotificationBar({
  name,
  username,
  imageSrc,
  notificatinCount,
  onAccountClicked,
  onPasswordClicked,
  onUserNameClicked,
  onLogout,
}) {
  const menu = (
    <UserBarDropDown
      onUserNameClicked={onUserNameClicked}
      name={name}
      username={username}
      imageSrc={imageSrc}
      extraData={[
        // {
        //   title: "Account",
        //   iconComponent: SettingFilled,
        //   clickHandler: onAccountClicked,
        // },
        {
          title: "Password",
          iconComponent: LockFilled,
          clickHandler: onPasswordClicked,
        },
        {
          title: "Logout",
          iconComponent: LogoutOutlined,
          clickHandler: onLogout,
        },
      ]}
    />
  );
  return (
    <Row
      style={{ marginRight: "30px" }}
      gutter={(12, 12)}
      align="stretch"
      justify="space-around"
    >
      {/* <Col>
        <Badge count={notificatinCount}>
          <BellTwoTone style={{ fontSize: 27 }} />
        </Badge>
      </Col> */}
      <Col>
        <Dropdown trigger={["click", "hover"]} overlay={menu}>
          {imageSrc ? (
            <Avatar
              icon={<UserOutlined />}
              style={{ background: "#fde3cf" }}
              size={35}
              src={imageSrc}
            />
          ) : (
            <Avatar icon={<UserOutlined />} size={35} />
          )}
        </Dropdown>
      </Col>
    </Row>
  );
}

export default UserNotificationBar;
