import {
  Card,
  Divider,
  Form,
  Input,
  Space,
  Typography,
  Row,
  Col,
  Button,
  Spin,
  message,
} from "antd";
import React, { useState } from "react";
import PasswordChangeForm from "../../../component/PasswordChangeForm/PasswordChangeForm";
import {
  MailFilled,
  MailOutlined,
  PhoneFilled,
  UserOutlined,
} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import BasciForm from "../../../Instructor/component/AccountForm/BasciForm";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
const Account = ({ admin, authToken, onLogout, onUpdate }) => {
  const [submmiting, setSubmiting] = useState(false);

  const onChangePassword = (values) => {
    const data = {
      oldPassword: values.oldPasssword,
      newPassword: values.newPassword,
    };

    setSubmiting(true);
    lemmajAxios
      .post("/LemmajUsers/change-password", data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 400) {
          message.error(Messages.INVALID_PASSWORD);
          setSubmiting(false);
          return;
        }
        message.success(Messages.PASSWORD_CHANGE_SUCCESS);
        setSubmiting(false);
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setSubmiting(false);
      });
  };
  const [emailForm] = Form.useForm();
  const [basciForm] = Form.useForm();

  const { email, firstName, lastName, phoneNumber } = admin;
  emailForm.setFieldsValue({
    email: email,
  });
  basciForm.setFieldsValue({
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber.slice(2),
  });

  const updateBasicForm = (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: `09${values.phoneNumber}`,
    };
    setSubmiting(true);
    lemmajAxios
      .patch(`/LemmajUsers/${admin.adminId}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setSubmiting(false);
        if (res.status === 401) {
          onLogout();
          return;
        }
        onUpdate();
        message.success(Messages.INFO_UPDATED);
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);

        setSubmiting(false);
      });
  };

  const updateEmailForm = (values) => {
    const data = {
      email: values.email,
    };
    setSubmiting(true);
    lemmajAxios
      .patch(`/LemmajUsers/${admin.adminId}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setSubmiting(false);
        if (res.status === 401) {
          onLogout();
          return;
        }
        onUpdate();
        message.success(Messages.INFO_UPDATED);
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setSubmiting(false);
      });
  };
  return (
    <Spin spinning={submmiting}>
      <Card>
        <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
          Basic
        </Typography.Title>

        <Form form={basciForm} onFinish={updateBasicForm} layout="vertical">
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "First name is required" }]}
          >
            <Input placeholder="First name" addonBefore={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Last name is required" }]}
          >
            <Input placeholder="Last name" addonBefore={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phoneNumber"
            rules={[
              { required: true, message: "Phone is required" },
              () => ({
                validator(_, value) {
                  const digit = Number(value);
                  if (
                    Number.isNaN(digit) ||
                    value.includes("+") ||
                    value.includes(".") ||
                    !Number.isInteger(digit) ||
                    value < 0 ||
                    value.length < 8
                  ) {
                    return Promise.reject("Phone must be valid");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="tel"
              maxLength={8}
              prefix={"09"}
              placeholder="Phone"
              addonBefore={<PhoneFilled />}
            />
          </Form.Item>

          <Form.Item>
            <Row justify="space-between">
              <Col xs={24} sm={8}>
                <Button
                  style={{
                    background: Colors.blue2,
                    borderRadius: Colors.blue2,
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
      <Divider />
      <Card>
        <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
          Admin Email
        </Typography.Title>

        <Form onFinish={updateEmailForm} form={emailForm} layout="vertical">
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Email has to be valid" },
            ]}
          >
            <Input
              name="email"
              placeholder="email"
              type="email"
              addonBefore={<MailFilled />}
            />
          </Form.Item>
          <Form.Item>
            <Row justify="space-between">
              <Col xs={24} sm={8}>
                <Button
                  style={{
                    background: Colors.blue2,
                    borderRadius: Colors.blue2,
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
      <Divider />
      <Card>
        <PasswordChangeForm onSubmit={onChangePassword} />
      </Card>
    </Spin>
  );
};

export default Account;
