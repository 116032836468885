import React, { useState } from "react";
import { Row, Col, Avatar, Badge, Dropdown } from "antd";
import {
  BellFilled,
  SettingFilled,
  CalendarFilled,
  LogoutOutlined,
  LockFilled,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import UserBarDropDown from "../UserBarDropDonw/UserBarDropDown";
import InstructorNotifications from "../InstructorNotification/InstructorNotifications";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import { colors } from "@material-ui/core";

function UserNotificationBar({
  name,
  username,
  imageSrc,
  notificatinCount,
  setCountNotification,
  onScheduleClicked,
  onPasswrodClicked,
  onLogout,
  userId,
  authToken,
  getInstructorNotification,
  onUpdate,
}) {
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [notificationNumber, setNotificationNumber] = useState(
    notificatinCount
  );
  const [notificationVisible, setNotificationVisible] = useState(false);
  // const [isNotificationIconClicked, setIsNotificationIconClicked] = useState(false);
  const dropDownCloseHandler = () => {
    setDropDownVisible(false);
  };
  const [firstName, lastName] = name.split(" ");

  const menu = (
    <UserBarDropDown
      name={name}
      username={username}
      imageSrc={imageSrc}
      dropDownCloseHandler={dropDownCloseHandler}
      authToken={authToken}
      userId={userId}
      onLogout={onLogout}
      extraData={[
        {
          title: "My Scehdule",
          iconComponent: CalendarFilled,
          clickHandler: onScheduleClicked,
        },
        {
          title: "Password",
          iconComponent: LockFilled,
          clickHandler: onPasswrodClicked,
        },
        {
          title: "Logout",
          iconComponent: LogoutOutlined,
          clickHandler: onLogout,
        },
      ]}
    />
  );
  const notificationMenu = () => {
    setCountNotification(0);
    const data = {
      isSeen: true,
    };
    lemmajAxios
      .post(
        `http://server.lemmaj.com.et/api/Notifications/update?[where][instructorId][like]=${userId}`,
        data,
        {
          params: {
            accessToken: authToken,
          },
        }
      )
      .then((res) => {
        if (res.data.error && res.data.error.StatusCode == 401) {
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          return;
        }
      })
      .catch((err) => {});
    return (
      <InstructorNotifications
        getInstructorNotification={getInstructorNotification}
        onUpdate={onUpdate}
        onDelete={() => setNotificationVisible(false)}
      />
    );
  };
  return (
    <Row
      style={{ marginRight: "30px" }}
      gutter={(12, 12)}
      align="middle"
      justify="space-around"
    >
      <Col>
        
        <Dropdown

          overlay={notificationMenu}
          placement="bottomRight"
          // trigger={['click']}
          visible={notificationVisible}
          onClick = {() => setNotificationVisible(!notificationVisible)}
        >
          <Badge
            count={notificatinCount}
            
          >
            <BellOutlined style={{ fontSize: 20 }} />
          </Badge>
        </Dropdown>
        
      </Col>
      <Col
        onMouseLeave={() => {
          setDropDownVisible(false);
        }}
      >
        <Dropdown
          arrow
          visible={dropDownVisible}
          trigger={["click", "hover"]}
          overlay={menu}
        >
          <div
            onMouseOver={() => {
              setDropDownVisible(true);
            }}
          >
            {imageSrc ? (
              <Avatar
                icon={<UserOutlined />}
                style={{ background: Colors.orange }}
                size={35}
                src={imageSrc}
              />
            ) : (
              <Avatar
                icon={<UserOutlined />}
                size={35}
                style={{ verticalAlign: "middle" }}
              >
                <span style={{ fontSize: 60 * 0.5 }}>
                  {firstName && String(firstName).toUpperCase()[0]}
                </span>
                <span style={{ fontSize: 60 * 0.5 }}>
                  {lastName && String(lastName).toUpperCase()[0]}
                </span>
              </Avatar>
            )}
          </div>
        </Dropdown>
      </Col>
    </Row>
  );
}
export default UserNotificationBar;
