import lemmajAxios from "../../../../others/apiConfig";
export default function getVehicles(
  userId,
  axios_option,
  setVehicleData,
  onLogout,
  scheduleId
) {
  lemmajAxios
    .get("Schedules/getFreeVehiclesForSchedule?scheduleId="+scheduleId, {
      params: {
        access_token: userId,
      },
      ...axios_option,
    })
    .then((res) => {
      if (res.data.error && res.data.error.statusCode === 401) {
        onLogout();
      }
      if (res.data.error && res.data.error.statusCode === 404) {
      }
      setVehicleData(res.data);
    })
    .catch((err) => {});
}
