import lemmajAxios from "../../../../others/apiConfig";
import moment from "moment";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
export default function getInstructorNotification(
  setCountNotification,
  setInstructorNotification
) {
  const userAuthData = JSON.parse(localStorage.getItem("userAuth"));
  const authToken = userAuthData.id;
  // const [countNotification, setCountNotification] = useState(0);
  return lemmajAxios
    .get(`https://lemmaj.herokuapp.com/api/Notifications`, {
      params: {
        access_token: authToken,
        "filter[where][instructorId][like]": userAuthData.user.id,
      },
      ...axiosOption,
    })
    .then((res) => {
      if (res.data.error && res.data.error.statusCode === 401) {
        return;
      }
      if (res.data.error && res.data.error.statusCode === 404) {
        return;
      }
      let countNotification = 0;
      res.data.reverse().map((noti) => {
        if (noti.isSeen === false) {
          countNotification++;
        }
      });
      res.data.map((notification) => {
        return (notification.message =
          notification.message +
          "\n on " +
          moment(notification.createdAt).format("MMM DD, YY"));
      });
      setCountNotification(countNotification);
      setInstructorNotification(res.data);
    })
    .catch((err) => {});
}
