import React from "react";
import {
  Card,
  Collapse,
  Typograph,
  Row,
  Col,
  Typography,
  Divider,
  List,
} from "antd";
import { GiftFilled, CheckCircleFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import EnrollmentContentHeader from "./EnrollmentContentHeader";

function EnrollmentContent({
  instructor,
  _package,
  student,
  period,
  startDate,
  portal_view = 0,
}) {
  return (
    <>
      <EnrollmentContentHeader
        portal_view={portal_view}
        _package={_package}
        startDate={startDate}
        period={period}
        student={student}
      />
      {/* <List
        dataSource={[
          {
            name: "Peroid A",
            completed: true,
          },
          {
            name: "Peroid B",
            completed: false,
          },
          {
            name: "Peroid C",
            completed: true,
          },
        ]}
        renderItem={(period) => {
          return (
            <List.Item>
              <span style={{ margin: 8 }}>{period.name}</span>
              {period.completed ? (
                <Typography.Text strong style={{ marginRight: 8 }}>
                  <CheckCircleFilled
                    style={{ color: Colors.green1, marginRight: 8 }}
                  />
                  Completed <br />
                  Jan-2-1990
                </Typography.Text>
              ) : (
                <Typography.Text strong style={{ marginRight: 8 }} strong>
                  Not Completed
                </Typography.Text>
              )}
            </List.Item>
          );
        }}
      /> */}
    </>
  );
}

export default EnrollmentContent;
