import React, { useState } from "react";
import { Card, Avatar, Tooltip, Row, Col, Button } from "antd";
import {
  PhoneFilled,
  GiftFilled,
  ReloadOutlined,
  SettingOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import Modal from "antd/lib/modal/Modal";
import SelectPackageModal from "../SelectPackageModal/SelectPackageModal";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";

const { Meta } = Card;

const ChangePackageCard = ({
  packageName,
  duration,
  onClick,
  imgSrc,
  description,
}) => {
  const students = [
    {
      firstName: "Daniel",
      lastName: "Zelalem",
      phone: +251923244699,
      username: "@daniel",
      imageSrc: "../../mosh.jpg",
      packageName: "PACKAGE A",
    },
    {
      firstName: "Daniel",
      lastName: "Zelalem",
      phone: +251923244699,
      username: "@daniel",
      imageSrc: "../../mosh.jpg",
      packageName: "PACKAGE A",
    },
    {
      firstName: "Daniel",
      lastName: "Zelalem",
      phone: +251923244699,
      username: "@daniel",
      imageSrc: "../../mosh.jpg",
      packageName: "PACKAGE A",
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Card
        style={{
          width: 300,
          borderRadius: 16,
        }}
        hoverable
        cover={<img alt="example" src={imgSrc} />}
        actions={[
          <Button
            onClick={showModal}
            size="small"
            type="primary"
            shape="round"
            style={{
              background: Colors.orange,
              border: Colors.orange,
            }}
          >
            Select Package
          </Button>,

          <Modal
            title="Courses not taken"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <SelectPackageModal students={students} />
          </Modal>,
        ]}
      >
        <Meta
          avatar={<GiftFilled />}
          title={`${packageName}`}
          description={`${description}`}
        />
      </Card>
    </>
  );
};

export default ChangePackageCard;
