import React from "react";
import { Card, Avatar, Tooltip, Row, Col } from "antd";
import { PhoneFilled, GiftFilled, BookOutlined } from "@ant-design/icons";
import Colors from "../../../constants/Colors";

const { Meta } = Card;

const SelectPackageCourseCard = ({
  firstName,
  lastName,
  username,
  phoneNumber,
  packageName,
  onClick,
  userAvatar,
}) => {
  return (
    <Card
      hoverable
      style={{ margin: "8px", borderRadius: 16 }}
      onClick={onClick}
    >
      <Meta
        avatar={<BookOutlined />}
        title={`${firstName} ${lastName}`}
        description={
          <Row gutter={[{ xs: 24, sm: 24, md: 16, lg: 8 }]}>
            <Col style={{ color: Colors.darkGrey }}>{username}</Col>
          </Row>
        }
      />
    </Card>
  );
};

export default SelectPackageCourseCard;