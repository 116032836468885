import React from "react";
import {
  Calendar,
  Badge,
  Typography,
  Alert,
  Row,
  Col,
  notification,
  Modal,
  message,
  List,
  Space,
  Card,
} from "antd";
import moment from "moment";
import Colors from "../../../constants/Colors";
import {
  InfoCircleFilled,
  ClockCircleTwoTone,
  ClockCircleFilled,
  ClockCircleOutlined,
} from "@ant-design/icons";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import { useHistory } from "react-router";
import Routes from "../../Routes";
const CalendarStyle = {
  height: "70%",
  width: "70%",
  lineHeight: "40px",
  borderRadius: 2,
  backgroundColor: Colors.lightGrey,
  textAlign: "center",
  fontSize: 14,
  margin: 10,
};

function Schedule({ dutyDays = [], exceptionDays = [] }) {
  const history = useHistory();
  const isScheduled = (momentDay) => {
    const allScedules = dutyDays.map((scheduleObj, i) => {
      const d = scheduleObj.schedule;
      return {
        ...d,
        startDate: moment(d.startDate).format("DD/MM/YYYY"),
        endDate: moment(d.endDate).format("DD/MM/YYYY"),
      };
    });

    const formattedDate = momentDay.format("DD/MM/YYYY");
    const scheduleDates = [];
    allScedules.forEach((d, i) => {
      if (
        d &&
        d.scheduleStatus === "scheduled" &&
        d.studentId &&
        d.startDate === formattedDate &&
        d.isApproved
      ) {
        scheduleDates.push(dutyDays[i]);
      }
    });
    if (!scheduleDates || scheduleDates.length <= 0) {
      return [];
    }
    return scheduleDates;
  };

  const onScheduleClick = (studId) => {
    Modal.destroyAll();
    history.push(`${Routes.STUDENT}?id=${studId}`);
  };
  const onCellSelect = (scheduledSchedules) => {
    Modal.info({
      okText: "CLOSE",
      closable: false,
      icon: false,
      content: (
        <List
          pagination={{ pageSize: 4 }}
          header={
            <b>
              {`Scheduled on ${moment(
                new Date(scheduledSchedules[0].schedule.startDate)
              ).format("DD-MMM-YYYY")}`}
            </b>
          }
          dataSource={scheduledSchedules}
          renderItem={(s) => {
            return (
              <List.Item onClick={() => onScheduleClick(s.schedule.studentId)}>
                <Card hoverable bordered={false} style={{ width: "100%" }}>
                  <List.Item.Meta
                    title={
                      <Typography.Text>{`${s.student.firstName} ${s.student.lastName}`}</Typography.Text>
                    }
                  />
                  <Space>
                    <ClockCircleFilled style={{ color: Colors.orange }} />

                    <span>
                      {`${moment(
                        new Date(s.schedule.startDate),
                        "hh:mm a"
                      ).format("hh:mm a")} - ${moment(
                        new Date(s.schedule.endDate),
                        "hh:mm a"
                      ).format("hh:mm a")}`}
                    </span>
                  </Space>
                </Card>
              </List.Item>
            );
          }}
        />
      ),
    });
  };
  return (
    <div>
      {/* <Alert
        banner
        icon={<ClockCircleFilled />}
        showIcon
        description={
          <>
            <Row gutter={(8, 8)} align="middle">
              <Col style={{ color: Colors.orange }}>
                Daniel Zelalem is Scheduled at 4:00 morning{" "}
              </Col>
            </Row>
          </>
        }
        type="warning"
      /> */}

      <Calendar
        mode="month"
        fullscreen={false}
        onSelect={(value) => {
          const day = Number(moment(value).toDate().getDate());
          const momentDay = moment(value);
          const scheduledSchedule = isScheduled(momentDay);
          if (scheduledSchedule.length > 0) {
            onCellSelect(scheduledSchedule);
          }
        }}
        dateFullCellRender={(value) => {
          const day = Number(moment(value).toDate().getDate());
          const momentDay = moment(value);

          const cell =
            isScheduled(momentDay).length > 0 ? (
              <div
                onClick={() => {}}
                style={{
                  height: "70%",
                  width: "70%",
                  lineHeight: "40px",
                  borderRadius: 2,
                  backgroundColor: Colors.yellow,
                  textAlign: "center",
                  fontSize: 14,
                  margin: 10,
                  color: Colors.white,
                }}
              >
                {day}
              </div>
            ) : (
              <div style={CalendarStyle}>{day}</div>
            );
          return cell;
        }}
      />
    </div>
  );
}

export default Schedule;
