import React, { useState } from "react";
import AppLayout from "../AppLayout/AppLayout";
import LemmajInstructorApp from "../../Instructor/component/App/LemajInstructorApp";
import LemmajDriver from "../../Driver/component/App/LemmajDriverApp";
import Register from "../Register/Register";
import Login from "../Login/Login";
import AppInputSearch from "../AppInputSearch/AppInputSearch";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import Routes from "../../Routes";
import LemmajAdmin from "../../Admin/component/App/LemmajAdmin";
import PhoneVerify from "../PhoneVerify/PhoneVerify";
import { message, Spin } from "antd";
import Messages from "../../others/Messages";
import ScrollIntoView from "../ScrollInToView/ScrollIntoView";
function LemmajApp(props) {
  const history = useHistory();
  const [userAuthData, setUserAuthData] = useState(null);
  const [logginOut, setLoggingOut] = useState(false);
  const handleLogout = () => {
    setLoggingOut(true);
    setTimeout(() => {
      localStorage.removeItem("userAuth");
      setUserAuthData(null);
      history.replace(Routes.BASE);
      message.success(Messages.LOGGED_OUT);
      setLoggingOut(false);
    }, 1000);
  };
  const handleLogin = (user, userType, userId, id, ttl) => {
    const data = {
      user,
      userType,
      userId,
      id,
      ttl,
    };
    localStorage.setItem("userAuth", JSON.stringify(data));
    setUserAuthData({ user, userType, userId, id, ttl });
  };

  const app = (
    <ScrollIntoView>
      <Spin spinning={logginOut} tip={"Logging out"}>
        <Switch>
          <Route
            path={Routes.ADMIN}
            render={
              localStorage.getItem("userAuth") != null &&
              JSON.parse(localStorage.getItem("userAuth")).userType === "Admin"
                ? () => (
                    <LemmajAdmin
                      userAuthData={JSON.parse(
                        localStorage.getItem("userAuth")
                      )}
                      onLogout={handleLogout}
                    />
                  )
                : () => <Login onLogin={handleLogin} />
            }
          />
          <Route path={Routes.VERIFY_PHONE} component={PhoneVerify} />

          <Route
            path={Routes.DRIVER}
            render={
              localStorage.getItem("userAuth") != null &&
              JSON.parse(localStorage.getItem("userAuth")).userType === "Driver"
                ? () => (
                    <LemmajDriver
                      userAuthData={JSON.parse(
                        localStorage.getItem("userAuth")
                      )}
                      onLogout={handleLogout}
                    />
                  )
                : () => <Login onLogin={handleLogin} />
            }
          />

          <Route
            path={Routes.INSTRUCTOR}
            render={
              localStorage.getItem("userAuth") != null &&
              JSON.parse(localStorage.getItem("userAuth")).userType ===
                "Instructor"
                ? () => (
                    <LemmajInstructorApp
                      userAuthData={JSON.parse(
                        localStorage.getItem("userAuth")
                      )}
                      onLogout={handleLogout}
                    />
                  )
                : () => <Login onLogin={handleLogin} />
            }
          />

          <Route path={Routes.REGISTER} render={() => <Register />} />
          <Route
            path={Routes.LOGIN}
            render={() => <Login onLogin={handleLogin} />}
          />
          <Redirect path={Routes.BASE} to={Routes.LOGIN} />
        </Switch>
      </Spin>
    </ScrollIntoView>
  );
  return app;
}

export default LemmajApp;
