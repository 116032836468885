import React, { useState } from "react";
import StudentCard from "../StudentCard/StudentCard";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import { Card, List, Typography } from "antd";
import Colors from "../../../constants/Colors";
function Students({ students, onClick }) {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  let filtredStudents =
    !searchTerm || searchTerm === ""
      ? students
      : students.filter((i) => {
          return `${i.firstName.toLowerCase()} ${i.lastName.toLowerCase()}`
            .trim()
            .includes(searchTerm.toLowerCase().trim());
        });
  const onSearch = (term) => {
    setSearchTerm(term);
  };
  return (
    <Card>
      <List
        bordered={false}
        pagination={{ pageSize: 10, position: "bottom" }}
        dataSource={filtredStudents}
        header={
          <div style={{ marginBottom: 5 }}>
            <AppInputSearch placeholder="Search student" onSearch={onSearch} />
          </div>
        }
        loading={false}
        renderItem={(student) => {
          const name = student.firstName + " " + student.lastName;
          const userAvatar = (
            <UserAvatar
              preview={false}
              imagSrc={student.imageSrc}
              name={name}
              size={50}
            />
          );
          return (
            <StudentCard
              key={student.id}
              onClick={() => {
                history.push(`${Routes.STUDENT}?id=${student.id}`);
              }}
              firstName={student.firstName}
              lastName={student.lastName}
              phoneNumber={student.phoneNumber}
              username={student.email}
              packageName={student.packageName}
              userAvatar={userAvatar}
            />
          );
        }}
      />
    </Card>
  );
}

export default Students;
