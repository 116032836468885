import React from "react";
import { Card, Row, Col, Avatar, Typography } from "antd";
import { ClockCircleFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";

function TodayScheduleCard({ time, student, onClick }) {
  let [hr, min] = time;
  hr = parseInt(hr);
  const color =
    hr <= 3
      ? Colors.yellow
      : hr <= 5
      ? Colors.blue3
      : hr <= 9
      ? Colors.green3
      : Colors.purple2;
  const text = time[2];
  return (
    <Card
      onClick={onClick}
      hoverable
      style={{
        margin: 0,
        marginBottom: 8,
        padding: 0,
        borderLeftWidth: 10,
        borderLeftColor: color,
        borderRadius: 16,
      }}
    >
      <Row>
        <Col>
          <Card.Meta
            avatar={
              <UserAvatar
                name={`${student.firstName} ${student.lastName}`}
                size={40}
                preview={false}
                imagSrc={student.imgSrc}
              />
            }
            title={student.firstName + " " + student.lastName}
            description={
              <Row gutter={(8, 8)}>
                <Col>
                  <Row gutter={(8, 8)}>
                    <Col>
                      <ClockCircleFilled
                        style={{
                          color: Colors.orange,
                          background: Colors.white,
                          fontSize: "20px",
                        }}
                      />
                    </Col>
                    <Col>
                      <Typography.Text style={{ color: Colors.orange }}>
                        {`${hr}:${min} ${text}`}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row gutter={(8, 8)}>
                    <Col>
                      <a href={`tel:${student.phone}`}>
                        <PhoneFilled
                          style={{ fontSize: "20px" }}
                          color={Colors.lightGrey}
                          key="Phone"
                        />
                      </a>
                    </Col>
                    <Col>
                      <a href={`tel:${student.phone}`}>
                        <Typography.Text
                          style={{ color: Colors.blue2, fontWeight: "bold" }}
                        >
                          {student.phone}
                        </Typography.Text>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
    </Card>
  );
}

export default TodayScheduleCard;
