import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Avatar,
  Typography,
  Collapse,
  Descriptions,
  Image,
  Divider,
  Space,
  Spin,
  message,
} from "antd";
import {
  PhoneFilled,
  StarFilled,
  CarFilled,
  CommentOutlined,
} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import Comments from "../Comments/Comments";
import moment from "moment";
import { useHistory } from "react-router";
import lemmajAxios from "../../../others/apiConfig";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import Messages from "../../../others/Messages";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
const comments_data = [];

function Instructor({
  user = { firstName: "", lastName: "", imgSrc: "../../mosh.jpg" },
  stars = 1,
  withCommentForm = true,
  withComment = true,
  withStars = true,
  accessToken,
  onLogout = () => null,
}) {
  const [commentsShow, showComment] = useState(false);
  const [comments, setComments] = useState(comments_data);
  const [instructor, setInstructor] = useState({});
  const [fetching, setFetching] = useState(true);
  const [vechile, setVechile] = useState({
    plate: "",
    model: "",
    gearType: "",
    year: "",
  });
  const history = useHistory();

  const handleCommentIconToggle = () => {
    showComment(!commentsShow);
  };
  const addComment = (content) => {
    const newComments = comments.slice();
    const comment = {
      author: user.firstName + " " + user.lastName,
      content: content,
      imgSrc: user.imgSrc,
      dateTime: moment().format("MM-DD-YYYY, HH:mm"),
    };
    newComments.push(comment);
    setComments(newComments);
  };

  const getVechile = () => {
    const instructor_id = history.location.search.slice("id?=".length);
    setFetching(true);
    lemmajAxios
      .get(`/LemmajUsers/${instructor_id}/vehicle`, {
        params: {
          access_token: accessToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error(res.statusText);
        }
        const activeVehilce = res.data[0];
        setVechile(activeVehilce);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    let id = "someId";
    id = new URLSearchParams(history.location.search).get("id");

    lemmajAxios
      .get(`/LemmajUsers/${id}`, {
        params: {
          access_token: accessToken,
          "filter[where][userType]": "Instructor",
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          onLogout();
          return;
        }
        setInstructor(res.data);
        getVechile();
        setFetching(false);
      })
      .catch((err) => {});
  }, []);
  return (
    <Spin tip={fetching} spinning={fetching}>
      <Card
        loading={fetching}
        bordered={false}
        actions={[
          withComment && (
            <Row
              onClick={handleCommentIconToggle}
              style={{ margin: 20 }}
              gutter={(9, 16)}
            >
              <Col>
                <CommentOutlined />
              </Col>
              <Col>{comments.length} Comments</Col>
            </Row>
          ),
        ]}
        hoverable
        style={{ borderRadius: 16, marginBottom: 20 }}
      >
        <Row>
          <Col>
            <Card.Meta
              avatar={
                <Row align="middle" justify="center">
                  <Col>
                    <UserAvatar
                      name={
                        instructor &&
                        `${instructor.firstName} ${instructor.lastName}`
                      }
                      size={50}
                      imagSrc=""
                    />
                    {withStars && (
                      <div>
                        {[1, 2, 3, 4, 5].map((i) => {
                          return stars >= i ? (
                            <StarFilled
                              key={i}
                              style={{
                                color: Colors.orange,
                                marginRight: 1,
                              }}
                            />
                          ) : (
                            <StarFilled
                              key={i}
                              style={{
                                marginRight: 1,
                                color: Colors.lightGrey,
                                // background: Colors.mediumGrey,
                              }}
                            />
                          );
                        })}
                      </div>
                    )}
                  </Col>
                </Row>
              }
              title={
                <Typography.Text strong style={{ color: Colors.orange }}>
                  {instructor &&
                    `${instructor.firstName} ${instructor.lastName}`}
                </Typography.Text>
              }
              description={
                <>
                  <Row gutter={(8, 8)}>
                    <Col>
                      <a href={`tel:${instructor && instructor.phoneNumber}`}>
                        <Space>
                          <PhoneFilled
                            style={{ fontSize: "20px" }}
                            color={Colors.lightGrey}
                            key="Phone"
                          />
                          {instructor && instructor.phoneNumber}
                        </Space>
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Space>
                        <CarFilled
                          style={{ fontSize: "20px", color: Colors.green3 }}
                        />
                        <b>
                          {vechile ? vechile.model.toUpperCase() : "No Car"}
                        </b>
                      </Space>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Space>
                      {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                        (d) => (
                          <Typography.Text
                            style={{ fontSize: 10, color: Colors.darkGrey }}
                          >
                            {d}
                          </Typography.Text>
                        )
                      )}
                    </Space> */}
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
      </Card>
      <Comments
        addCommentForm={withCommentForm}
        onCommentSubmit={addComment}
        visible={commentsShow}
        comments={comments}
      />
      <Collapse
        style={{ borderLeftWidth: 10, borderLeftColor: Colors.blue2 }}
        accordion
        expandIconPosition="right"
      >
        <Collapse.Panel header="Licence Information">
          <Descriptions>
            <Descriptions.Item label="Name">
              <b style={{ color: Colors.veryDarkGrey }}>
                {instructor && `${instructor.firstName} ${instructor.lastName}`}
              </b>
            </Descriptions.Item>
            <Descriptions.Item label="Licence Type">
              <b style={{ color: Colors.veryDarkGrey }}>
                {instructor && instructor.licenseType}
              </b>
            </Descriptions.Item>
            <Descriptions.Item label="Exprience Year">
              <b style={{ color: Colors.veryDarkGrey }}>
                {instructor && instructor.yearDriven}
              </b>
            </Descriptions.Item>
            <Descriptions.Item label="Expire Date">
              <b style={{ color: Colors.veryDarkGrey }}>
                {instructor &&
                  moment(instructor.licenseExpiryDate).format("MMM-DD-YYYY")}
              </b>
            </Descriptions.Item>
            <Descriptions.Item label="Issue Date">
              <b style={{ color: Colors.veryDarkGrey }}>
                {instructor &&
                  moment(instructor.licenseIssuedDate).format("MMM-DD-YYYY")}
              </b>
            </Descriptions.Item>
          </Descriptions>
        </Collapse.Panel>
        {vechile && (
          <Collapse.Panel header="Car Information">
            <Descriptions>
              <Descriptions.Item label="Gear Type">
                <b style={{ color: Colors.veryDarkGrey }}>
                  {vechile && vechile.gearType && vechile.gearType}
                </b>
              </Descriptions.Item>
              <Descriptions.Item label="Car Model">
                <b style={{ color: Colors.veryDarkGrey }}>
                  {vechile && vechile.model && vechile.model}
                </b>
              </Descriptions.Item>
              <Descriptions.Item label="Plate number">
                <b style={{ color: Colors.veryDarkGrey }}>
                  {vechile && vechile.plate && vechile.plate}
                </b>
              </Descriptions.Item>
              <Descriptions.Item label="Model Year">
                <b style={{ color: Colors.veryDarkGrey }}>
                  {vechile && moment(vechile.year).format("YYYY")}
                </b>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Year Driven">
              <b style={{ color: Colors.veryDarkGrey }}>
                {instructor && instructor.yearDriven}{" "}
              </b>
            </Descriptions.Item> */}
            </Descriptions>
          </Collapse.Panel>
        )}
      </Collapse>
    </Spin>
  );
}

export default Instructor;
