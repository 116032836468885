import React from "react";
import { Tabs, Card, Row, Col } from "antd";
import InstructorStudents from "../Instructors/InstructorStudents";
import Enrollment from "../Enrollment/Enrollment";
import InstructorSchedule from "../InstructorSchedule/InstructorSchedule";
import InstructorBasicInfo from "../Instructors/InstructorBasicInfo";
import InstructorHeader from "../Instructors/InstructorHeader";

const { TabPane } = Tabs;
function InstructorContent({ onLogOut, authToken }) {
  return (
    <Card>
      <InstructorHeader onLogOut={onLogOut} authToken={authToken} />

      <Tabs>
        <TabPane tab="Instructor" key="1">
          <InstructorBasicInfo onLogOut={onLogOut} authToken={authToken} />
        </TabPane>
        {/* <TabPane tab="Current Scheduled Students" key="2">
          <InstructorStudents onLogOut={onLogOut} authToken={authToken} />
        </TabPane> */}
        <TabPane tab="Completed Periods" key="3">
          <Enrollment
            filterStatus="completed"
            onLogOut={onLogOut}
            authToken={authToken}
          />
        </TabPane>
        <TabPane tab="Scheduled Periods" key="4">
          <Enrollment
            filterStatus="scheduled"
            onLogOut={onLogOut}
            authToken={authToken}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
}

export default InstructorContent;
