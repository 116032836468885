import React, { useEffect, useState } from "react";
import {
  List,
  Card,
  Typography,
  Button,
  Avatar,
  Divider,
  message,
  Spin,
  notification,
} from "antd";
import Colors from "../../../constants/Colors";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import InstructorNotification from "../../../Instructor/component/InstructorNotification/InstructorNotifications";
import postNotification from "../../../Instructor/component/InstructorNotification/InstructorQueries/PostInstructorNotification";
import getCurrentDate from "../../../component/GetCurrentDate/getCurrentDate";
import lemmajAxios from "../../../others/apiConfig";
import { useHistory } from "react-router";
import Messages from "../../../others/Messages";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import Routes from "../../Routes";
import { NavLink } from "react-router-dom";
import { Message } from "@material-ui/icons";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function LeavePending({
  authToken = null,
  onLogout = () => {},
  onUpdate = () => null,
}) {
  const [fetching, setFetching] = useState(true);
  const [pendingLeaveRequests, setPendingLeaveRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [instructor, setInstructor] = useState({});
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const [ModalVisiblityObj, setIsModalVisible] = useState({});

  const showModal = (id, visible) => {
    const newModalVisiblityObj = { ...ModalVisiblityObj };
    newModalVisiblityObj[id] = visible;
    setIsModalVisible(newModalVisiblityObj);
  };

  const sendNotification = (approved, rejected, instructorId) => {
    const notificationData = {
      message: approved
        ? Messages.LEAVE_REQUESTE_ACCEPT
        : rejected
        ? Messages.LEAVE_REQUESTE_REJECT
        : "",
      instructorId: instructorId,
      isSeen: false,
    };
    postNotification(notificationData, axiosOption);
  };

  const handleLeaveRequest = (
    approved = false,
    rejected = false,
    id = null
  ) => {
    const data = {
      isApproved: approved,
      isRejected: rejected,
    };
    const respond_message = approved
      ? Messages.LEAVE_REQUESTE_ACCEPT
      : rejected
      ? Messages.LEAVE_REQUESTE_REJECT
      : "";
    // setInstructor("filter[where][instructorId]=")
    setProcessing(true);
    lemmajAxios
      .patch(`/Leaves/${id}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setProcessing(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error("Something went wrong");
          return;
        }
        message.success(respond_message);
        onUpdate();
        fetchData();
        sendNotification(approved, rejected, res.data.instructorId);

        // lemmajAxios
        // .post('/Notifications', data, {
        //   params: {
        //     access_token: authToken,
        //   },
        //   ...axiosOption,
        // })
        // .then((res) => {
        //   if(res.data.error && res.data.error.statusCode === 401) {
        //     onLogout();
        //     return;
        //   }
        //   if(res.data.error && res.data.error.statusCode === 404) {
        //     message.error(Messages.ITEM_NOT_FOUND);
        //     return;
        //   }
        //   if(res.data.error) {
        //     message.error("Somthing went wrong");
        //     return;
        //   }

        // })
        // .catch((err) => {})
        // sendNotification();
      })
      .catch((err) => {
        setProcessing(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  const handleOk = (id = null) => {
    const approved = true;
    const rejected = false;
    handleLeaveRequest(approved, rejected, id);
    showModal(id, false);
  };

  const handleCancel = (id = null) => {
    const approved = false;
    const rejected = true;
    handleLeaveRequest(approved, rejected, id);
    showModal(id, false);
  };

  const getName = (firstName = "", lastName = "") => {
    return `${firstName} ${lastName}`;
  };

  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      .get("/Leaves/getLeavesOfAnInstructorWithBasicInfo", {
        params: {
          access_token: authToken,
          "filter[where][isApproved]": false,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error("Something went wrong");
          return;
        }
        const unHandledLeaves = res.data.filter((leave) => {
          return !leave.Leave.isApproved && !leave.Leave.isRejected;
        });
        setPendingLeaveRequests(unHandledLeaves);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchData();
    // setFetching(false);
  }, []);

  const handleSearchTerm = (term) => {
    setSearchTerm(term);
  };

  let filteredPendings =
    !searchTerm || searchTerm === ""
      ? pendingLeaveRequests
      : pendingLeaveRequests.filter((i) => {
          return `${i.LemmajUser.firstName.toLowerCase()} ${i.LemmajUser.lastName.toLowerCase()}`
            .trim()
            .includes(searchTerm.toLowerCase().trim());
        });

  return (
    <Spin spinning={processing} tip="Processing">
      <Card loading={fetching} hoverable style={{ borderRadius: 16 }}>
        <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
          Leave Requests
        </Typography.Title>
        <Divider />
        <div style={{ marginBottom: 20 }}>
          <AppInputSearch onSearch={handleSearchTerm} placeholder="Search" />
        </div>
        <List
          header={
            <Typography.Text style={{ fontSize: 15, color: Colors.darkGrey }}>
              {filteredPendings.length} leave requests
            </Typography.Text>
          }
          pagination={{ pageSize: 15 }}
          dataSource={filteredPendings}
          renderItem={(leaveRequest) => {
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src="../../mosh.jpg" />}
                  title={
                    <NavLink
                      to={`${Routes.INSTRUCTOR_DETAIL}?id=${leaveRequest.Leave.instructorId}`}
                    >
                      {getName(
                        leaveRequest.LemmajUser.firstName,
                        leaveRequest.LemmajUser.lastName
                      )}
                    </NavLink>
                  }
                  description={moment(leaveRequest.Leave.createdDate).format(
                    "DD-MMM-YYYY"
                  )}
                />
                <Button
                  onClick={() => showModal(leaveRequest.Leave.id, true)}
                  type="primary"
                  style={{
                    background: Colors.blue2,
                    borderColor: Colors.blue2,
                  }}
                >
                  View Request
                </Button>
                <Modal
                  onCancel={() => showModal(leaveRequest.Leave.id, false)}
                  footer={[
                    <Button
                      loading={processing}
                      key="back"
                      onClick={() => handleCancel(leaveRequest.Leave.id)}
                    >
                      Reject
                    </Button>,
                    <Button
                      loading={processing}
                      key="submit"
                      type="primary"
                      onClick={() => handleOk(leaveRequest.Leave.id)}
                    >
                      Approve
                    </Button>,
                  ]}
                  title="Leave Request"
                  visible={ModalVisiblityObj[leaveRequest.Leave.id]}
                >
                  <p>
                    <b>Instructor Name: </b>
                    {getName(
                      leaveRequest.LemmajUser.firstName,
                      leaveRequest.LemmajUser.lastName
                    )}
                  </p>
                  <p>
                    <b>Reason: </b> {leaveRequest.Leave.reason}{" "}
                  </p>
                  <p>
                    <b>Start date: </b>
                    {moment(leaveRequest.Leave.startDate).format(
                      "DD / MMM / YYYY"
                    )}
                  </p>
                  <p>
                    <b>End date: </b>
                    {moment(leaveRequest.Leave.endDate).format(
                      "DD / MMM/ YYYY"
                    )}
                  </p>
                </Modal>
              </List.Item>
            );
          }}
        />
      </Card>
    </Spin>
  );
}

export default LeavePending;
