import React from "react";
import { Menu } from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
  CarOutlined,
  ProfileOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { NavLink, useHistory } from "react-router-dom";
import Colors from "../../../constants/Colors";
import Routes from "../../Routes";

function SiderMenu(props) {
  const history = useHistory();
  return (
    <Menu
      onSelect={props.menuCliked}
      theme="dark"
      mode="inline"
      style={{ background: Colors.blue2 }}
      defaultSelectedKeys={["1"]}
    >
      <Menu.Item
        style={{
          color: Colors.white,
          background:
            history.location.pathname === Routes.TODAY_SCHEDULE
              ? Colors.blue3
              : Colors.blue2,
        }}
        key="1"
        icon={<HomeOutlined />}
      >
        <NavLink style={{ color: Colors.white }} to={Routes.TODAY_SCHEDULE}>
          Home
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={{
          color: Colors.white,
          background:
            history.location.pathname === Routes.STUDENTS
              ? Colors.blue3
              : Colors.blue2,
        }}
        key="2"
        icon={<ProfileOutlined />}
      >
        <NavLink style={{ color: Colors.white }} to={Routes.STUDENTS}>
          Students
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={{
          color: Colors.white,
          background:
            history.location.pathname === Routes.SCHEDULE
              ? Colors.blue3
              : Colors.blue2,
        }}
        key="6"
        icon={<CalendarOutlined />}
      >
        <NavLink style={{ color: Colors.white }} to={Routes.SCHEDULE}>
          My Schedule
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={{
          color: Colors.white,
          background:
            history.location.pathname === Routes.DUTY_DAYS
              ? Colors.blue3
              : Colors.blue2,
        }}
        key="8"
        icon={<CheckSquareOutlined />}
      >
        <NavLink style={{ color: Colors.white }} to={Routes.DUTY_DAYS}>
          Duty Days
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={{
          color: Colors.white,
          background:
            history.location.pathname === Routes.ACCOUNT
              ? Colors.blue3
              : Colors.blue2,
        }}
        key="3"
        icon={<UserOutlined />}
      >
        <NavLink style={{ color: Colors.white }} to={Routes.ACCOUNT}>
          Account
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item
        style={{
          color: Colors.white,
          background:
            history.location.pathname === Routes.VECHILE
              ? Colors.blue3
              : Colors.blue2,
        }}
        key="4"
        icon={<CarOutlined />}
      >
        <NavLink style={{ color: Colors.white }} to={Routes.VECHILES}>
          My Vehicle
        </NavLink>
      </Menu.Item> */}
      <Menu.Item
        style={{
          color: Colors.white,
          background:
            history.location.pathname === Routes.PERFORMANCE
              ? Colors.blue3
              : Colors.blue2,
        }}
        key="7"
        icon={<CarOutlined />}
      >
        <NavLink style={{ color: Colors.white }} to={Routes.PERFORMANCE}>
          Performance
        </NavLink>
      </Menu.Item>
      <Menu.Item
        onClick={props.onLogout}
        style={{ color: Colors.white }}
        key="5"
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
}

export default SiderMenu;
