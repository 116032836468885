import lemmajAxios from "../../../../others/apiConfig";

export default function postNotification(notificationData, axiosOption) {
  const getUser = JSON.parse(localStorage.getItem("userAuth"));
  const authToken = getUser.id;
  return lemmajAxios
    .post("/Notifications", notificationData, {
      params: {
        access_token: authToken,
      },
      ...axiosOption,
    })
    .then((res) => {
      if (res.data.error && res.data.error.statusCode === 401) {
        return;
      }
      if (res.data.error && res.data.error.statusCode === 404) {
        return;
      }
      if (res.data.error) {
        return;
      }
    })
    .catch((err) => {});
}
