import React from "react";
import { Typography, Card } from "antd";
import Colors from "../../constants/Colors";
import { CheckCircleTwoTone } from "@ant-design/icons";

function AppTitleBoard({ title }) {
  return (
    <Card>
      <Typography.Title level={4}>{title} </Typography.Title>
      <Typography.Text type={"secondary"}>
        Status, Activated <CheckCircleTwoTone twoToneColor={Colors.green2} />{" "}
      </Typography.Text>
    </Card>
  );
}

export default AppTitleBoard;
