import React, { useState } from "react";
import {
  Form,
  Button,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Divider,
  Typography,
  message,
  Spin,
} from "antd";
import { UserOutlined, MailFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import moment from "moment";
import lemmajAxios from "../../../others/apiConfig";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function FirstStepForm({
  firstName = "",
  lastName = "",
  email = "",
  dob = moment(Date.now()),
  phoneNumber = "",
  gender,
  country,
  region,
  city,
  onSubmit,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  form.setFieldsValue({
    firstName: firstName,
    lastName,
    email,
    dob: dob,
    phoneNumber: phoneNumber,
    gender,
    country,
    region,
    city,
  });

  const onFinished = (data) => {
    const email = data.email;
    setLoading(true);
    lemmajAxios
      .get(`LemmajUsers/emailExists?email=${email}`, lemmajAxios)
      .then((res) => {
        setLoading(false);
        if (res.data === true) {
          message.warning("Email already exist, try another email please");
          return;
        }
        onSubmit(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Spin spinning={loading}>
      <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
        Basic
      </Typography.Title>
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onFinished(values);
        }}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "First Name is required" }]}
        >
          <Input addonBefore={<UserOutlined />} placeholder="First Name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: "Last Name is required" }]}
        >
          <Input addonBefore={<UserOutlined />} placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { type: "email", message: "The input is not valid Email!" },
            { required: true, message: "Email is required" },
          ]}
        >
          <Input addonBefore={<MailFilled />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phoneNumber"
          rules={[
            { required: true, message: "Phone is required" },
            () => ({
              validator(_, value) {
                const digit = Number(value);
                if (
                  Number.isNaN(digit) ||
                  value.includes("+") ||
                  value.includes(".") ||
                  !Number.isInteger(digit) ||
                  value < 0 ||
                  value.length < 8
                ) {
                  return Promise.reject("Phone must be valid");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="tel"
            maxLength={8}
            prefix={"09"}
            placeholder="Phone"
            addonBefore={<PhoneFilled />}
          />
        </Form.Item>
        {/* <Form.Item
          label="Nationality"
          name="nationality"
          rules={[{ required: true, message: "Nationality is required" }]}
        >
          <Input addonBefore={<FlagFilled />} placeholder="Nationality" />
        </Form.Item> */}
        <Form.Item
          label="Date Of Birth"
          name="dob"
          rules={[{ required: true, message: "Birth Date is required" }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="gender"
          rules={[{ required: true, message: "Select a gender" }]}
        >
          <Select placeholder="Gender">
            <Select.Option key="male">Male</Select.Option>
            <Select.Option key="female">Female</Select.Option>
          </Select>
        </Form.Item>

        <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
          Location Information
        </Typography.Title>
        <Divider />
        <Form.Item
          name="country"
          label="Country"
          rules={[{ required: true, message: "Country is required" }]}
        >
          <Input placeholder="Country" />
        </Form.Item>
        <Form.Item
          label="Region"
          name="region"
          rules={[{ required: true, message: "Select a region" }]}
        >
          <Select placeholder="Region">
            <Select.Option key="Addis Ababa City State">
              Addis Ababa City State
            </Select.Option>
            <Select.Option key="Afar">Afar</Select.Option>
            <Select.Option key="Amhara">Amhara</Select.Option>
            <Select.Option key="Benishangul Gumuz">
              Benishangul Gumuz
            </Select.Option>
            <Select.Option key=" Dire Dawa City State">
              Dire Dawa City State
            </Select.Option>
            <Select.Option key="Gambela">Gambela</Select.Option>
            <Select.Option key="Harari">Harari</Select.Option>
            <Select.Option key="Oromia">Oromia</Select.Option>
            <Select.Option key="SNNP">SNNP</Select.Option>
            <Select.Option key="Sidama">Sidama</Select.Option>
            <Select.Option key="Tigray">Tigray</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "City is required" }]}
        >
          <Input placeholder="City" />
        </Form.Item>

        <Form.Item>
          <Row justify="space-between">
            <Col xs={24} sm={8}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{
                  background: Colors.blue2,
                  borderColor: Colors.blue2,
                }}
              >
                NEXT
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default FirstStepForm;
