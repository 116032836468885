import React, { useEffect, useState } from "react";
import AppLayout from "../../../component/AppLayout/AppLayout";
import Account from "../Account/Account";
import Courses from "../Courses/Courses";
import HeaderNavs from "../HeaderNavs/HeaderNavs";
import PendingDetails from "../PendingDetails/PendingDetails";
import CreateInstructor from "../CreateInstructor/index";
import NextForm from "../CreateInstructor/NextForm";
import CreatePackage from "../CreatePackage/CreatePackage";
import Enrollment from "../Enrollment/Enrollment";
import EnrollmentCollapse from "../Enrollment/EnrollmentCollapse";
import InstructorContent from "../InstructorContent/InstructorContent";
import Instructors from "../Instructors/Instructors";
import InstructorStudents from "../Instructors/InstructorStudents";
import InstructorSchedule from "../InstructorSchedule/InstructorSchedule";
import Packages from "../Pakages/Packages";
import SiderMenu from "../siderMenu/SiderMenu";
import Students from "../Students/Students";
import CertifiedStudents from "../CertifiedStudents/CertifiedStudents";
import StudentStatus from "../StudentStatus/StudentStatus";
import { Switch, Route, useHistory } from "react-router-dom";
import Routes from "../../Routes";
import StudentDetails from "../StudentDetails/StudentDetails";
import ChangePackage from "../ChangePackage/ChangePackage";
import BasicInst from "../RegisterInstructor/BasicInst";
import LocationInst from "../RegisterInstructor/LocationInst";
import NewPasswordFormInst from "../RegisterInstructor/newPasswordFormInst";
import UpdateInstructor from "../UpdateInstructor";
import AddPackage from "../AddPackage/AddPackage";
import RestorePassword from "../RestorePassword/RestorePassword";
import PendingStudentDetails from "../PendingDetails/PendingDetails";
import Colors from "../../../constants/Colors";
import AddCourse from "../AddCourse/AddCourse";
import UpdateCourse from "../UpdateCourse/UpdateCourse";
import LeavePending from "../LeavePendings/LeavePending";
import StudentsPending from "../StudentsPending/StudentsPending";
import UpdatePackage from "../UpdatePackage/UpdatePackage";
import RestorePasswordStudent from "../RestorePassword/RestorePasswordStudent";
import RestorePasswordInstructor from "../RestorePassword/RestorePasswordInstructor";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import { message } from "antd";

import VECHILES from "../Vechile/Vehicles";
import VechileProfile from "../Vechile/VechileProfile";
import InstructorWrapper from "../Instructors/InstructorWrapper";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function LemmajAdmin(props) {
  const [admin, setAdmin] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    adminId: "",
  });

  const [fetching, setFetching] = useState(true);
  const [pendingStudentCount, setPendingStudentCount] = useState(0);
  const [pendingLeaveRequestCount, setPendingLeaveRequestCount] = useState(0);

  const fetchData = () => {
    const userData = props.userAuthData;
    lemmajAxios
      .get(`/LemmajUsers/${userData.userId}`, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        const user = res.data;

        const admin = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          adminId: user.id,
        };
        setAdmin(admin);
      })
      .catch((err) => {});
  };

  const fetchPendingData = () => {
    setFetching(true);
    const userData = props.userAuthData;
    const authToken = userData.id;
    const onLogout = props.onLogout;
    lemmajAxios
      .get("/LemmajUsers", {
        params: {
          access_token: authToken,
          "filter[where][userType]": "student",
          "filter[where][isApproved]": false,
          "filter[where][isRejected]": false,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const data = res.data.map((c) => {
          return {
            name: c.firstName + " " + c.lastName,
            pending_time: c.createdDate,
            id: c.id,
          };
        });
        setPendingStudentCount(res.data.length);

        lemmajAxios
          .get("/Leaves/count", {
            params: {
              access_token: authToken,
              "[where][isApproved]": false,
              "[where][isRejected]": false,
            },
            ...axiosOption,
          })
          .then((res) => {
            setFetching(false);
            if (res.data.error && res.data.error.statusCode === 401) {
              onLogout();
              return;
            }
            if (res.data.error && res.data.error.statusCode === 404) {
              message.error(Messages.ITEM_NOT_FOUND);
              return;
            }
            setPendingLeaveRequestCount(res.data.count);
          })
          .catch((err) => {
            setFetching(false);
            message.error(Messages.OPREATION_FAILED);
          });
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  useEffect(() => {
    const userData = props.userAuthData;
    if (
      (!userData.user ||
        !userData.id ||
        !userData.userId ||
        userData.user.userType !== "Admin",
      userData.userType !== "Admin")
    ) {
      props.onLogout();
      return;
    }
    fetchData();
    fetchPendingData();
  }, []);

  const onFetchUpdate = () => {
    fetchData();
    fetchPendingData();
  };
  const siderMenu = (
    <SiderMenu
      leavePendingCount={pendingLeaveRequestCount}
      studentPendingCount={pendingStudentCount}
      onLogout={props.onLogout}
      authToken={props.userAuthData.id}
    />
  );
  const content = (
    <Switch>
      <Route
        path={Routes.ACCOUNT}
        render={() => (
          <Account
            onUpdate={onFetchUpdate}
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
            admin={admin}
          />
        )}
      />

      <Route path={Routes.UPDATE_PACKAGES} render={() => <UpdatePackage />} />

      <Route path={Routes.ADD_PACKAGES} render={() => <AddPackage />} />

      <Route path={Routes.PACKAGES} render={() => <Packages />} />

      <Route path={Routes.UPDATE_COURSE} render={() => <UpdateCourse />} />
      <Route path={Routes.VECHILES} render={() => <VECHILES />} />
      <Route
        path={Routes.VECHILE}
        render={() => <VechileProfile authToken={props.userAuthData.id} />}
      />
      <Route
        path={Routes.ADD_COURSE}
        render={() => (
          <AddCourse
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.COURSE}
        render={() => (
          <Courses
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.RESTORE_PASSWORD_INSTRUCTOR}
        render={() => (
          <RestorePasswordInstructor
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.UPDATE_INSTRUCTOR}
        render={() => (
          <UpdateInstructor
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.INSTRUCTOR_DETAIL}
        render={() => (
          <InstructorContent
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.CREAT_INSTRUCTOR}
        render={() => (
          <CreateInstructor
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.INSTRUCTORS}
        render={() => (
          <InstructorWrapper
            userId={props.userAuthData.id}
            onLogout={props.onLogout}
            axiosOption={axiosOption}
          />
        )}
      />

      <Route
        path={Routes.LEAVE_PENDING}
        render={() => (
          <LeavePending
            onUpdate={onFetchUpdate}
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.CHANGE_PACKAGE}
        render={() => (
          <ChangePackage
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.RESTORE_PASSWORD_STUDENT}
        render={() => (
          <RestorePasswordStudent
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.STUDENT_DETAIL}
        render={() => (
          <StudentDetails
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.STUDENTS}
        render={() => (
          <Students
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />
      <Route
        path={Routes.CERTIFIED_STUDENTS}
        render={() => (
          <CertifiedStudents
            userId={props.userAuthData.id}
            onLogout={props.onLogout}
          />
        )}
      />
      <Route
        path={Routes.STUDENT_STATUS}
        render={() => (
          <StudentStatus
            userId={props.userAuthData.id}
            onLogout={props.onLogout}
            axiosOption={axiosOption}
          />
        )}
      />

      <Route
        path={Routes.STUDENT_PENDING_DETAIL}
        render={() => (
          <PendingDetails
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.STUDENTS_PENDING}
        render={() => (
          <StudentsPending
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />

      <Route
        path={Routes.BASE}
        render={() => (
          <StudentsPending
            onLogout={props.onLogout}
            authToken={props.userAuthData.id}
          />
        )}
      />
    </Switch>
  );

  return (
    <AppLayout
      headerNavItems={<HeaderNavs onLogout={props.onLogout} />}
      title={"Welcome " + `${admin.firstName} ${admin.lastName}`}
      // userProfileSiderHeader={userProfileSiderHeader}
      siderMenu={siderMenu}
      content={content}
    />
  );
}

export default LemmajAdmin;
