import React, { useState, useEffect } from "react";
import UserProfileSiderHeader from "../../../component/UserPorfilePic/UserProfilePic";
import AppLayout from "../../../component/AppLayout/AppLayout";
import SiderMenu from "../SiderMenu/SiderMenu";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import UserNotificationBar from "../UserNotificationBar/UserNotificationBar";
import Package from "../Package/Package";
import AccountForm from "../AccountForm/AccountForm";
import Profile from "../Profile/Profile";
import CourseCompletionCertificate from "../CourseCompletionCertificate/CourseCompletionCertificate";
import { Switch, Route, useHistory } from "react-router-dom";
import Routes from "../../Routes";
import InstructorSearch from "../InstructorSearch/InstrcutorSearch";
import InstructorSchedule from "../InstructorSchedule/InstructorSchedule";
import postNotification from "../../../Instructor/component/InstructorNotification/InstructorQueries/PostInstructorNotification";
import { Result, Alert, Button, Spin, message } from "antd";
import { CheckCircleFilled, LoginOutlined } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import SetInstructor from "../SetInstructor/SetInstructor";
import moment from "moment";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import Schedule from "../Schedule/Schedule";
import MyCourses from "../MyCourses/MyCourses";
import ScdeuleCalender from "../ScdeuleCalender/ScdeuleCalender";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function LemmajDriver(props) {
  const [user, setUser] = useState({
    name: "Daniel Zelalem",
    username: "@daniel",
    phone: +251923244699,
    imageSrc: "../../../mosh.jpg",
  });

  const [fetching, setFetching] = useState(true);

  const history = useHistory();

  const siderMenu = <SiderMenu onLogout={props.onLogout} />;

  const fetchData = () => {
    const userData = props.userAuthData;
    lemmajAxios
      .get(`/LemmajUsers/${userData.userId}`, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          props.onLogout();
          return;
        }
        setUser({
          name: res.data.firstName + " " + res.data.lastName,
          username: res.data.email,
          phone: res.data.phoneNumber,
          imageSrc: null,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const userData = props.userAuthData;
    if (
      (!userData.user ||
        !userData.id ||
        !userData.userId ||
        userData.user.userType !== "Driver",
      userData.userType !== "Driver")
    ) {
      props.onLogout();
      return;
    }
    setFetching(true);
    fetchData();
    setFetching(false);
  }, []);

  // const _instructor = instructor;
  const _instructor = true;

  const userProfileSiderHeader = (
    <UserProfileSiderHeader
      user={user}
      userProfileAvatar={
        <UserAvatar imagSrc={user.imageSrc} name={user.name} size={50} />
      }
    />
  );
  const headerNavItems = (
    <UserNotificationBar
      notificatinCount={2}
      name={user.name}
      imageSrc={user.imageSrc}
      username={user.username}
      onAccountClicked={() => history.push(Routes.ACCOUNT)}
      onPasswordClicked={() => history.push(Routes.ACCOUNT)}
      onUserNameClicked={() => history.push(Routes.PROFILE)}
      onLogout={props.onLogout}
    />
  );
  const content = (
    <Spin spinning={fetching}>
      <Switch>
        <Route
          path={Routes.SCEHEDULE_CALENDER}
          render={() => (
            <ScdeuleCalender
              onLogout={props.onLogout}
              userId={props.userAuthData.userId}
            />
          )}
        />
        <Route
          path={Routes.SET_INSTRUCTOR}
          render={() => (
            <SetInstructor
              onLogout={props.onLogout}
              userData={props.userAuthData}
              userType={props.userAuthData.userType}
            />
          )}
        />
        <Route
          path={Routes.INSTRCUTOR_SEARCH}
          render={() => (
            <InstructorSearch
              onLogout={props.onLogout}
              userData={props.userAuthData}
              userType={props.userAuthData.userType}
            />
          )}
        />
        <Route
          path={Routes.PROFILE}
          render={() => (
            <Profile
              onBasicUpdate={() => {
                fetchData();
              }}
              onLogout={props.onLogout}
              userData={props.userAuthData}
            />
          )}
        />
        <Route
          path={Routes.CERTIFICATE}
          render={() => <CourseCompletionCertificate onLogout = {props.onLogout} />}
        />
        <Route path={Routes.SCHEDULE} render={() => <Schedule />} />
        <Route
          path={Routes.ACCOUNT}
          render={() => (
            <AccountForm
              userData={props.userAuthData}
              onLogout={props.onLogout}
            />
          )}
        />
        <Route
          path={`${Routes.INSTRUCTOR_SCHEDULE}`}
          render={() => <InstructorSchedule userData={props.userAuthData} />}
        />
        <Route
          path={Routes.INSTRCUTOR_SEARCH}
          render={
            <Result
              icon={<CheckCircleFilled style={{ color: Colors.orange }} />}
              status="success"
              extra={[
                <Button
                  key="Goto"
                  onClick={() => {
                    history.push(Routes.INSTRUCTOR_SCHEDULE);
                  }}
                  type="primary"
                  style={{
                    background: Colors.blue2,
                    borderColor: Colors.blue2,
                  }}
                >
                  Go to my instructor
                </Button>,
              ]}
              subTitle={
                <Alert
                  icon={<CheckCircleFilled />}
                  description=" You already have selected an instructor for this package"
                  banner
                >
                  {" "}
                </Alert>
              }
            />
          }
        />

        {/* <Route
            path={Routes.SET_INSTRUCTOR}
            render={() => <Package instId={instructor.instructorId} />}
          /> */}

        {/* <Route
            path={Routes.PACKAGE}
            render={() => <MyCourses instId={instructor.instructorId} />}
          /> */}
        <Route
          path={Routes.PACKAGE}
          render={() => <Package student={props.userAuthData.user} />}
        />
        {/* <Route
            path={Routes.BASE}
            render={() => <MyCourses instId={instructor.instructorId} />}
          /> */}
        <Route
          path={Routes.BASE}
          render={() => <Package student={props.userAuthData.user} />}
        />
      </Switch>
    </Spin>
  );
  return (
    <AppLayout
      headerNavItems={headerNavItems}
      title={"Welcome " + user.name}
      userProfileSiderHeader={userProfileSiderHeader}
      siderMenu={_instructor ? siderMenu : null}
      content={content}
      siderMenuShow={true}
    />
  );
}

export default LemmajDriver;
