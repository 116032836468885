import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Form,
  Button,
  Select,
  Row,
  Col,
  Input,
  Space,
  Divider,
  InputNumber,
  Spin,
  message,
} from "antd";
import { GiftFilled, PlusOutlined } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router";
import AddComponentToPackage from "../AddComponentToPackage/AddComponentToPackage";
import Routes from "../../../Admin/Routes";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function AlterPackage({
  name = "",
  description = "",
  duration = 0,
  showAddPackage,
  onSubmit,
  authToken = null,
  onLogout = () => null,
}) {
  const [fetchingCourses, setFetchingCourses] = useState(false);
  const [fetching, setFetching] = useState(!showAddPackage);
  const [courses, setCourses] = useState([]);
  const [packageCourseIds, setPackageCourseIds] = useState([]);
  const [form] = useForm();
  const history = useHistory();

  const fillForm = (name, description, duration, price, code) => {
    form.setFieldsValue({
      name: name,
      description: description,
      duration: duration,
      price: price,
      packageCode: code,
    });
  };

  const getPackageId = () => {
    return history.location.search.slice("?id=".length);
  };

  const fetchDataAndFillData = () => {
    if (!showAddPackage) {
      const package_id = history.location.search.slice("?id=".length);
      setFetching(true);
      lemmajAxios
        .get(`/LemmajPackages/${package_id}`, {
          params: { access_token: authToken },
          ...axiosOption,
        })
        .then((res) => {
          setFetching(false);
          if (res.data.error && res.data.error.statusCode === 401) {
            onLogout();
            return;
          }
          if (res.data.error && res.data.error.statusCode === 404) {
            message.error(Messages.ITEM_NOT_FOUND);
            return;
          }
          const _package = res.data;
          fillForm(
            _package.name,
            _package.description,
            _package.length,
            _package.price,
            _package.packageCode
          );
          setPackageCourseIds(_package.courses);
        })
        .catch((err) => {
          setFetching(false);
          message.error(Messages.OPREATION_FAILED);
        });
    }
  };
  const fetchCourses = () => {
    if (showAddPackage) {
      setFetchingCourses(true);
      lemmajAxios
        .get("/Courses", axiosOption)
        .then((res) => {
          setFetchingCourses(false);
          const data = res.data.map((c) => {
            return { name: c.name, duration: c.length, id: c.id };
          });
          setCourses(data);
        })
        .catch((err) => {
          setFetchingCourses(false);
          message.error(Messages.OPREATION_FAILED);
        });
    }
  };

  useEffect(() => {
    if (!showAddPackage) {
      fetchDataAndFillData();
    }
    fetchCourses();
  }, []);

  const totalLength = (courseIds = []) => {
    if (courseIds.length <= 0) {
      return 0;
    }
    let total = 0;
    const filtered = courses.filter((c) => {
      return courseIds.includes(c.id);
    });
    filtered.forEach((c) => {
      total += c.duration;
    });
    return total;
  };
  const onSubmitPackage = (values) => {
    //console.log(values);
    // lemmajAxios;
    let data = {
      name: values.name,
      duration: { length: totalLength(values.courses), unit: "min" },
      description: values.description,
      createdDate: new Date(Date.now()),
      courses: values.courses,
      language: "en",
      price: values.price,
      packageCode: values.packageCode,
    };

    //console.log(data);
    if (showAddPackage) {
      lemmajAxios
        .post("/LemmajPackages", data, {
          params: {
            access_token: authToken,
          },
          ...axiosOption,
        })
        .then((res) => {
          if (res.data.error && res.data.error.statusCode === 401) {
            onLogout();
            return;
          }
          message.success(Messages.PACKAGE_CREATED);
          history.replace(Routes.PACKAGES);
        })
        .catch((err) => {
          message.error(Messages.OPREATION_FAILED);
        });
    } else {
      data = {
        name: values.name,
        description: values.description,
        language: "en",
        price: values.price,
        packageCode: values.code,
      };
      const package_id = history.location.search.slice("?id=".length);

      lemmajAxios
        .patch(`/LemmajPackages/${package_id}`, data, {
          params: {
            access_token: authToken,
          },
          ...axiosOption,
        })
        .then((res) => {
          if (res.data.error && res.data.error.statusCode === 401) {
            onLogout();
            return;
          }
          if (res.data.error && res.data.error.statusCode === 404) {
            message.error(Messages.ITEM_NOT_FOUND);
            return;
          }
          message.success(Messages.PACKAGE_UPDATED);
          history.replace(Routes.PACKAGES);
        })
        .catch((err) => {
          message.error(Messages.OPREATION_FAILED);
        });
    }

    return;
  };
  return (
    <>
      <Card loading={fetching}>
        <Spin spinning={fetchingCourses}>
          {showAddPackage && (
            <Typography.Title level={4} style={{ color: Colors.orange }}>
              <Space>
                <GiftFilled />
                Create Package
              </Space>
            </Typography.Title>
          )}
          {!showAddPackage && (
            <Typography.Title level={4} style={{ color: Colors.orange }}>
              <Space>
                <GiftFilled />
                Update Package
              </Space>
            </Typography.Title>
          )}
          <Divider />
          <Form form={form} onFinish={onSubmitPackage} layout="vertical">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label="Price"
              name="price"
              rules={[
                { required: true, message: "Price is required" },
                // { min: 0, message: "Price must be greater than 0" },
                {
                  type: "number",
                  message: "Input valid price",
                },
              ]}
            >
              <InputNumber placeholder="Price" />
            </Form.Item>
            <Form.Item
              label="Package Code"
              name="packageCode"
              rules={[
                { required: true, message: "Package Code is required" },
                {
                  type: "string",
                  message: "Input Package Code",
                },
              ]}
            >
              <Input placeholder="Package Code" />
            </Form.Item>
            {showAddPackage && (
              <Form.Item
                name="courses"
                label="Courses"
                rules={[
                  {
                    required: true,
                    message: "You have to select atleast one course",
                  },
                ]}
              >
                <Select mode="multiple" placeholder="Courses">
                  {courses.map((c) => {
                    return <Select.Option key={c.id}>{c.name}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              rules={[{ required: true, message: "Description is required" }]}
              name="description"
              label="Description"
            >
              <Input.TextArea maxLength={70} />
            </Form.Item>
            <Form.Item>
              <Row justify="start">
                <Col xs={24} sm={8}>
                  {showAddPackage && (
                    <Button
                      icon={<PlusOutlined />}
                      style={{
                        background: Colors.blue2,
                        borderRadius: Colors.blue2,
                      }}
                      block
                      type="primary"
                      htmlType="submit"
                    >
                      Add Package
                    </Button>
                  )}
                  {!showAddPackage && (
                    <Button
                      style={{
                        background: Colors.blue2,
                        borderRadius: Colors.blue2,
                      }}
                      block
                      type="primary"
                      htmlType="submit"
                    >
                      Update Package
                    </Button>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
      <Divider />
      {!showAddPackage && (
        <Card>
          <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
            Package Courses
          </Typography.Title>
          <AddComponentToPackage
            packageCourseIDs={packageCourseIds}
            packageId={getPackageId()}
          />
        </Card>
      )}
    </>
  );
}

export default AlterPackage;
