import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Divider,
  Input,
  DatePicker,
  Select,
  Button,
  Row,
  Col,
  Typography,
  message,
  Spin,
} from "antd";
import ProfilePicInput from "./ProfilePicInput";
import { UserOutlined, MailFilled, FlagFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import PhoneNumberFormItem from "../../../component/PhoneNumberFormItem/PhoneNumberFormItem";
import lemmajAxios from "../../../others/apiConfig";
import moment from "moment";
import Messages from "../../../others/Messages";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function Basic({
  onLogout = () => null,
  userData = null,
  onUpdate = () => null,
}) {
  const [fetching, setFetching] = useState(true);
  const [updating, setUpdateing] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (
      (!userData.user ||
        !userData.id ||
        !userData.userId ||
        userData.user.userType !== "Driver",
      userData.userType !== "Driver")
    ) {
      onLogout();
      return;
    }
    lemmajAxios
      .get(`/LemmajUsers/${userData.userId}`, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        const user = res.data;
        form.setFieldsValue({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          dob: moment(user.dob),
          gender: user.gender.toLowerCase() === "male" ? "male" : "female",
          phoneNumber: user.phoneNumber.slice(2),
        });
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
      });
  }, []);

  const updateBasic = (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      dob: values.dob.toString(),
      gender: values.gender,
      phoneNumber: `09${values.phoneNumber}`,
    };
    setUpdateing(true);
    lemmajAxios
      .patch(`/LemmajUsers/${userData.userId}`, data, {
        params: { access_token: userData.id },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error) {
          message.error(res.data.error.message);
          onLogout();
          return;
        }
        onUpdate();
        message.success(Messages.INFO_UPDATED);
        setUpdateing(false);
      })
      .catch((err) => {
        message.success(Messages.OPREATION_FAILED);
        setUpdateing(false);
      });
  };
  return (
    <Spin spinning={updating}>
      {/* <Card style={{ marginBottom: 20 }}>
        <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
          Profile Picture
        </Typography.Title>
        <ProfilePicInput />
      </Card> */}
      <Card loading={fetching}>
        <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
          Basic
        </Typography.Title>
        <Form onFinish={updateBasic} form={form} layout="vertical">
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "First Name is required" }]}
          >
            <Input addonBefore={<UserOutlined />} placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Last Name is required" }]}
          >
            <Input addonBefore={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input addonBefore={<MailFilled />} placeholder="Email" />
          </Form.Item>
          <PhoneNumberFormItem />
          <Form.Item
            label="Date Of Birth"
            name="dob"
            rules={[{ required: true, message: "Birth Date is required" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Select a gender" }]}
          >
            <Select placeholder="Gender">
              <Select.Option key="male">Male</Select.Option>
              <Select.Option key="female">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  loading={updating}
                  style={{
                    background: Colors.blue2,
                    borderRadius: Colors.blue2,
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default Basic;
