import React from "react";
import { Card, Typography, Row, Col, Progress } from "antd";
import Colors from "../../../constants/Colors";
import { GiftFilled } from "@ant-design/icons";

function PackageHeader({ periods = [], progress = 0 }) {
  const getPeriodStausCount = () => {
    let finished = 0;
    let scheduled = 0;
    periods.forEach((p) => {
      if (p.period && p.period.periodStatus === "completed") {
        finished += 1;
      }
      if (p.period && p.period.periodStatus === "scheduled") {
        scheduled += 1;
      }
    });
    return { finished: finished, scheduled: scheduled };
  };
  const statusCount = getPeriodStausCount();
  return (
    <>
      <Progress percent={progress} />
      <Card
        hoverable
        style={{
          borderLeftWidth: 10,
          borderLeftColor: Colors.blue3,
          borderRadius: 16,
          marginBottom: 20,
        }}
      >
        <Typography.Title level={3} style={{ color: Colors.orange }}>
          <GiftFilled style={{ marginRight: 5 }} />
          Intermediate Package
        </Typography.Title>
        <Row
          align="middle"
          justify="space-between"
          style={{
            backgroundColor: Colors.veryLightGrey,
            padding: 15,
            borderRadius: 15,
          }}
        >
          <Col>
            <Row gutter={(8, 8)} justify="space-between">
              <Col>
                <Typography.Text style={{ fontWeight: "bold" }}>
                  Enrollement Date:{" "}
                </Typography.Text>
              </Col>
              <Col>
                {" "}
                <Typography.Text> 10-19-2020 </Typography.Text>{" "}
              </Col>
            </Row>
            <Row gutter={(8, 8)}>
              <Col>
                {" "}
                <Typography.Text style={{ fontWeight: "bold" }}>
                  {" "}
                  Total Periods:{" "}
                </Typography.Text>{" "}
              </Col>
              <Col>
                {" "}
                <Typography.Text> {periods.length} </Typography.Text>{" "}
              </Col>
            </Row>
            <Row gutter={(8, 8)}>
              <Col>
                {" "}
                <Typography.Text style={{ fontWeight: "bold" }}>
                  Completed Periods:{" "}
                </Typography.Text>{" "}
              </Col>
              <Col>
                {" "}
                <Typography.Text>{statusCount.finished}</Typography.Text>{" "}
              </Col>
            </Row>
            <Row gutter={(8, 8)}>
              <Col>
                {" "}
                <Typography.Text style={{ fontWeight: "bold" }}>
                  Scheduled Periods:{" "}
                </Typography.Text>{" "}
              </Col>
              <Col>
                {" "}
                <Typography.Text>
                  {" "}
                  {statusCount.scheduled}{" "}
                </Typography.Text>{" "}
              </Col>
            </Row>
          </Col>
          <Col>
            <Progress width={65} percent={progress} type="circle" />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default PackageHeader;
