import React from "react";
import { Typography, Form, Input, Button, Row, Col } from "antd";
import { LockFilled } from "@ant-design/icons";
import Colors from "../../constants/Colors";
function NewPasswordForm({ onSubmit, onCancel, password = "" }) {
  const passwordFormRef = React.createRef();
  const [form] = Form.useForm();
  form.setFieldsValue({
    newPassword: password,
    confirmPassword: password,
  });
  return (
    <>
      <Typography.Title
        level={4}
        style={{
          color: Colors.mediumGrey,
        }}
      >
        {" "}
        Password
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        ref={passwordFormRef}
        onFinish={(v) => {
          onSubmit(v);
        }}
      >
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: "New password is required" },
            () => ({
              validator(rule, value) {
                if (value && value.length < 6) {
                  return Promise.reject("Minimum password length allowed is 6");
                }
                if (value && value.length > 35) {
                  return Promise.reject(
                    "Maximum password length allowed is 35"
                  );
                }
                //   const passdReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[ \~\`\!\@\#\$\%\^\&\*\(\)\_\+\=\-\{\}\[\]\|\\\"\'\:\;\?\>\<\.\,\/])/;
                //   if (!value.match(passdReg)) {
                //     return Promise.reject(
                //       "Password must contian an uppercase a lowercase a digit and a special character"
                //     );
                //   }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            addonBefore={<LockFilled />}
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          rules={[
            { required: true, message: "Confirm password field is required" },
            () => ({
              validator(rule, value) {
                if (
                  passwordFormRef.current.getFieldValue("newPassword") != value
                ) {
                  return Promise.reject("Password does not match");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            addonBefore={<LockFilled />}
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between">
            <Col span={10}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{
                  borderRadius: 16,
                  background: Colors.blue2,
                  borderColor: Colors.blue2,
                }}
              >
                Next
              </Button>
            </Col>
            <Col span={10}>
              <Button
                onClick={onCancel}
                block
                type="primary"
                style={{
                  background: Colors.orange,
                  borderColor: Colors.orange,
                  borderRadius: 16,
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
}

export default NewPasswordForm;
