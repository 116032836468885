import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Modal,
  Space,
  Input,
  InputNumber,
  DatePicker,
  Select,
  Button,
  Spin,
  Row,
  Col,
  Typography,
  message,
  Alert,
} from "antd";
import { UserOutlined, MailFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../constants/Colors";
import moment from "moment";
import firebase from "../../others/firebaseConfig";
import Messages from "../../others/Messages";
import { useHistory } from "react-router";
import lemmajAxios from "../../others/apiConfig";
import Routes from "../../Routes";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function PhoneVerify(props) {
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [tip_message, setTipMessage] = useState("");
  const [alert_message, setMessage] = useState("");
  useEffect(() => {
    setUpRecaptcha();
  }, []);

  const data = history.location.state ? history.location.state.data : null;

  //   if (!data) {
  //     history.replace(Routes.BASE);
  //   }
  const confimOTP = window.confirmationResult;

  //   if (!confimOTP) {
  //     history.replace(Routes.BASE);
  //   }
  const setUpRecaptcha = async () => {
    window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier(
      "captcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // captcha resolved
          // sendPhoneVerification();
        },
      }
    );
  };
  const sendPhoneVerification = async (phoneWithShortCode) => {
    if (!window.recaptchaVerifier || undefined) {
      setUpRecaptcha();
    }
    await firebase
      .auth()
      .signInWithPhoneNumber(phoneWithShortCode, window.recaptchaVerifier)
      .then(async (confirmationResult) => {
        localStorage.setItem("OTP", JSON.stringify(confirmationResult));
        window.confirmationResult = confirmationResult;
        setMessage("Verification code has been sent");
      })
      .catch((error) => {
        setMessage(error.message);

        console.error(error);
      });
  };

  const resendCode = async () => {
    const phoneWithShortCode = `+251${data.phoneNumber.slice(1)}`;

    setSubmitting(true);
    setTipMessage("Resending code");
    await sendPhoneVerification(phoneWithShortCode);
    setSubmitting(false);
    setTipMessage(false);
  };

  const verifyOtp = (confimOTP, code) => {
    setSubmitting(true);
    setTipMessage("Verifying...");
    confimOTP
      .confirm(code)
      .then((result) => {
        setSubmitting(false);
        setTipMessage("");
        onFinalRegister();
      })
      .catch((error) => {
        setSubmitting(false);
        setTipMessage("");
        message.error(Messages.PHONE_NOT_VERIFIED);
        setMessage(Messages.PHONE_NOT_VERIFIED);
      });
  };

  const onDeleteImage = (image) => {
    let storage = firebase.storage();
    let storageRef = storage.ref();
    let deleteRef = storageRef.child("license/" + image);
    deleteRef
      .delete()
      .then(() => {})
      .catch((err) => {});
  };

  const onFinalRegister = () => {
    setMessage("");
    if (data.license_image) {
      const file = data.license_image.originFileObj;
      setSubmitting(true);
      setTipMessage("Registering...");

      let storage = firebase.storage();
      let storageRef = storage.ref();
      const imageFileName =
        file.name + `${String(file.lastModified)}-${String(Math.random())}`;
      let uploadTask = storageRef.child("license/" + imageFileName).put(file);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (error) => {
          setSubmitting(false);
          setTipMessage("");
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            if (url) {
              const payload = {
                firstName: data.firstName,
                lastName: data.lastName,
                dob: data.dob.toString(),
                vehicleUsed: "vehicleIDGoesHere",
                phoneNumber: data.phoneNumber,
                email: data.email,
                gender: data.gender,
                userType: "student",
                userRole: [""],
                city: data.city,
                country: data.country,
                Region: data.Region,
                licenseNumber: data.licenseNumber,
                licenseType: data.licenseType,
                licenseIssuedDate: data.licenseIssuedDate,
                licenseExpiryDate: data.licenseExpiryDate,
                gearType: data.gearType,
                licenseImageUrl: url,
                password: data.password,
                isLocked: false,
                packageId: "6072bcaa73a4230015b4caae",
              };
              setSubmitting(true);
              setTipMessage("Registering...");

              lemmajAxios
                .post("/LemmajUsers", payload, axiosOption)
                .then((res) => {
                  if (res.data.error) {
                    // delete pic
                    onDeleteImage(imageFileName);
                    if (res.data.error.details.messages.email) {
                      message.error(res.data.error.details.messages.email[0]);
                      setMessage(res.data.error.details.messages.email[0]);

                      setSubmitting(false);
                      return;
                    }
                    message.error(res.data.error.message);
                    setMessage(res.data.error.message);
                    setSubmitting(false);
                    setTipMessage("");

                    return;
                  }
                  message.success(Messages.REG_SUCCESS, 6);
                  setMessage(Messages.REG_SUCCESS);
                  setSubmitting(false);
                  history.replace(Routes.LOGIN);
                  return;
                })
                .catch((err) => {
                  onDeleteImage(imageFileName);
                  // delete pic
                  message.error(Messages.REG_FAILED);
                  setMessage(Messages.REG_SUCCESS);
                  setSubmitting(false);
                  setTipMessage("");
                });
            }
          });
        }
      );
      // if this is successull
    }
  };
  return (
    <div style={{ background: Colors.veryLightGrey, minHeight: "100vh" }}>
      <div>
        <Row
          style={{
            width: "100%",
          }}
        >
          <Col
            xl={{ offset: 7, span: 12 }}
            lg={{ offset: 7, span: 12 }}
            md={{ offset: 7, span: 13 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            style={{ marginTop: 100 }}
          >
            {alert_message && (
              <Alert banner description={alert_message} closable />
            )}

            <Spin spinning={submitting} tip={tip_message}>
              <Card hoverable style={{ marginBottom: 20, borderRadius: 16 }}>
                <Typography.Title
                  level={4}
                  style={{ color: Colors.mediumGrey }}
                >
                  Verify Your Phone
                </Typography.Title>
                <Form
                  // form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    verifyOtp(confimOTP, values.confirm_code);
                    // onFinalRegister();
                    // sendPhoneVerification(values);
                    // form.setFieldsValue({
                    //   firstName: values.firstName,
                    //   lastName: values.lastName,
                    //   email: values.email,
                    //   dob: values.dob,
                    //   phoneNumber: values.phoneNumber,
                    //   gender: values.gender,
                    // });
                    // setTimeout(Math.random() > 0.5, 1000);
                  }}
                >
                  <div
                    id="captcha-container"
                    style={{ display: "hidden" }}
                  ></div>
                  <Form.Item
                    label="Enter Verification Code"
                    name="confirm_code"
                    rules={[
                      { required: true, message: "Verification Code required" },
                    ]}
                  >
                    <Input addonBefore={<UserOutlined />} placeholder="CODE" />
                  </Form.Item>
                  <Form.Item>
                    <Row justify="space-around">
                      <Col span={10}>
                        <Button
                          block
                          type="primary"
                          htmlType="submit"
                          style={{
                            borderRadius: 16,
                            background: Colors.blue2,
                            borderColor: Colors.blue2,
                          }}
                        >
                          Verify
                        </Button>
                      </Col>

                      <Col span={10}>
                        <Button
                          block
                          type="primary"
                          onClick={resendCode}
                          style={{
                            borderRadius: 16,
                            background: Colors.orange,
                            borderColor: Colors.orange,
                          }}
                        >
                          Resend Code
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PhoneVerify;
