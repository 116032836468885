import React from "react";
import { Image } from 'antd';
import icon from '../../Assets/logo.png';

function Logo(props) {
  return (<Image 
      width={200}
      src={icon}
      preview={false}
    />);
}

export default Logo;
