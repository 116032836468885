import React from "react";
import { Card, Collapse, Typograph, Row, Col, Typography } from "antd";
import { GiftFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Routes from "../../Routes";

function EnrollmentContentHeader({
  instructor,
  _package,
  student,
  period,
  startDate,
  portal_view = 0,
}) {
  return (
    <Row
      align="middle"
      justify="space-between"
      style={{
        backgroundColor: Colors.veryLightGrey,
        padding: 15,
        borderRadius: 15,
      }}
    >
      <Col>
        <Row gutter={(8, 8)} justify="space-between">
          <Col>
            <Typography.Text style={{ fontWeight: "bold" }}>
              Period Date:{" "}
            </Typography.Text>
          </Col>
          <Col>
            {" "}
            <Typography.Text>
              {" "}
              {moment(startDate).format("DD-MMM-YYYY")}{" "}
            </Typography.Text>{" "}
          </Col>
        </Row>
        <Row gutter={(8, 8)} justify="space-between">
          <Col>
            <Typography.Text style={{ fontWeight: "bold" }}>
              Period Start Time:{" "}
            </Typography.Text>
          </Col>
          <Col>
            {" "}
            <Typography.Text>
              {" "}
              {moment(new Date(startDate), "hh:mm a").format("hh:mm a")}{" "}
            </Typography.Text>{" "}
          </Col>
        </Row>

        <Row gutter={(8, 8)}>
          <Col>
            {" "}
            <Typography.Text style={{ fontWeight: "bold" }}>
              Student:{" "}
            </Typography.Text>{" "}
          </Col>
          <Col>
            {" "}
            <Typography.Text>
              {portal_view === 0 ? (
                <NavLink to={`${Routes.STUDENT_DETAIL}?id=${student.id}`}>
                  <a>{`${student.firstName} ${student.lastName}`}</a>
                </NavLink>
              ) : (
                <b>{`${student.firstName} ${student.lastName}`}</b>
              )}
            </Typography.Text>{" "}
          </Col>
        </Row>
        <Row gutter={(8, 8)}>
          <Col>
            {" "}
            <Typography.Text style={{ fontWeight: "bold" }}>
              Package:{" "}
            </Typography.Text>{" "}
          </Col>
          <Col>
            {" "}
            <Typography.Text>
              {portal_view === 0 ? (
                <NavLink to={`${Routes.UPDATE_PACKAGES}?id=${_package.id}`}>
                  <a>{_package.name}</a>
                </NavLink>
              ) : (
                <b>{_package.name}</b>
              )}
            </Typography.Text>{" "}
          </Col>
        </Row>

        <Row gutter={(8, 8)}>
          <Col>
            {" "}
            <Typography.Text style={{ fontWeight: "bold" }}>
              Status:{" "}
            </Typography.Text>{" "}
          </Col>
          <Col>
            {" "}
            <Typography.Text> {period.periodStatus} </Typography.Text>{" "}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default EnrollmentContentHeader;
