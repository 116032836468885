import React, { useState } from "react";
import {
  Form,
  Card,
  Divider,
  Modal,
  Space,
  Input,
  InputNumber,
  DatePicker,
  Select,
  Button,
  Spin,
  Row,
  Col,
  Typography,
  message,
} from "antd";
import { UserOutlined, MailFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../constants/Colors";
import moment from "moment";
import firebase from "../../others/firebaseConfig";
import Messages from "../../others/Messages";
import lemmajAxios from "../../others/apiConfig";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function Basic({
  onFormSubmit,
  firstName = "",
  lastName = "",
  email = "",
  gender = "",
  phoneNumber = "",
  dob = moment(Date.now()),
}) {
  // PHONE VERIFICATION
  const { confirm } = Modal;
  const [confimation, setConfimation] = useState(null);
  const [verified, setVerified] = useState(false);
  const [loading, setLoding] = useState(false);
  const [confirmOtpValue, setConfirmOtpValue] = useState("asghas");
  const phoneShortCode = "+2519";
  // const setUpRecaptcha = () => {
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //     "captcha-container",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         // captcha resolved
  //         // sendPhoneVerification();
  //       },
  //     }
  //   );
  // };
  // // setUpRecaptcha();
  // const sendPhoneVerification = (values) => {
  //   setLoding(true);
  //   if (!window.recaptchaVerifier || undefined) {
  //     setUpRecaptcha();
  //   }
  //   const phoneWithShortCode = phoneShortCode + values.phoneNumber;
  //   firebase
  //     .auth()
  //     .signInWithPhoneNumber(phoneWithShortCode, window.recaptchaVerifier)
  //     .then((confirmationResult) => {
  //       setLoding(false);
  //       window.confirmationResult = confirmationResult;
  //       setConfimation(confirmationResult);
  //       showPromiseConfirm(values);
  //     })
  //     .catch((error) => {
  //       setLoding(false);
  //       console.error(error);
  //     });
  // };

  const onSubmit = (data) => {
    const email = data.email;
    setLoding(true);
    lemmajAxios
      .get(`LemmajUsers/emailExists?email=${email}`, lemmajAxios)
      .then((res) => {
        setLoding(false);
        if (res.data === true) {
          message.warning("Email already exist, try another email please");
          return;
        }
        onFormSubmit(data);
      })
      .catch((err) => {
        setLoding(false);
      });
  };

  const [form] = Form.useForm();
  form.setFieldsValue({
    firstName: firstName,
    lastName,
    email,
    dob: dob,
    phoneNumber: phoneNumber,
    gender,
  });
  return (
    <Spin spinning={loading} tip="checking...">
      <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
        Basic
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onSubmit({ ...values, phoneNumber: values.phoneNumber });
        }}
      >
        <div id="captcha-container"></div>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "First Name is required" },
          {
            pattern: /^[a-zA-Z]+$/,
            message: "First Name must be alphabets only",
          }]}
        >
          <Input addonBefore={<UserOutlined />} placeholder="First Name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: "Last Name is required" },
          {
            pattern: /^[a-zA-Z]+$/,
            message: "Last Name must be alphabets only",
          }]}
        >
          <Input addonBefore={<UserOutlined />} placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required" },
          {
            type: "email",
            message: "Email is not valid",
          }]}
        >
          <Input addonBefore={<MailFilled />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phoneNumber"
          rules={[
            { required: true, message: "Phone is required" },
            () => ({
              validator(_, value) {
                const digit = Number(value);
                if (
                  Number.isNaN(digit) ||
                  value.includes("+") ||
                  value.includes(".") ||
                  !Number.isInteger(digit) ||
                  value < 0 ||
                  value.length < 8
                ) {
                  return Promise.reject("Phone must be valid");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            type="tel"
            maxLength={8}
            prefix={"09"}
            placeholder="Phone"
            addonBefore={<PhoneFilled />}
          />
        </Form.Item>
        {/* <Spin spinning={loading} /> */}
        {/* <Form.Item
          label="Nationality"
          name="nationality"
          rules={[{ required: true, message: "Nationality is required" }]}
        >
          <Input addonBefore={<FlagFilled />} placeholder="Nationality" />
        </Form.Item> */}
        <Form.Item
          label="Date Of Birth"
          name="dob"
          rules={[{ required: true, message: "Birth Date is required" }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="gender"
          rules={[{ required: true, message: "Select a gender" }]}
        >
          <Select placeholder="Gender">
            <Select.Option key="male">Male</Select.Option>
            <Select.Option key="female">Female</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Row justify="start">
            <Col span={10}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{
                  borderRadius: 16,
                  background: Colors.blue2,
                  borderColor: Colors.blue2,
                }}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default Basic;
