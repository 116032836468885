import React from "react";
import { List, Space, Typography, Popover, Button, Row, Col } from "antd";
import {
  PhoneOutlined,
  StarFilled,
  CarOutlined,
  MessageOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import Colors from "../../../constants/Colors";
import Comments from "../Comments/Comments";

const IconText = ({ icon, text, onClick = () => null, popContent = false }) => {
  const main = (
    <div onClick={onClick}>
      <Space>
        {icon ? icon : null}
        {text}
      </Space>
    </div>
  );
  return popContent ? (
    <Popover
      style={{ width: "50" }}
      trigger="click"
      content={popContent}
      placement="bottom"
    >
      {main}
    </Popover>
  ) : (
    main
  );
};
function Instructors({
  onClick = () => null,
  onCommentIconToggle = () => null,
  onCommentSubmit = () => null,
  onIncreaseRate = () => null,
  instructors = [],
  user,
  withComments = true,
  withStars = true,
}) {
  const handleCommentVisibleToggle = (index) => {
    onCommentIconToggle(index);
  };

  return (
    <List
      pagination={{ pageSize: 10, size: "small", total: instructors.length }}
      renderItem={(item, index) => {
        return (
          <>
            <List.Item
              onClick={() => {
                onClick(item.id);
              }}
              key={Math.random()}
              actions={[
                // <IconText
                //   icon={<StarOutlined />}
                //   text={item.star}
                //   onClick={() => onIncreaseRate(index)}
                //   key="list-vertical-star-o"
                // />,
                <IconText
                  icon={<EyeOutlined />}
                  text={"View Details"}
                  onClick={() => {
                    onClick(item.id);
                  }}
                  key="list-vertical-view-o"
                />,
                withComments && (
                  <IconText
                    icon={<MessageOutlined />}
                    popContent={false}
                    text={item.comments && item.comments.length}
                    onClick={() => handleCommentVisibleToggle(index)}
                    key="list-vertical-message"
                  />
                ),
                // <IconText
                //   icon={item.car ? <CarOutlined /> : null}
                //   key="list-vertical-car-o"
                // />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Row justify="center" align="middle">
                    <Col>
                      <UserAvatar
                        size={40}
                        imagSrc={item.imgSrc}
                        name={item.firstName + " " + item.lastName}
                      />
                      {withStars &&
                        [1, 2, 3, 4, 5].map((i) => {
                          const stars = item.star;
                          return stars >= i ? (
                            <StarFilled
                              key={i}
                              style={{
                                color: Colors.orange,
                                marginRight: 1,
                              }}
                            />
                          ) : (
                            <StarFilled
                              key={i}
                              style={{
                                marginRight: 1,
                                color: Colors.lightGrey,
                                // background: Colors.mediumGrey,
                              }}
                            />
                          );
                        })}
                    </Col>
                  </Row>
                }
                title={
                  <Typography.Text style={{ color: Colors.darkGrey }}>
                    {item.firstName + " " + item.lastName}
                  </Typography.Text>
                }
                description={
                  <a
                    href={`tel:${item.phone}`}
                    style={{ color: Colors.darkGrey }}
                  >
                    <PhoneOutlined
                      style={{ fontSize: "20px" }}
                      title={item.phone}
                    />
                    {item.phone}
                  </a>
                }
              />
              {item.content}
            </List.Item>
            {withComments ? (
              <List.Item>
                <Comments
                  visible={item.commentShow}
                  onCommentSubmit={(content) => onCommentSubmit(index, content)}
                  author={user}
                  comments={item.comments}
                  addCommentForm={false}
                />
              </List.Item>
            ) : (
              <></>
            )}
          </>
        );
      }}
      dataSource={instructors}
      itemLayout="vertical"
      size="large"
    ></List>
  );
}

export default Instructors;
