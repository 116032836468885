import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import Routes from "../../Routes";
import ChangePackageCard from "../ChangePackageCard/ChangePackageCard";
import Messages from "../../../others/Messages";
import lemmajAxios from "../../../others/apiConfig";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

const ChangePackage = ({ authToken = null, onLogout = () => {} }) => {
  const [fetchedPackageData, setFetchedPackageData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const history = useHistory();

  const student_id = history.location.search.slice("id?=".length);

  const fetchData = () => {
    setFetching(true);

    lemmajAxios
      .get("/LemmajPackages", {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        setFetchedPackageData(res.data);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Row gutter={(16, 16)}>
      {fetchedPackageData.map((fetchedPackage) => {
        const name = fetchedPackage.name;
        const userAvatar = (
          <UserAvatar
            preview={false}
            imgSrc="../../mosh.jpg"
            name={name}
            size={60}
          />
        );
        return (
          <Col
            style={{
              marginBottom: 16,
            }}
          >
            <ChangePackageCard
              packageName={fetchedPackage.name}
              duration={fetchedPackage.duration}
              userAvatar={userAvatar}
              imgSrc="../../../mosh.jpg"
              description={fetchedPackage.description}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default ChangePackage;
