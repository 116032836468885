import { message } from "antd";
import lemmajAxios from "../../../../others/apiConfig";
import Messages from "../../../../others/Messages";
import getScheduledStudents from "./getScheduledStudents";
import postNotification from "../../../../Instructor/component/InstructorNotification/InstructorQueries/PostInstructorNotification";
export default function PatchScheduleData(
  schedule_id,
  studentName,
  userId,
  axiosOption,
  payload,
  setIsLoading,
  setScheduledData,
  onLogout
) {
  const styleNotification = {
    fontWeigh: "bold",
  };
  lemmajAxios
    .patch(`/Schedules/${schedule_id}`, payload, {
      params: {
        access_token: userId,
      },
      ...axiosOption,
    })
    .then((res) => {
      if (res.data.error && res.data.error.statusCode === 401) {
        onLogout();
      }
      if (res.data.error && res.data.error.statusCode === 404) {
        return;
      }
      payload.hasOwnProperty("isRejected") === true
        ? message.success(Messages.SCHEDULE_REJECTED)
        : payload.hasOwnProperty("isApproved")
        ? message.success(Messages.SCHEDULE_APPROVED)
        : message.success("successfully updated");
      getScheduledStudents(
        userId,
        axiosOption,
        setIsLoading,
        setScheduledData,
        onLogout
      );
      const notificationData = {
        message:
          payload.hasOwnProperty("isApproved") === true
            ? `schedule has been approved for student ${studentName}`
            : payload.hasOwnProperty("isRejected") === true
            ? `schedule has been rejected for student ${studentName}`
            : "",
        instructorId: res.data.instructorId,
      };
      postNotification(notificationData, axiosOption);
    })
    .catch((err) => {});
}
