import React from "react";
import { Collapse, Typography, Row, Col, Progress } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import Colors from "../../../../constants/Colors";
function StudenetStatusPanelHeader({ title, completed, courses }) {
  return (
    <Row gutter={(8, 8)} align="middle">
      <Col>
        <Typography.Text style={{ fontSize: 16, fontWeight: "bold" }}>
          {title}
        </Typography.Text>
      </Col>
      {true ? (
        <Col>
          <Progress
            percent={completed ? 100 : 0}
            steps={courses.length}
            size="small"
            strokeColor={Colors.green1}
          />
        </Col>
      ) : null}
    </Row>
  );
}

export default StudenetStatusPanelHeader;
