import { message, Table, Card } from "antd";
import React, { useEffect, useState } from "react";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";

import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import { Input, Space, Modal, Button, Typography } from "antd";
import moment from "moment";

const { Search } = Input;
const { Text } = Typography;

function CertifiedStudents({ userId, onLogout }) {
  const [certifiedUsers, setCertifiedUsers] = useState([]);
  const [certifiedUserByCode, setCertifiedUserByCode] = useState([]);
  const [isStudentGeneratedByCode, setIsStudentGeneratedByCode] = useState(
    true
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [passedCode, setPassedCode] = useState(0);

  const [searchCode, setSearchCode] = useState("");

  let filteredCertifiedUsers = 
    !searchCode || searchCode === ""
    ? certifiedUsers 
    : certifiedUsers.filter((code) => {
      if(code !== undefined) {
        return `${code.certificateCode.toLowerCase()}`
        .trim()
        .includes(searchCode.toLowerCase().trim()
        )
        || 
        `${code.name.toLowerCase()}`
        .trim()
        .includes(searchCode.toLowerCase().trim()
        )
      }
    });

  const handleSearchTerm = term => {
    setSearchCode(term)
  }
  
  const getCertifiedUsers = () => {
    setIsLoading(true);
    lemmajAxios
      .get("/CertifiedUsers/getCertifiedUsersWithUserInfo", {
        params: {
          access_token: userId,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const data = res.data.map((CertifiedStudent) => {
          if(CertifiedStudent.User !== undefined) {
            return {
              name: `${CertifiedStudent.User.firstName} ${CertifiedStudent.User.lastName}`,
              certificateCode: CertifiedStudent.certifiedUser.certificateCode,
              certfiedDate: moment(CertifiedStudent.certifiedUser.certfiedDate).format("MMM DD, YYYY")
            };
          }
        });
        setCertifiedUsers(data);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    getCertifiedUsers();
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: 'name',
    },
    {
      title: "Certeficate Code",
      dataIndex: "certificateCode",
      key: 'certificateCode',
    },
    {
      title: "Certified Date",
      dataIndex: "certfiedDate",
      key: 'certifiedteDate',
    },
  ];
  return (
    <Card>
      <Card style={{ marginBottom: 10, borderRadius: 16 }}>
              <AppInputSearch 
              onSearch={handleSearchTerm}
              placeholder="Search by Code or fullname" />
      </Card>
      <Table
        loading={isLoading}
        key={certifiedUsers.certifiedUser}
        pagination={{ position: "topRight" }}
        scroll={{ x: 20 }}
        columns={columns}
        dataSource={filteredCertifiedUsers}
        size="small"
      ></Table>
    </Card>
  );
}

export default CertifiedStudents;
