import React from "react";
import { Button, List, message, Space, Spin, Tag } from "antd";
import {
  CalendarOutlined,
  CheckOutlined,
  SyncOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import { useHistory, NavLink } from "react-router-dom";
import Routes from "../../Routes";
import moment from "moment";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import { useState } from "react";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function StudentCourseDetails({
  courses,
  periodStatus,
  schedule = null,
  period = null,
  onCompleteFinished = () => null,
  onPackageCompleted = () => null,
  onLogout = () => null,
  authToken = "",
}) {
  const [loading, setLoading] = useState(false);
  const instId = 1;
  let startDate = "";
  let startTime = "";
  let endTime = "";

  if (schedule && schedule.startDate) {
    startDate = moment(new Date(schedule.startDate)).format("DD-MMM-YYYY");
    startTime = moment(new Date(schedule.startDate), "hh:mm a").format(
      "hh:mm a"
    );
    endTime = moment(new Date(schedule.endDate), "hh:mm a").format("hh:mm a");
  }
  const onComplete = (period, schedule) => {
    const data = { scheduleStatus: "completed" };
    lemmajAxios
      .patch(`/Schedules/${schedule.id}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }
        if (period && period.id) {
          const periodData = {
            periodStatus: "completed",
          };
          lemmajAxios
            .patch(`/Periods/${period.id}`, periodData)
            .then((res) => {
              if (res.data.error) {
                message.error(res.statusText);
                return;
              }
              message.success("Period marked completed");
              onPackageCompleted();
              onCompleteFinished();
            })
            .catch((err) => {
              message.error(Messages.OPREATION_FAILED);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        message.error(Messages.OPREATION_FAILED);
        setLoading(false);
      });
  };
  const icon =
    periodStatus === "completed" ? (
      <CheckOutlined style={{ color: Colors.green2 }} />
    ) : periodStatus === "scheduled" ? (
      <CalendarOutlined style={{ color: Colors.blue2 }} />
    ) : periodStatus === "not started" ? (
      <ClockCircleOutlined style={{ color: Colors.red }} />
    ) : null;
  const footer =
    periodStatus === "completed" ? (
      `Completed on ${startDate}`
    ) : periodStatus === "scheduled" && schedule.isApproved ? (
      <Button
        onClick={() => onComplete(period, schedule)}
        icon={<CheckOutlined />}
        type="primary"
        style={{
          background: Colors.blue2,
          borderColor: Colors.blue2,
        }}
      >
        MARK COMPLETE
      </Button>
    ) : (
      ""
    );
  const history = useHistory();

  return (
    <Spin spinning={loading}>
      <List
        footer={footer}
        dataSource={courses}
        renderItem={(i) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Space direction="horizontal">
                  {icon}
                  {i.name}
                </Space>
              }
              description={i.description ? i.description : i.name}
            />
            <div>
              <Tag color={Colors.darkGrey}>
                <Space>
                  <ClockCircleOutlined />
                  {i.length} minutes
                </Space>
              </Tag>
            </div>
          </List.Item>
        )}
      ></List>
    </Spin>
  );
}

export default StudentCourseDetails;
