import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Divider,
  Typography,
  Card,
  Spin,
  message,
} from "antd";
import { UserOutlined, MailFilled, PhoneFilled } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import moment from "moment";
import Routes from "../../Routes";
import { useHistory } from "react-router";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function Basic({ authToken = null, onLogout = () => {} }) {
  const [submmiting, setSubmmittng] = useState(false);
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const parsePhoneNo = (phoneNumber) => {
    return `09${phoneNumber}`;
  };

  const fillForm = (firstName, lastName, email, phoneNumber, dob, gender) => {
    form.setFieldsValue({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber.slice(2),
      dob: moment(dob),
      dateFormat,
      gender: gender,
    });
  };

  const dateFormat = "YYYY-MM-DD";

  const fetchDataAndFillData = () => {
    const instructor_id = history.location.search.slice("id?=".length);
    setFetching(true);
    lemmajAxios
      .get(`/LemmajUsers/${instructor_id}`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const instructor = res.data;
        fillForm(
          instructor.firstName,
          instructor.lastName,
          instructor.email,
          instructor.phoneNumber,
          instructor.dob,
          instructor.gender
        );
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchDataAndFillData();
  }, []);

  const onUpdateBasicInstructor = (values) => {
    const instructor_id = history.location.search.slice("id?=".length);
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: parsePhoneNo(values.phoneNumber),
      dob: values.dob,
      gender: values.gender,
    };
    setSubmmittng(true);
    lemmajAxios
      .patch(`/LemmajUsers/${instructor_id}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        message.success(Messages.INFO_UPDATED);
        history.replace(`${Routes.INSTRUCTOR_DETAIL}?id=${instructor_id}`);
        setSubmmittng(false);
      })
      .catch((err) => {
        setSubmmittng(false);
        message.error(Messages.OPREATION_FAILED);
      });
    return;
  };

  return (
    <Spin spinning={submmiting}>
      <Card loading={fetching}>
        <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
          Basic
        </Typography.Title>
        <Divider />
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            onUpdateBasicInstructor(values);
          }}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "First Name is required" }]}
          >
            <Input addonBefore={<UserOutlined />} placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Last Name is required" }]}
          >
            <Input addonBefore={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input addonBefore={<MailFilled />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phoneNumber"
            rules={[
              { required: true, message: "Phone is required" },
              () => ({
                validator(_, value) {
                  const digit = Number(value);
                  if (
                    Number.isNaN(digit) ||
                    value.includes("+") ||
                    value.includes(".") ||
                    !Number.isInteger(digit) ||
                    value < 0 ||
                    value.length < 8
                  ) {
                    return Promise.reject("Phone must be valid");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="tel"
              maxLength={8}
              prefix={"09"}
              placeholder="Phone"
              addonBefore={<PhoneFilled />}
            />
          </Form.Item>
          {/* <Form.Item
            label="Nationality"
            name="nationality"
            rules={[{ required: true, message: "Nationality is required" }]}
          >
            <Input addonBefore={<FlagFilled />} placeholder="Nationality" />
          </Form.Item> */}
          <Form.Item
            label="Date Of Birth"
            name="dob"
            rules={[{ required: true, message: "Birth Date is required" }]}
          >
            <DatePicker
            //defaultValue={moment('2015/01/01', dateFormat)}
            />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Select a gender" }]}
          >
            <Select placeholder="Gender">
              <Select.Option key="male">Male</Select.Option>
              <Select.Option key="female">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                <Button
                  style={{
                    background: Colors.blue2,
                    borderRadius: Colors.blue2,
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Information
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default Basic;
