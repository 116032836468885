import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Card, Tag, Button, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import lemmajAxios from "../../../others/apiConfig";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import Routes from "../../Routes";
import Messages from "../../../others/Messages";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function Students({ authToken = null, onLogout = () => {} }) {
  const [fetching, setFetching] = useState(true);
  const [studentData, setStudentData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      //fetch all students
      .get(`/LemmajUsers`, {
        params: {
          access_token: authToken,
          "filter[where][userType]": "student",
          "filter[where][isApproved]": true,
        },
        ...axiosOption,
      })
      .then((res) => {
        //temporary code
        setFetching(false);

        const data = res.data.map((c) => {
          return {
            name: c.firstName + " " + c.lastName,
            id: c.id,
            email: c.email,
            status: c.isLocked ? "Locked" : c.isOnLeave ? "On Leave" : "Active",
            // , id: c.id, gear_type: c.gearType
          };
        });
        setStudentData(data);

        //fetch packages for each students
        // for loop
        // lemmajAxios
        // .get(`/LemmajPackages/${res.data.id}`, {
        //   params: {
        //     access_token: authToken,
        //   },
        //   ...axiosOption,
        // })
        // .then((res) => {
        //   setFetching(false);
        //   const data = res.data.map((c) => {
        //     return { name: c.firstName+" "+ c.lastName, status: c.isLocked?"Locked":"Active", package: "Package A", gearType: "Auto", id: c.id};
        //   });
        //   setStudentData(data);
        // })
        // .catch((err) => {
        //   setFetching(false);
        //   message.error(Messages.OPREATION_FAILED);
        // });
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchData();
    // setFetching(false);
  }, []);

  const handleSearchTerm = (term) => {
    setSearchTerm(term);
  };

  let filteredStudents =
    !searchTerm || searchTerm === ""
      ? studentData
      : studentData.filter((i) => {
          return (
            `${i.name.toLowerCase()}`
              .trim()
              .includes(searchTerm.toLowerCase().trim()) ||
            `${i.email.toLowerCase()}`
              .trim()
              .includes(searchTerm.toLowerCase().trim())
          );
        });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    // {
    //   title: "Gear Type",
    //   dataIndex: "gear_type",
    // },
    {
      title: "Details",
      render: (_, record) => {
        return (
          <Button
            onClick={() =>
              history.push(`${Routes.STUDENT_DETAIL}?id=${record.id}`)
            }
            icon={<EyeOutlined />}
            type="ghost"
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 10, borderRadius: 16 }}>
        <AppInputSearch
          onSearch={handleSearchTerm}
          placeholder="Students name | email"
        />
      </Card>
      <Card style={{ borderRadius: 16 }}>
        <Table
          loading={fetching}
          scroll={{ x: 50 }}
          columns={columns}
          dataSource={filteredStudents}
        ></Table>
      </Card>
    </>
  );
}

export default Students;
