import React, { useEffect, useState } from "react";
import { Menu, Row, Col, Table, Button, message, Modal } from "antd";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import "./notification.css";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function InstructorNotifications({
  getInstructorNotification,
  onUpdate,
  onDelete,
}) {
  const getUser = JSON.parse(localStorage.getItem("userAuth"));
  const authToken = getUser.id;
  
  useEffect(() => {
    // getInstructorNotification();
  });
  // onVisible();
  const columns = [
    {
      key: "id",
      title: "Notification",
      dataIndex: "message",
    },
    {
      key: "delete",
      dataIndex: "action",
      width: 67,
      render(text, record) {
        return (
          <Button
            onClick={() => {
              onDeleteIconClicked(record.id);
            }}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const onDeleteIconClicked = (id) => {
    onDelete();
    Modal.confirm({
      title: Messages.DELETE_NOTIFICATION_CONFIRM,
      onOk: () => {
        deleteNotification(id);
      },
    });
  };
  const deleteNotification = (id) => {
    lemmajAxios
      .delete(`Notifications/${id}`, {
        params: {
          access_token: authToken,
        },
      })
      .then((res) => {
        if (res.data.error && res.data.statusCode === 401) {
          return;
        }
        if (res.data.error && res.data.statusCode === 404) {
          return;
        }
        message.success(Messages.NOTIFICATION_DELETED);
        onUpdate();
      });
  };
  const data = {
    isRowClicked: true,
  };
  const onTableRowClicked = (record, index) => {
    lemmajAxios
      .post(`Notifications/update?[where][id]=${record.id}`, data, {
        params: {
          access_token: authToken,
        },
      })
      .then((res) => {
        if (res.data.error && res.data.statusCode === 401) {
          return;
        }
        if (res.data.error && res.data.statusCode === 404) {
          return;
        }
        onUpdate();
      })
      .catch((err) => {});
  };
  return (
      <Row>
        
      <Col span={8} xs={0} sm={0} lg={8} md={0} xxl={8} ></Col>
      <Col span={8} xs={8} sm={8} lg={8} md={8} xxl={8}></Col>
      <Col span={8} xs={16} sm={16} lg={8} md={16} xxl={8}>
        <Table
          rowClassName={(record, index) =>
            record.isRowClicked === false ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={getInstructorNotification}
          scroll={{ y: 250 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onTableRowClicked(record, rowIndex);
              },
            };
          }}
        />
      </Col>
    </Row>
  );
}

export default InstructorNotifications;
