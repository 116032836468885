import React, { useEffect, useState } from "react";
import { Steps, Row, Col, Card, message, Spin, Button } from "antd";
import {} from "@ant-design/icons";
import Basic from "./Basic";
import Location from "../Register/Location";
import License from "../Register/License";
import Colors from "../../constants/Colors";
import moment from "moment";
import lemmajAxios from "../../others/apiConfig";
import Messages from "../../others/Messages";
import PasswordChangeForm from "../PasswordChangeForm/PasswordChangeForm";
import NewPasswordForm from "./newPasswordForm";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";
import firebase from "../../others/firebaseConfig";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
const { Step } = Steps;

function Register(props) {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [confirmationCode, setConfimationCode] = useState("");
  const [licenseInfo, setLicenseInfo] = useState({
    licenseType: "",
    regNum: "",
    license_image: "",
    issuedDate: moment(Date.now()),
    expireDate: moment(Date.now()),
    gearType: "",
    packageId: "",
  });
  const [basicInfo, setBasicInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: moment(Date.now()),
    phoneNumber: "",
    gender: "",
  });
  const [locationInfo, setLocationInfo] = useState({
    country: "",
    region: "",
    city: "",
  });

  useEffect(() => {
    setUpRecaptcha();
  }, []);

  const naviagte = (to) => {
    if (current != to && to >= 0 && to < 4) {
      setCurrent(to);
    }
  };

  const onBasicInfoSubmit = (values) => {
    setBasicInfo(values);
    naviagte(1);
  };
  const onLocationInfoSubmit = (values) => {
    setLocationInfo(values);
    naviagte(3);
  };
  const onPasswordSubmit = (values) => {
    setPassword(values.newPassword);
    naviagte(2);
  };
  const setUpRecaptcha = async () => {
    window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier(
      "captcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // captcha resolved
          // sendPhoneVerification();
        },
      }
    );
  };
  const sendPhoneVerification = async (phoneWithShortCode) => {
    if (!window.recaptchaVerifier || undefined) {
      setUpRecaptcha();
    }
    await firebase
      .auth()
      .signInWithPhoneNumber(phoneWithShortCode, window.recaptchaVerifier)
      .then(async (confirmationResult) => {
        localStorage.setItem("OTP", JSON.stringify(confirmationResult));
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onLicenseInfoSubmit = async (values) => {
    setLicenseInfo(values);
    setSubmitting(true);
    await sendPhoneVerification(`+2519${basicInfo.phoneNumber}`);
    setSubmitting(false);
    const data = {
      firstName: basicInfo.firstName,
      lastName: basicInfo.lastName,
      dob: basicInfo.dob.toString(),
      vehicleUsed: "vehicleIDGoesHere",
      phoneNumber: `09${basicInfo.phoneNumber}`,
      email: basicInfo.email,
      gender: basicInfo.gender,
      userType: "student",
      userRole: [""],
      city: locationInfo.city,
      country: locationInfo.country,
      Region: locationInfo.region,
      licenseNumber: values.regNum,
      licenseType: values.licenseType,
      licenseIssuedDate: values.issuedDate.toString(),
      licenseExpiryDate: values.expireDate.toString(),
      gearType: values.gearType,
      license_image: values.license_image,
      password: password,
      isLocked: false,
      packageId: values.packageId,
    };
    history.push({
      pathname: Routes.VERIFY_PHONE,
      state: { data: data },
    });
  };

  const steps = [
    {
      title: "Basic",
      content: (
        <Basic
          firstName={basicInfo.firstName}
          lastName={basicInfo.lastName}
          phoneNumber={basicInfo.phoneNumber}
          email={basicInfo.email}
          dob={basicInfo.dob}
          gender={basicInfo.gender}
          onFormSubmit={onBasicInfoSubmit}
        />
      ),
    },
    {
      title: "Password",
      content: (
        <NewPasswordForm
          password={password}
          onCancel={() => naviagte(0)}
          onSubmit={onPasswordSubmit}
        />
      ),
    },
    {
      title: "Location",
      content: (
        <Location
          city={locationInfo.city}
          region={locationInfo.region}
          country={locationInfo.country}
          onCancel={() => naviagte(1)}
          onFormSubmit={onLocationInfoSubmit}
        />
      ),
    },
    {
      title: "License",
      content: (
        <License
          submitting={submitting}
          licenseType={licenseInfo.licenseType}
          regNum={licenseInfo.regNum}
          license_image={licenseInfo.license_image}
          issuedDate={licenseInfo.issuedDate}
          expireDate={licenseInfo.expireDate}
          onCancel={() => naviagte(2)}
          onFormSubmit={onLicenseInfoSubmit}
          gearType={licenseInfo.gearType}
          packageId={licenseInfo.packageId}
        />
      ),
    },
  ];

  return (
    <div style={{ background: Colors.veryLightGrey, minHeight: "100vh" }}>
      <div id="captcha-container"></div>
      <div style={{ margin: 8 }}>
        <Row style={{ width: "100%" }}>
          <Col></Col>
          <Col
            xl={{ offset: 7, span: 10 }}
            lg={{ offset: 7, span: 10 }}
            md={{ offset: 7, span: 13 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Spin spinning={submitting} tip="Registering">
              <Card
                hoverable
                style={{ marginTop: 20, marginBottom: 20, borderRadius: 16 }}
              >
                <Steps
                  responsive
                  current={current}
                  direction="horizontal"
                  size="small"
                >
                  {steps.map((item) => (
                    <Step title={item.title} />
                  ))}
                </Steps>
              </Card>

              <Card style={{ borderRadius: 16 }}>{steps[current].content}</Card>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Register;
