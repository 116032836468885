import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, DatePicker, Select } from "antd";
import { PhoneFilled, UserOutlined, MailFilled } from "@ant-design/icons";
import moment from "moment";
import Colors from "../../../constants/Colors";
function BasciForm({
  firstName = "",
  lastName = "",
  phone = "",
  email = "",
  gender = "",
  dob = "",
  submitting = false,
  onSubmit = () => null,
  disabled = false,
}) {
  const [form] = Form.useForm();

  form.setFieldsValue({
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    email: email,
    dateBirth: dob,
    gender: gender,
  });

  return (
    <Form
      form={form}
      style={{ marginTop: 0 }}
      layout="vertical"
      onFinish={(values) => {
        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          phoneNumber: "09" + String(values.phone),
          dob: moment(values.dateBirth).toString(),
        };
        onSubmit(data);
      }}
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "First name is required" }]}
      >
        <Input
          disabled={disabled}
          placeholder="First Name"
          addonBefore={<UserOutlined />}
        />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: "Last name is required" }]}
      >
        <Input
          disabled={disabled}
          placeholder="Last Name"
          addonBefore={<UserOutlined />}
        />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        rules={[
          { required: true, message: "Phone is required" },
          () => ({
            validator(_, value) {
              const digit = Number(value);
              if (
                Number.isNaN(digit) ||
                value.includes("+") ||
                value.includes(".") ||
                !Number.isInteger(digit) ||
                value < 0 ||
                value.length < 8
              ) {
                return Promise.reject("Phone must be valid");
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input
          disabled={disabled}
          type="tel"
          maxLength={8}
          prefix={"09"}
          placeholder="Phone"
          addonBefore={<PhoneFilled />}
        />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Email is required" }]}
      >
        <Input
          disabled={disabled}
          placeholder="Email"
          addonBefore={<MailFilled />}
        />
      </Form.Item>
      <Form.Item
        label="Gender"
        name="gender"
        rules={[{ required: true, message: "Select gender" }]}
      >
        <Select disabled={disabled} placeholder="Gender">
          <Select.Option key="male">Male</Select.Option>
          <Select.Option key="female">Female</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Date Birth"
        name="dateBirth"
        rules={[{ required: true, message: "Last name is required" }]}
      >
        <DatePicker disabled={disabled} placeholder="Date Of Birth" />
      </Form.Item>
      {!disabled ? (
        <Form.Item>
          <Row justify="start">
            <Col xs={24} sm={8}>
              <Button
                loading={submitting}
                style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
                block
                type="primary"
                htmlType="submit"
              >
                Update Information
              </Button>
            </Col>
          </Row>
        </Form.Item>
      ) : null}
    </Form>
  );
}

export default BasciForm;
