import React, {useState, useEffect } from "react";
import {Button, Space, Card, Table, Modal, List, message } from "antd";
import lemmajAxios from "../../../others/apiConfig";
import moment from 'moment';
import Messages from "../../../others/Messages";

const axiosOption = {
    validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
    },
};
function VehiclesPerformance({userId}) {

    const [performance, setPerformance] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const paymentAmount = 10; // 10 ethiopian birr;
    const fetchPerformance = () => {
            setIsLoading(true)
            lemmajAxios
            .get('/Schedules/', {
            params: {
                access_token: userId
            },
            ...axiosOption,
            })
            .then((res) => {
            setIsLoading(false)
            const newPerformanceData = res.data.map((p) => {
                const newP = {...p}
                newP.startDate = moment(newP.startDate).format("MMM DD YY, HH:mm");
                newP.endDate = moment(newP.endDate).format("MMM DD YY, HH:mm");
                let getStartDate = moment(newP.startDate).format("hh:mm:ss");
                let getEndDate = moment(newP.endDate).format("hh:mm:ss");
                let formateStartDate = moment(getStartDate, "hh:mm:ss");
                let formateEndDate = moment(getEndDate, "hh:mm:ss");
                let subtract = formateEndDate.diff(formateStartDate, 'hours');
                newP.dailyGain = subtract * paymentAmount;
                return newP
            })
            setPerformance(newPerformanceData);
            })
            .catch((err) => {
                setIsLoading(false)
                message.error(Messages.OPREATION_FAILED)

            })
        
    }
    

    
    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => {
        fetchPerformance();
    }, []);
        const columns = [
            {
                title: "Vehicle Plate Number",
                dataIndex: 'vehiclePlateNumber',
            },
            {
                title: "Performance",
                render: (_, record) => {
                    return (
                        <Space>
                            <Button
                                onClick = {() => {
                                    onMoreButtonClicked(record.id);
                                }}
                                size="small"
                                type="primary"
                            >More</Button>
                        </Space>
                    )
                }
            }
        ];
        const modalColumns = [
            {
                title: "From",
                dataIndex: "startDate",
            },
            {
                title: "To",
                dataIndex: "endDate",
            },
            {
                title: "Daily Gain",
                dataIndex: "dailyGain",
            },
        ];
        const onMoreButtonClicked = (id) => {
            // Modal.confirm
            setIsModalVisible(true);
            // setPerformance
        }
        const handleModal = () => {
            setIsModalVisible(false)
        }
        
    return (

        <Card style={{ borderRadius: 16 }}>
        <Table
          loading={isLoading}
          pagination={{ position: "topRight" }}
          scroll={{ x: 20 }}
          columns={columns}
          dataSource={performance}
          size="small"
        ></Table>
        <Modal title="Performance For the Selected Vehicle" 
            visible={isModalVisible} onOk={handleModal} onCancel={handleModal}>
                <Table
                pagination={{position: "topRight"}}
                scroll={{x: 20}}
                columns={modalColumns}
                dataSource={performance}
                size="small"
                ></Table>
        </Modal>
      </Card>
    )    
}

export default VehiclesPerformance;