import React from "react";
import Instructor from "../Instructor/Instructor";
import { Typography, Button, Card, Spin } from "antd";
import {} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";

function SetInstructor({ userData, onInstructorSet, selecting = false }) {
  const history = useHistory();
  let id = "someId";
  id = new URLSearchParams(history.location.search).get("id");

  const onProceedClicked = (id) => {
    history.push({
      pathname: `${Routes.SCEHEDULE_CALENDER}`,
      search: `?id=${id}`,
      state: { ...history.location.state },
    });
  };
  return (
    <Spin spinning={selecting} tip="waiting...">
      <Card>
        <Instructor
          withStars={false}
          withComment={false}
          accessToken={userData.id}
          withCommentForm={false}
        />
        <Button
          loading={selecting}
          size="large"
          type="primary"
          onClick={() => onProceedClicked(id)}
          style={{
            marginTop: 20,
            background: Colors.blue2,
            borderRadius: Colors.blue2,
          }}
        >
          Proceed
        </Button>
      </Card>
    </Spin>
  );
}

export default SetInstructor;
