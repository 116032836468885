import React, { useEffect, useState } from "react";
import {
  Badge,
  List,
  Row,
  Col,
  Card,
  Tag,
  Collapse,
  Typography,
  Divider,
  Avatar,
  Button,
  message,
} from "antd";
import { ClockCircleFilled, ClockCircleOutlined } from "@ant-design/icons";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import Colors from "../../../constants/Colors";
import { useHistory } from "react-router";
import Routes from "../../Routes";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import Messages from "../../../others/Messages";
import lemmajAxios from "../../../others/apiConfig";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function StudentsPending({ authToken = null, onLogout = () => {} }) {
  const [fetching, setFetching] = useState(true);
  const [pendingStudentData, setPendingStudentData] = useState([]);
  const history = useHistory();

  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      .get("/LemmajUsers", {
        params: {
          access_token: authToken,
          "filter[where][userType]": "student",
          "filter[where][isApproved]": false,
          "filter[where][isRejected]": false,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        const data = res.data.map((c) => {
          return {
            name: c.firstName + " " + c.lastName,
            pending_time: c.createdDate,
            id: c.id,
          };
        });
        setPendingStudentData(data);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchData();
    // setFetching(false);
  }, []);
  return (
    <Card>
      <Typography.Title level={4} style={{ color: Colors.darkGrey }}>
        Student Register Pending
      </Typography.Title>
      <Divider />
      <List
        loading={fetching}
        dataSource={pendingStudentData}
        renderItem={(student) => {
          return (
            <>
              <List.Item onClick={() => {}}>
                <List.Item.Meta
                  avatar={<UserAvatar imagSrc={null} name={student.name} />}
                  title={<a>{student.name}</a>}
                  description={
                    (
                      <Tag color={Colors.orange}>
                        <ClockCircleFilled style={{ color: Colors.orange }} />
                        {student.pending_time}
                      </Tag>
                    ) && !student.pending_time
                  }
                />
                <Button
                  onClick={() => {
                    history.push(
                      `${Routes.STUDENT_PENDING_DETAIL}?id=${student.id}`
                    );
                  }}
                  type="primary"
                  style={{
                    background: Colors.blue2,
                    borderColor: Colors.blue2,
                  }}
                >
                  View Request
                </Button>
              </List.Item>
            </>
          );
        }}
      />
    </Card>
  );
}

export default StudentsPending;
