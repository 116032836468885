import React, { useEffect, useState } from "react";
import {
  Calendar,
  Alert,
  Row,
  Col,
  Modal,
  Select,
  Form,
  Button,
  Spin,
  message,
  List,
  Space,
} from "antd";
import moment from "moment";
import Colors from "../../../constants/Colors";
import { WarningOutlined } from "@ant-design/icons";
import Constant from "../../../constants/Constant";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import {
  ClockCircleFilled,
  CloseOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { CancelScheduleSend } from "@material-ui/icons";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
const CalendarStyle = {
  height: "70%",
  width: "70%",
  lineHeight: "40px",
  borderRadius: 2,
  backgroundColor: Colors.lightGrey,
  textAlign: "center",
  fontSize: 14,
  margin: 10,
};

const time = Constant.TIME;

function DutyDays({
  dutyDays = [],
  onSaveDutyDate = () => null,
  exceptionDays = [],
  authToken = "",
  onLogout = () => null,
}) {
  const [form] = Form.useForm();
  const [selectedDate, setselectedDate] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const getUser = JSON.parse(localStorage.getItem("userAuth"));

  const CancelScheduleSend = (id) => {
    lemmajAxios
      .delete(`/Schedules/${id}`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }

        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }
        if (res.data && res.data.count <= 0) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        message.success(Messages.SCHEDULE_DELETED);
        onSaveDutyDate();
        Modal.destroyAll();
      })
      .catch((err) => {
        setLoading(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  const saveDutyDay = (value) => {
    const data = {
      instructorId: getUser.userId,
      dutyDays: value,
    };
    //console.log(value);
    setLoading(true);
    lemmajAxios
      .post("/Schedules/setDutyDays", data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })

      .then((res) => {
        setLoading(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }
        message.success("Schedule created");
        onSaveDutyDate();
        form.resetFields();
        Modal.destroyAll();
      })
      .catch((err) => {
        setLoading(false);
        message.error(Messages.OPREATION_FAILED);
        form.resetFields();
        setModalShow(false);
      });
  };

  const onDutyDays = (values) => {
    const times = values.time;
    const dates = [];
    //console.log(values)
    times.forEach((time) => {
      const startTime = moment(time[0], "H:mm A");
      const endTime = moment(time[1], "H:mm A");
      const day = moment(values.day);

      const startDate = moment(
        new Date(
          day.year(),
          day.month(),
          day.date(),
          startTime.hour(),
          startTime.minute(),
          0
        )
      ).toString();
      const endDate = moment(
        new Date(
          day.year(),
          day.month(),
          day.date(),
          endTime.hour(),
          endTime.minute(),
          0
        )
      ).toString();

      dates.push({
        startDate,
        endDate,
      });
    });
    saveDutyDay(dates);
  };

  const isDutyDays = (momentDay) => {
    const allScedules = dutyDays.map((scheduleObj, i) => {
      const d = scheduleObj.schedule;

      return {
        ...d,
        startDate: moment(d.startDate).format("DD/MM/YYYY"),
        endDate: moment(d.endDate).format("DD/MM/YYYY"),
      };
    });
    const formattedDate = momentDay.format("DD/MM/YYYY");
    const scheduleDates = [];
    allScedules.forEach((d, i) => {
      if (d.startDate === formattedDate) {
        scheduleDates.push(dutyDays[i]);
      }
    });
    if (!scheduleDates || scheduleDates.length <= 0) {
      return [];
    }
    return scheduleDates;
  };

  const onCellSelect = (value) => {
    const found = isDutyDays(moment(value));
    if (found.length > 0) {
      Modal.info({
        icon: false,
        closable: true,
        content: (
          <List
            header={
              <b>{`Schedules on ${moment(value).format("MMM-DD-YYYY")}`}</b>
            }
            pagination={{ pageSize: 6 }}
            dataSource={found}
            renderItem={(i, ind) => {
              return (
                <List.Item key={ind}>
                  <List.Item.Meta
                    title={
                      <Space>
                        <ClockCircleFilled style={{ color: Colors.orange }} />
                        <span>
                          {`${moment(
                            new Date(i.schedule.startDate),
                            "hh:mm A"
                          ).format("hh:mm A")} - ${moment(
                            new Date(i.schedule.endDate),
                            "hh:mm A"
                          ).format("hh:mm A")}`}
                        </span>
                      </Space>
                    }
                  />
                  {i.studentId ? (
                    <Button type="text" disabled icon={<CalendarOutlined />} />
                  ) : (
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => CancelScheduleSend(i.schedule.id)}
                    ></Button>
                  )}
                </List.Item>
              );
            }}
          />
        ),
      });
      return;
    }
    const date = moment(value).format("MMM-DD-YYYY");
    const day2 = moment(value);
    setselectedDate(date);
    if (day2.day() === Constant.SUNDAY) {
      return;
    }
    Modal.info({
      title: `Scheduled on ${date}`,
      cancelText: "CLOSE",
      okText: "ClOSE",
      closable: true,
      icon: false,
      content: (
        <>
          <Form
            form={form}
            onFinish={(values) => {
              //console.log(values)
              const data = { ...values, day: date };
              onDutyDays(data);
            }}
          >
            <Form.Item name="time" required>
              <Select style={{ width: "100%" }} mode="multiple">
                {time.map((t) => (
                  <Select.Option value={t.toString()} key={t}>
                    {t[0]} - {t[1]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
                type="primary"
                htmlType="submit"
              >
                SAVE
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    });
  };
  return (
    <Spin spinning={loading}>
      <Alert
        banner
        icon={<WarningOutlined />}
        showIcon
        description={
          <>
            <Row gutter={(8, 8)} align="middle">
              <Col style={{ color: Colors.grey }}>
                Please click a day in the month and enter duty days and time.
              </Col>
            </Row>
          </>
        }
        type="warning"
      />

      <Calendar
        mode="month"
        fullscreen={false}
        onSelect={onCellSelect}
        dateFullCellRender={(value) => {
          const day = Number(moment(value).toDate().getDate());
          const momentDay = moment(value);
          const cell =
            isDutyDays(momentDay).length > 0 ? (
              <div
                onClick={() => {}}
                style={{
                  height: "70%",
                  width: "70%",
                  lineHeight: "40px",
                  borderRadius: 2,
                  backgroundColor: Colors.yellow,
                  textAlign: "center",
                  fontSize: 14,
                  margin: 10,
                  color: Colors.white,
                }}
              >
                {day}
              </div>
            ) : (
              <div style={CalendarStyle}>{day}</div>
            );
          return cell;
        }}
      />
    </Spin>
  );
}

export default DutyDays;
