import { Button, Card, List, message, Space, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import moment from "moment";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import Routes from "../../Routes";
import Messages from "../../../others/Messages";
import { NavLink } from "react-router-dom";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

const Vehicles = ({ onLogout, authToken }) => {
  const [vehicles, setVehicles] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [filterWord, setFilterWord] = useState("");
  const history = useHistory();
  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      .get("/Vehicles", {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        const car_list = res.data.reverse();
        setVehicles(car_list);
      })
      .catch((err) => {
        setFetching(false);

        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onDelete = (id) => {
    setDeleting(true);
    lemmajAxios
      .delete(`/Vehicles/${id}`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setDeleting(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        if (res.data.error) {
          message.error(Messages.OPREATION_FAILED);
          return;
        }
        message.success("Vehicle deleted successfully");
        fetchData();
      })
      .catch((err) => {
        setDeleting(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  let filteredInstructors =
    !filterWord || filterWord === ""
      ? vehicles
      : vehicles.filter((i) => {
        return (
          `${i.plate?.toLowerCase()}`
            .trim()
            .includes(filterWord.toLowerCase().trim()) ||
          `${i?.category?.toLowerCase()}`
            .trim()
            .includes(filterWord.toLowerCase().trim()) ||
          `${i?.gearType?.toLowerCase()}`
            .trim()
            .includes(filterWord.toLowerCase().trim()) ||
          `${moment(i?.year).format('YYYY')?.toLowerCase()}`
            .trim()
            .includes(filterWord.toLowerCase().trim()) ||
          `${i?.model?.toLowerCase()}`
            .trim()
            .includes(filterWord.toLowerCase().trim())
        );});
  return (
    <Spin spinning={deleting || fetching}>
      <Card>
        <AppInputSearch
          onSearch={(value) => {
            setFilterWord(value);
          }}
          placeholder="Plate number | Year | Name | Model | Category"
        />
      </Card>
      <Card>
        <List
          pagination={{ defaultPageSize: 10 }}
          header={
            <Button
              onClick={() => {
                history.push(Routes.VECHILE);
              }}
              type="primary"
              style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
              icon={<PlusCircleOutlined />}
            >
              Add Vehicle
            </Button>
          }
          style={{ background: "white" }}
          dataSource={filteredInstructors}
          renderItem={(i) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Space direction="horizontal">
                    <b>{i.model}</b>
                    <br />
                  </Space>
                }
                description={
                  <div>
                    {
                      <Tag style={{ marginBottom: "10px", marginTop: "10px" }}>
                        {moment(i.year).format("YYYY")}
                      </Tag>
                    }

                    {
                      <Tag style={{ marginBottom: "10px", marginTop: "10px" }}>
                        {i.plate}
                      </Tag>
                    }

                    {
                      <Tag style={{ marginBottom: "10px", marginTop: "10px" }}>
                        {i.gearType}
                      </Tag>
                    }
                    {i.owner && i.owner != "none" && i.owner != "Instructor" && (
                      <NavLink to={`${Routes.INSTRUCTOR_DETAIL}?id=${i.owner}`}>
                        <Button type="link">View Owner</Button>
                      </NavLink>
                    )}
                  </div>
                }
              />
              <div>
                <Button
                  onClick={() => onDelete(i.id)}
                  type="primary"
                  style={{
                    background: Colors.blue2,
                    borderColor: Colors.blue2,
                  }}
                >
                  Delete
                </Button>
              </div>
            </List.Item>
          )}
        ></List>
      </Card>
    </Spin>
  );
};

export default Vehicles;
