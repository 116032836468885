import React, { useEffect, useState } from "react";
import {
  Table,
  Checkbox,
  Space,
  List,
  Card,
  Row,
  Col,
  Typography,
  Button,
  message,
  Spin,
} from "antd";
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import { useHistory } from "react-router";
import Routes from "../../Routes";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function InstructorSchedule({ authToken = null, onLogout = () => {} }) {
  const [fetching, setFetching] = useState(false);
  const [submmiting, setSubmmittng] = useState(false);
  const history = useHistory();

  const daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [schedules, setSchedules] = useState([]);
  const [editMode, toggleEdit] = useState(false);
  const handleCheck = (index, checked) => {
    const newShedules = schedules.slice();
    const temp = { ...newShedules[index] };
    temp.duty = !temp.duty;
    newShedules[index] = temp;
    setSchedules(newShedules);
  };
  // const toggleEditMode = (value) => {
  //   toggleEdit(value);

  // };

  const fetchDataAndFillData = () => {
    const instructor_id = history.location.search.slice("id?=".length);
    setFetching(true);

    lemmajAxios
      .get(`/LemmajUsers/${instructor_id}`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }

        const newShedules = [
          { day: "", duty: false },
          { day: "", duty: false },
          { day: "", duty: false },
          { day: "", duty: false },
          { day: "", duty: false },
          { day: "", duty: false },
          { day: "", duty: false },
        ];
        for (let i = 0; i < 7; i++) {
          const temp = { ...newShedules[i] };
          temp.day = daysOfTheWeek[i];

          res.data.dutyDays.includes(i.toString())
            ? (temp.duty = true)
            : (temp.duty = false);

          newShedules[i] = temp;
        }
        setSchedules(newShedules);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  const convertScheduleToNumerical = () => {
    const data = [];
    for (let i = 0; i < schedules.length; i++) {
      if (schedules[i].duty) {
        data.push(i.toString());
      }
    }
    return data;
  };

  const onUpdateSchedule = () => {
    const instructor_id = history.location.search.slice("id?=".length);
    const data = {
      dutyDays: convertScheduleToNumerical(),
    };
    setSubmmittng(true);
    lemmajAxios
      .patch(`/LemmajUsers/${instructor_id}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        message.success(Messages.INFO_UPDATED);
        history.replace(Routes.INSTRUCTORS);
        setSubmmittng(false);
      })
      .catch((err) => {
        setSubmmittng(false);
        message.error(Messages.OPREATION_FAILED);
      });
    return;
  };

  useEffect(() => {
    fetchDataAndFillData();
  }, []);

  return (
    <Spin spinning={submmiting}>
      <Card loading={fetching}>
        <List
          header={
            <Typography.Text style={{ color: Colors.veryDarkGrey }} strong>
              {" "}
              Weekdays{" "}
            </Typography.Text>
          }
          dataSource={schedules}
          renderItem={(item, index) => {
            return (
              <List.Item>
                <Row gutter={(20, 20)}>
                  <Col>
                    <Checkbox
                      disabled={!editMode}
                      value={item.duty}
                      onChange={(e) => {
                        handleCheck(index, e.target.value);
                      }}
                      checked={item.duty}
                    />
                  </Col>
                  <Col>
                    <Typography.Text strong type="secondary">
                      {item.day}
                    </Typography.Text>
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
        <Row gutter={(16, 16)}>
          <Col>
            <Button
              onClick={() => {
                toggleEdit(false);
                onUpdateSchedule();
              }}
              disabled={!editMode}
              type="primary"
              style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
            >
              {" "}
              Update Information
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => toggleEdit(true)}
              icon={<EditOutlined />}
              type="primary"
              style={{ background: Colors.blue2, borderColor: Colors.blue2 }}
            >
              {" "}
              Edit
            </Button>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
}

export default InstructorSchedule;
