import React from "react";
import lemmajAxios from "../../../../others/apiConfig";
import moment from "moment";

export default function getEnrollments(
  userId,
  axiosOption,
  setIsLoading,
  setEnrollments,
  onLogout
) {
  lemmajAxios
    .get("Enrollments/getAllEnrollmentForAdmin", {
      params: {
        access_token: userId,
      },
      ...axiosOption,
    })
    .then((res) => {
      setIsLoading(false);
      if (res.data.error && res.data.error.statusCode === 401) {
        return onLogout();
      }
      if (res.data.error && res.data.error.statusCode === 404) {
        return res.json({ message: "data is not found" });
      }
      const data = res.data.map((enrollment) => {
        return {
          studentName: `${enrollment.student.firstName} ${enrollment.student.lastName}`,
          instructorName: `${enrollment.instructor.firstName} ${enrollment.instructor.lastName}`,
          enrollmentDate: moment(enrollment.enrollment.enrollmentDate).format(
            "MMM DD, YY"
          ),
          status: enrollment.enrollment.status,
          isDone: enrollment.enrollment.isDone === true ? "true" : "false",
          completed: enrollment.enrollment.completed === 1 ? "true" : "false",
        };
      });
      res.data.map((enrollment) => {
        return (
          (enrollment.idDone = enrollment.idDone === true ? "true" : "false"),
          (enrollment.completed = enrollment.completed === 1 ? "true" : "false")
        );
      });

      setEnrollments(data.reverse());
    })
    .catch((err) => {
      setIsLoading(false);
    });
}
