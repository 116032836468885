import React, { useEffect, useState } from "react";
import { Button, Collapse, message, Space, Typography } from "antd";
import {
  CheckCircleFilled,
  ClockCircleFilled,
  ScheduleFilled,
  SyncOutlined,
  ScheduleTwoTone,
  PhoneFilled,
  WarningOutlined,
} from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
function Period({ title, subTitle, content, status, period, schedule }) {
  const [instructorName, setInstructorName] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const authToken = JSON.parse(localStorage.getItem("userAuth")).id;
  const axiosOption = {
    validateStatus: function (status) {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
  };
  const themeColor =
    status === "completed"
      ? Colors.green1
      : status === "scheduled"
      ? Colors.blue2
      : status === "not started"
      ? Colors.red
      : status === "waiting"
      ? Colors.orange
      : Colors.darkGrey;
  const icon =
    status === "completed" ? (
      <CheckCircleFilled />
    ) : status === "scheduled" ? (
      schedule.isRejected ? (
        <Space>
          <WarningOutlined style={{ color: Colors.blue2 }} />
          <ScheduleTwoTone style={{ color: Colors.blue2 }} />
        </Space>
      ) : (
        <ScheduleTwoTone size={10} />
      )
    ) : status === "not started" ? (
      <ClockCircleFilled />
    ) : status === "waiting" ? (
      <SyncOutlined spin />
    ) : null;

  const getInstuctor = () => {
    if (status === "completed" || status === "scheduled") {
      if (period.instructorId) {
        lemmajAxios
          .get(`/LemmajUsers/${period.instructorId}`, {
            params: { access_token: authToken },
            ...axiosOption,
          })
          .then((res) => {
            if (res.data) {
              const firstName = res.data.firstName ? res.data.firstName : "";
              const lastName = res.data.lastName ? res.data.lastName : "";
              const phone = res.data.phoneNumber ? res.data.phoneNumber : "";
              setPhoneNumber(phone);
              setInstructorName(`${firstName} ${lastName}`);
            }
          })
          .catch((err) => {
            message.error(Messages.OPREATION_FAILED);
          });
      }
    }
  };
  useEffect(() => {
    getInstuctor();
  }, []);

  return (
    <Collapse
      style={{
        marginBottom: 16,
        borderLeftWidth: 10,
        borderLeftColor: themeColor,
      }}
      expandIconPosition="right"
    >
      <Collapse.Panel
        header={
          <>
            <Typography.Text style={{ color: themeColor, marginRight: 8 }}>
              {icon}
            </Typography.Text>
            <Typography.Text style={{ marginRight: 8, fontWeight: "bold" }}>
              {title}
            </Typography.Text>
            <Typography.Text
              style={{ fontWeight: "bold", color: Colors.darkGrey }}
            >
              {subTitle}
            </Typography.Text>
            {status === "completed" || status === "scheduled" ? (
              <p>
                <Space>
                  <b>Instructor:</b> {instructorName}
                  <a href={`tel:0923244699`}>
                    <Space>
                      <PhoneFilled />
                      {phone}
                    </Space>
                  </a>
                </Space>
              </p>
            ) : (
              ""
            )}
          </>
        }
      >
        {" "}
        {content}
      </Collapse.Panel>
    </Collapse>
  );
}

export default Period;
