import React from "react";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import SelectPackageCourseCard from "../SelectPackageCourseCard/SelectPackageCourseCard";
function SelectPackageModal({ students, onClick }) {
  const history = useHistory();
  return students.map((student) => {
    const name = student.firstName + " " + student.lastName;
    const userAvatar = (
      <UserAvatar
        preview={false}
        imagSrc={student.imageSrc}
        name={name}
        size={60}
      />
);
    return (
      <SelectPackageCourseCard
        onClick={() => {
          history.push(Routes.STUDENT);
        }}
        firstName={student.firstName}
        lastName={student.lastName}
        phoneNumber={student.phone}
        username={student.username}
        packageName={student.packageName}
        userAvatar={userAvatar}
      />
    );
  });
}

export default SelectPackageModal;