import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Space,
  Collapse,
  Descriptions,
  Image,
  Divider,
  Button,
  Table,
  message,
} from "antd";
import UserAvatar from "../../../component/UserAvatar/UserAvatar";
import Colors from "../../../constants/Colors";
import {
  PhoneFilled,
  GiftFilled,
  LockOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import { useHistory } from "react-router";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function InstructorHeader({ authToken = null, onLogout = () => {} }) {
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [fetchedHeaderData, setFetchedHeaderData] = useState({});

  const instructor_id = history.location.search.slice("id?=".length);

  const fetchData = () => {
    setFetching(true);

    lemmajAxios
      .get(`/LemmajUsers/${instructor_id}`, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        setFetching(false);
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        setFetchedHeaderData(res.data);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card
      loading={fetching}
      bordered={false}
      hoverable
      style={{ borderRadius: 16, marginBottom: 20 }}
    >
      <Row>
        <Col>
          <Card.Meta
            avatar={
              <Row align="middle" justify="center">
                <Col>
                  <UserAvatar
                    name={
                      fetchedHeaderData.firstName +
                      " " +
                      fetchedHeaderData.lastName
                    }
                    size={60}
                  />
                </Col>
              </Row>
            }
            title={
              <Typography.Text strong style={{ color: Colors.orange }}>
                {fetchedHeaderData.firstName + " " + fetchedHeaderData.lastName}
              </Typography.Text>
            }
            description={
              <>
                <Row gutter={(8, 8)}>
                  <Col>
                    <a href={`tel:${fetchedHeaderData.phoneNumber}`}>
                      <Space>
                        <PhoneFilled
                          style={{ fontSize: "20px" }}
                          color={Colors.lightGrey}
                          key="Phone"
                        />
                        {fetchedHeaderData.phoneNumber}
                      </Space>
                    </a>
                  </Col>
                </Row>
                <Row></Row>
              </>
            }
          />
        </Col>
      </Row>
    </Card>
  );
}

export default InstructorHeader;
