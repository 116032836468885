import React from "react";
import { Tabs, Card } from "antd";
import MyPerformance from "./MyPerformance";
import VehiclesPerformance from "./VehiclesPerformance";

const { TabPane } = Tabs;
function PerformanceList({ user, authToken, onLogout }) {
  return (
    <Card>
      <Tabs defaultActiveKey="1">
        <TabPane tab="My Performance" key="1">
          <MyPerformance
            user={user}
            authToken={authToken}
            onLogout={onLogout}
          />
        </TabPane>
        <TabPane tab="Vehicle's Performance" key="2">
          <VehiclesPerformance userId={user.id} />
        </TabPane>
      </Tabs>
    </Card>
  );
}
export default PerformanceList;
