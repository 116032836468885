import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Typography,
  Input,
  DatePicker,
  Row,
  message,
  Select,
  Col,
  Button,
  Divider,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Colors from "../../constants/Colors";
import firebase from "../../others/firebaseConfig";
import moment from "moment";
import ImageUpload from "../../component/ImageUpload/ImageUpload";
import lemmajAxios from '../../others/apiConfig';
import Messages from '../../others/Messages';

const axiosOption = {
  validateStatus: function (status) {
    return status <= 500; // Reject only if the status code is greater than or equal to 500
  },
};

function License({
  onFormSubmit,
  onCancel,
  licenseType = "",
  regNum = "",
  license_image = "",
  issuedDate = moment(Date.now()),
  expireDate = moment(Date.now()),
  submitting = false,
  gearType = "",
  packageId = "",
}) {
  const [form] = Form.useForm();
  const [image, setImage] = useState([]);
  const [packages, setPackages] = useState([]);
  const [downloadURL, setDownloadURL] = useState("");
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [fetching, setFetching] = useState(true);

  form.setFieldsValue({
    licenseType,
    regNum,
    issuedDate,
    expireDate,
    packageId,
    gearType,
    license_image,
  });

  const getPackages = () => {
    setFetching(true);
    lemmajAxios
    .get("/LemmajPackages", axiosOption)
      .then((res) => {
        setFetching(false);
        setPackages(res.data);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };

  useEffect(() => {
    getPackages();
  }, []);

  const props = {
    name: "license_image",
    onChange(info) {
      if (info.file.status !== "uploading") {
        let reader = new FileReader();
        reader.onload = (e) => {};
      }
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
      }
    },
  };
  // const handleChange = (info) => {
  //   e.preventDefault();

  //   if(e.target.files[0]) {
  //     setImage(e.target.files[0])
  //   }

  // };
  const handleUploadStart = () => {
    setProgress(0);
    setIsUploading(true);
  };
  const handleProgress = (progress) => {
    setProgress(progress);
  };
  const handleUploadError = (error) => {
    setIsUploading(false);
    console.error(error);
  };
  const handleUploadSuccess = (filename) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    setIsUploading(false);
    setIsUploading(100);
    setImage(filename);
    firebase
      .storage()
      .ref("license")
      .child(filename)
      .getDownloadURL()
      .then((url) => setDownloadURL(url));
  };
  // onchange(info) {
  //   console.log("cccccc", info.file)
  // }
  const handleUpload = () => {
    // console.log('image is ', image)
    // return;
  };
  return (
    <>
      <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
        License Information
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const data = { ...values, license_image: image[0] };
          onFormSubmit(data);
        }}
      >
        <Form.Item
          name="license_image"
          label="License"
          rules={[
            () => ({
              validator(_, _value) {
                if (!image || image.length <= 0) {
                  return Promise.reject("License image is required");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <ImageUpload
            manualUpload={true}
            name="upload-"
            fileList={image}
            onChange={(value) => {
              setImage(value.fileList);
            }}
          />
          {/* <Upload 
            // action={handleUpload}
            // onChange={(info) => {

            // }}
            beforeUpload = {file => {
              const reader = new FileReader()
              reader.onload = e => {
                console.log("finally doing",e.target.result)
              }
              return false;
            }}
            listType="picture"
            className="upload-list-inline"
            maxCount = {1}
          >
            <Button icon={<UploadOutlined />}>file</Button>
          </Upload> */}
          {/* <input type="file" id="file" onChange={handleChange} />
          <Button onClick={handleUpload}>Upload</Button> */}
        </Form.Item>
        <Divider />
        <Form.Item
          name="licenseType"
          label="License Type"
          rules={[{ required: true, message: "License type is required" }]}
        >
          <Select placeholder="License Type">
            <Select.Option key="auto">Auto</Select.Option>
            <Select.Option key="bus1">Hizb 1</Select.Option>
            <Select.Option key="bus2">Hizb 2</Select.Option>
            <Select.Option key="heavy">Heavy</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="regNum"
          label="Registeration Number"
          rules={[
            { required: true, message: "Registeration Number is required" },
          ]}
        >
          <Input placeholder="Registeration Number" />
        </Form.Item>
        <Form.Item
          label="Issued Date"
          name="issuedDate"
          rules={[{ required: true, message: "Issued Date is required" }]}
        >
          <DatePicker placeholder="Issued Date" />
        </Form.Item>
        <Form.Item
          label="Expire Date"
          name="expireDate"
          rules={[{ required: true, message: "Expire Date is required" }]}
        >
          <DatePicker placeholder="Expire Date" />
        </Form.Item>
        <Divider />
        <Form.Item
          name="packageId"
          label="Select Package Type"
          rules={[
            { required: true, message: "Pacakge type is required" },
          ]}
        >
          <Select placeholder="Package Type">
            {packages.map((item) => (
              <Select.Option key={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="gearType"
          label="Gear Type"
          rules={[{ required: true, message: "Gear Type is required" }]}
        >
          <Select placeholder="Gear Type">
            <Select.Option key="auto">Auto</Select.Option>
            <Select.Option key="manual">Manual</Select.Option>
            <Select.Option key="hybrid">Hybrid</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Row justify="space-between">
            <Col span={10}>
              <Button
                loading={submitting}
                block
                type="primary"
                htmlType="submit"
                style={{
                  borderRadius: 16,
                  background: Colors.blue2,
                  borderColor: Colors.blue2,
                }}
              >
                Register
              </Button>
            </Col>
            <Col span={10}>
              <Button
                block
                type="primary"
                onClick={onCancel}
                style={{
                  background: Colors.orange,
                  borderColor: Colors.orange,
                  borderRadius: 16,
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
}

export default License;
