import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Form,
  Button,
  Select,
  Row,
  Col,
  Input,
  Space,
  Divider,
  InputNumber,
  Spin,
  message,
} from "antd";
import { DiffOutlined, GiftFilled, PlusOutlined } from "@ant-design/icons";
import Colors from "../../../constants/Colors";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";
import UpdateCourse from "../UpdateCourse/UpdateCourse";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};

function AlterCourse({
  name = "",
  description = "",
  duration = 0,
  showAddCourse,
  onSubmit,
  authToken = null,
  onLogout = () => {},
}) {
  const [submmiting, setSubmmittng] = useState(false);
  const [fetching, setFetching] = useState(!showAddCourse);
  const history = useHistory();
  const [form] = Form.useForm();

  const handleAddCourse = (values) => {
    // lemmajAxios;
    const data = {
      name: values.name,
      length: values.duration,
      description: values.description,
    };
    setSubmmittng(true);
    lemmajAxios
      .post("/Courses", data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        message.success(Messages.COURSE_CREATED);
        history.replace(Routes.COURSE);
        setSubmmittng(false);
      })
      .catch((err) => {
        setSubmmittng(false);
        message.error(Messages.OPREATION_FAILED);
      });
    return;
  };

  const fillForm = (name, description, duration) => {
    form.setFieldsValue({
      name: name,
      description: description,
      duration: duration,
    });
  };

  const fetchDataAndFillData = () => {
    if (!showAddCourse) {
      const course_id = history.location.search.slice("id?=".length);
      setFetching(true);
      lemmajAxios
        .get(`/Courses/${course_id}`, {
          params: {
            access_token: authToken,
          },
          ...axiosOption,
        })
        .then((res) => {
          setFetching(false);
          if (res.data.error && res.data.error.statusCode === 401) {
            onLogout();
            return;
          }
          if (res.data.error && res.data.error.statusCode === 404) {
            message.error(Messages.ITEM_NOT_FOUND);
            return;
          }
          const course = res.data;
          fillForm(course.name, course.description, course.length);
        })
        .catch((err) => {
          setFetching(false);
          message.error(Messages.OPREATION_FAILED);
        });
    }
  };
  useEffect(() => {
    fetchDataAndFillData();
  }, []);
  const onUpdateCourse = (values) => {
    const course_id = history.location.search.slice("id?=".length);
    const data = {
      name: values.name,
      length: values.duration,
      description: values.description,
    };
    setSubmmittng(true);
    lemmajAxios
      .patch(`/Courses/${course_id}`, data, {
        params: {
          access_token: authToken,
        },
        ...axiosOption,
      })
      .then((res) => {
        if (res.data.error && res.data.error.statusCode === 401) {
          onLogout();
          return;
        }
        if (res.data.error && res.data.error.statusCode === 404) {
          message.error(Messages.ITEM_NOT_FOUND);
          return;
        }
        message.success(Messages.INFO_UPDATED);
        history.replace(Routes.COURSE);
        setSubmmittng(false);
      })
      .catch((err) => {
        setSubmmittng(false);
        message.error(Messages.OPREATION_FAILED);
      });
    return;
  };
  return (
    <Spin spinning={submmiting}>
      <Card loading={!showAddCourse && fetching}>
        {showAddCourse && (
          <Typography.Title level={4} style={{ color: Colors.orange }}>
            <Space>
              <DiffOutlined />
              Create course
            </Space>
          </Typography.Title>
        )}
        {!showAddCourse && (
          <Typography.Title level={4} style={{ color: Colors.orange }}>
            <Space>
              <DiffOutlined />
              Update course
            </Space>
          </Typography.Title>
        )}
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            showAddCourse ? handleAddCourse(values) : onUpdateCourse(values);
          }}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea
              showCount
              maxLength={120}
              rules={[{ required: true, message: "Description is required" }]}
            />
          </Form.Item>
          <Form.Item
            label="Duration in minutes"
            name="duration"
            type="number"
            rules={[
              { required: true, message: "Duration is required" },
              // { min: 0, message: "Duration must be greater than 0" },
              {
                type: "number",
                message: "Input numbers only",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Row justify="start">
              <Col xs={24} sm={8}>
                {showAddCourse && (
                  <Button
                    icon={<PlusOutlined />}
                    style={{
                      background: Colors.blue2,
                      borderRadius: Colors.blue2,
                    }}
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    Add Course
                  </Button>
                )}
                {!showAddCourse && (
                  <Button
                    style={{
                      background: Colors.blue2,
                      borderRadius: Colors.blue2,
                    }}
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    Update Course
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
}

export default AlterCourse;
