export default {
  ENTER_VERIFICATION_NUMBER:
    "Please enter the verification Number you receive.",
  PHONE_VERIFIED: "Phone verification successfull",
  PHONE_NOT_VERIFIED: "Failed to verify Phone",
  CONNECTION_ERR: "Connection error",
  INFO_UPDATED: "Information updated successfully",
  OPREATION_FAILED: "Opreation failed, check your connection",
  REG_FAILED: "Registration failed, check your connection",
  INVALID_PASSWORD: "Incorrect password",
  PASSWORD_CHANGE_SUCCESS: "Password changed successully",
  INSTRUCTOR_SELECTED_SUCCESS: "Instructor selected successfully",
  REG_SUCCESS:
    "You have registred successfully, please wait until your payment information is verified and activated",
  COURSE_CREATED: "Course created successfully",
  PACKAGE_CREATED: "Package created successfully",
  PACKAGE_UPDATED: "ackage updated successully",

  INSTRUCTOR_CREATED: "Instructor created successfully",
  DELETE_COURSE_CONFIRM: "Are you sure you want to delete this course ?",
  DELETE_PACKAGE_CONFIRM: "Are you sure you want to delete this package ?",
  PACKAGE_DELETED: "Package deleted successully",

  ITEM_NOT_FOUND: "Item not found",
  COURSE_DELETED: "Course deleted successully",
  LOGGED_OUT: "Logged out successully",
  LEAVE_REQUESTED: "Leave request sent",
  SOMETHING_WENT_WRONG: "Something went wrong",

  LEAVE_REQUESTE_ACCEPT: "Leave request has been accepted",
  LEAVE_REQUESTE_REJECT: "Leave request has been rejected",
  MORNING: "Morning",
  AFTERNOON: "Afternoon",

  DELETE_NOTIFICATION_CONFIRM:
    "Are you sure you want to delete this notification ?",
  NOTIFICATION_DELETED: "Notification deleted successfully",
  SCHEDULE_DELETED: "Schedule canceled successfully",

  SCHEDULE_APPROVED: "Schedule approved successfully",
  SCHEDULE_REJECTED: "Schedule rejected successfully",

  STUDENT_APPROVED: "Students has been APPROVED successully",
  STUDENT_REJECTED: "Students has been REJECTED successully",

  FEEDBACK_SUBMITTED: "Feedback submitted successully",

  BAD_REQUEST: "Bad request",
};
