export default {
  black: "#333333",
  white: "#fff",
  veryDarkGrey: "#4F4F4F",
  darkGrey: "#828282",
  mediumGrey: "#BDBDBD",
  lightGrey: "#E0E0E0",
  veryLightGrey: "#F2F2F2",
  primary: "",
  secodnary: "",
  red: "#EB5757",
  orange: "#F2994A",
  yellow: "#F2C94C",
  green1: "#219653",
  green2: "#27AE60",
  green3: "#6FCF97",
  blue1: "#2F80ED",
  blue2: "#2D9CDB",
  blue3: "#56CCF2",
  purple1: "#9B51E0",
  purple2: "#BB6BD9",
};
