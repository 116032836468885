import React, { useState } from "react";
import {
  Form,
  Button,
  DatePicker,
  Input,
  Row,
  Col,
  Divider,
  Typography,
  Select,
} from "antd";
import Colors from "../../../constants/Colors";
import ImageUpload from "../../../component/ImageUpload/ImageUpload";
function NextForm({ onSubmit, onCancel }) {
  const [image, setImage] = useState([]);

  return (
    <>
      <Form
        onFinish={(values) => {
          onSubmit({
            ...values,
            license_image: values.license_image.fileList[0],
          });
        }}
        layout="vertical"
      >
        <Typography.Title level={4} style={{ color: Colors.mediumGrey }}>
          License Information
        </Typography.Title>
        <Divider />
        <Form.Item
          name="license_image"
          label="License"
          rules={[
            () => ({
              validator(_, _value) {
                if (!image || image.length <= 0) {
                  return Promise.reject("License image is required");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <ImageUpload
            manualUpload={true}
            name="upload-"
            fileList={image}
            onChange={(value) => {
              setImage(value.fileList);
            }}
          />
        </Form.Item>
        <Form.Item
          name="licenseType"
          label="License Type"
          rules={[{ required: true, message: "License type is required" }]}
        >
          <Select placeholder="License Type">
            <Select.Option key="auto">Auto</Select.Option>
            <Select.Option key="bus1">Hizb 1</Select.Option>
            <Select.Option key="bus2">Hizb 2</Select.Option>
            <Select.Option key="heavy">Heavy</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="regNum"
          label="Registeration Number"
          rules={[
            { required: true, message: "Registeration Number is required" },
          ]}
        >
          <Input placeholder="Registeration Number" />
        </Form.Item>
        <Form.Item
          label="Issued Date"
          name="issuedDate"
          rules={[{ required: true, message: "Issued Date is required" }]}
        >
          <DatePicker placeholder="Issued Date" />
        </Form.Item>
        <Form.Item
          label="Expire Date"
          name="expireDate"
          rules={[{ required: true, message: "Expire Date is required" }]}
        >
          <DatePicker placeholder="Expire Date" />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between">
            <Col xs={24} sm={8}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{
                  background: Colors.blue2,
                  borderColor: Colors.blue2,
                }}
              >
                Create Instructor
              </Button>
            </Col>
            <Col xs={24} sm={8}>
              <Button
                block
                type="primary"
                onClick={() => {
                  onCancel();
                }}
                style={{
                  background: Colors.orange,
                  borderColor: Colors.orange,
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
}

export default NextForm;
