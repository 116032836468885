import React, { useEffect, useState } from "react";
import { Table, Card, Tag, Button, message } from "antd";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import AppInputSearch from "../../../component/AppInputSearch/AppInputSearch";
import Colors from "../../../constants/Colors";
import { useHistory } from "react-router-dom";
import Routes from "../../Routes";
import lemmajAxios from "../../../others/apiConfig";
import Messages from "../../../others/Messages";

const axiosOption = {
  validateStatus: function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
};
function Periods(props) {
  const [fetching, setFetching] = useState(true);
  const [periodData, setPeriodData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = () => {
    setFetching(true);
    lemmajAxios
      .get(`/Periods/getBadReviews?access_token=${props.authToken}`, axiosOption)
      .then((res) => {
        setFetching(false);
        const data = res.data.map((c) => {
          return { instructor: c.instructor.firstName+" "+ c.instructor.lastName, student: c.student.firstName+" "+ c.student.lastName, periodNumber: c.period.sequence, feedback: "Very Dissatisfied",};
        });
        setPeriodData(data);
      })
      .catch((err) => {
        setFetching(false);
        message.error(Messages.OPREATION_FAILED);
      });
  };
  useEffect(() => {
    fetchData();
    // setFetching(false);
  }, []);

  const handleSearchTerm = (term) => {
    setSearchTerm(term);
  };

  let filteredPeriods =
    !searchTerm || searchTerm === ""
      ? periodData
      : periodData.filter((i) => {
          return `${i.instructor.toLowerCase()}`
            .trim()
            .includes(searchTerm.toLowerCase().trim());
        });

  const history = useHistory();
  const columns = [
    {
      title: "Instructor",
      dataIndex: "instructor",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Student",
      dataIndex: "student",
    },
    {
        title: "Period Number",
        dataIndex: "periodNumber",
    },
    {
        title: "Feedback",
        dataIndex: "feedback",
    },
    // {
    //   title: "Duty Days",
    //   dataIndex: "dutyDays",
    //   render: (value = []) => {
    //     return value.map((i) => {
    //       return <Tag style={{ margin: 5 }}>{parseDaysOftheWeek(i)}</Tag>;
    //     });
    //   },
    // },
    // {
    //   title: "Details",
    //   render: (_, record) => {
    //     return(
    //       <Button
    //         onClick={() => {
    //           history.push(`${Routes.INSTRUCTOR_DETAIL}?id=${record.id}`)}}
    //         icon={<EyeOutlined />}
    //         type="ghost"
    //       >
    //         View
    //       </Button>
    //     );
    //   },
    // },
  ];

  return (
    < >
      <Card style={{ marginBottom: 10, borderRadius: 16 }}>
        <AppInputSearch
          onSearch={handleSearchTerm}
          placeholder="Instructors name"
        />
      </Card>

      <Card style={{ borderRadius: 16 }}>
        <Table loading={fetching} scroll={{ x: 50 }} columns={columns} dataSource={filteredPeriods}></Table>
      </Card>
    </>
  );
}

export default Periods;
